<div oui-dialog-title>
  {{ title }}
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-vertical gap-16">
    <div class="flex-horizontal gap-16 align-self-center">
      <div><oui-icon class="big-icon color-error">warning</oui-icon></div>
      <div class="body-1 color-on-surface-emphasis">
        Vous ne pouvez pas déclarer de nouvelles opérations ou traiter des opérations en cours car vous n'avez aucune
        habilitation en placement active. Vous avez 2 possibilités :
      </div>
    </div>
    <div class="flex-vertical gap-8">
      <div class="flex-horizontal gap-16 flex-center rounded with-border padding-16">
        <div class="width-24 height-24"><oui-number [value]="1"></oui-number></div>
        <div>
          Consulter <a [href]="articleUri">cet article du Centre d’Aide</a> pour faire les démarches d’habilitation
          nécessaires.
        </div>
      </div>
      <div class="flex-horizontal gap-16 flex-center rounded with-border padding-16">
        <div><oui-number [value]="2"></oui-number></div>
        <div>Mettre en place <a [href]="relationUri">une relation secondaire.</a></div>
      </div>
    </div>
  </div>
</oui-dialog-content>
