import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import { OperationConfig } from '../models/generated/graphql';

const operationConfigsRequest = gql`
  query allOperationConfig($filter: OperationConfigFilterInput) {
    allOperationConfig(where: $filter) {
      id
      natureOperationId
      enveloppeId
      allowMultiple
      natureOperation {
        id
        libelle
      }
      filteredNatureDocumentRequis {
        id
        natureDocument {
          id
          categorieId
          nom
          boxDocumentType {
            key
            gedParentTypeCode
          }
          categorie {
            libelle
          }
        }
        withAutoImport
        withAutoImportCoInv
      }
      enveloppeProduit {
        code
        libelle
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class OperationConfigService {
  constructor(private queryManager: QueryManagerService) {}

  async fetchSingleOperationConfig(natureOperationId: number, enveloppeId: number) {
    var operationConfigResult = await firstValueFrom(
      this.queryManager.query<{ allOperationConfig: OperationConfig[] }>({
        query: operationConfigsRequest,
        variables: {
          filter: {
            and: [{ natureOperationId: { eq: natureOperationId } }, { enveloppeId: { eq: enveloppeId } }],
          },
        },
      })
    );
    return operationConfigResult.data.allOperationConfig[0];
  }
}
