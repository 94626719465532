<div class="flex-vertical width-100pc gap-8">
  <oui-slide-toggle
    label="Démembrement"
    [(checked)]="dismemberedChecked"
    (checkedChange)="onDemembrementToggled()"></oui-slide-toggle>
  <ng-container *ngIf="dismemberedChecked">
    <oui-button-toggle [options]="dismemberedOptions" [control]="dismemberedControl"></oui-button-toggle>

    <div class="flex-vertical width-100pc gap-8">
      <div *ngIf="!isViager" class="top-margin-8">Contrepartie connue ?*</div>
      <div class="flex-horizontal width-100pc gap-32">
        <oui-button-toggle
          [options]="contrepartieOptions"
          [control]="contrepartieControl"
          *ngIf="!isViager"
          class="flex-1"></oui-button-toggle>

        <oui-textfield
          label="Nom de la contrepartie"
          type="text"
          [control]="contrepartieNameControl"
          *ngIf="isKnown || isViager"
          class="flex-1"></oui-textfield>

        <div *ngIf="!isKnown || isViager" class="flex-1"></div>
      </div>
    </div>
  </ng-container>
</div>
