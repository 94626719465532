import { createFichierOperationFragment } from "@lib/models/graphqlFragments";
import { gql } from "apollo-angular";


export const createAndReplaceRequiredFichierOperationInOperationGroup = gql`
    mutation createAndReplaceRequiredFichierOperationInOperationGroup($input: FichierOperationInGroupInput!) {
        createAndReplaceRequiredFichierOperationInOperationGroup(input: $input) {
            id
            operation{
                id
                fichierOperations{
                    ...createFichierOperation
                }
            }
          
        }
    }
    ${createFichierOperationFragment}
`;   