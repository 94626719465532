import { Component } from '@angular/core';
import { AbstractSpecificFieldComponent } from '../abstract-specific-field.component';

@Component({
  selector: 'app-specific-comment',
  templateUrl: './specific-comment.component.html',
  styleUrls: ['./specific-comment.component.scss'],
})
export class SpecificCommentComponent extends AbstractSpecificFieldComponent<string> {
  override getDataName(): string {
    return 'commentaire';
  }

  override wrapData(value: string): string {
    return value;
  }
}
