<div class="flex-vertical gap-32">
  <div class="border-left-primary oui-elevation-01 rounded padding-16">
    <oui-accordion [expanded]="true">
      <oui-accordion-header>
        <oui-accordion-title class="flex-horizontal flex-center gap-8">
          <oui-icon color="primary">info</oui-icon>Informations sur l’ordre des signatures</oui-accordion-title
        >
      </oui-accordion-header>
      <ol class="subtitle-2 color-on-surface-medium flex-vertical gap-12">
        <li>Le Document d'entrée en relation (DER) <span class="body-2">doit être signé en premier</span></li>
        <li>
          La fiche connaissance client (recueil d'informations) et le profil investisseur :<span class="body-2">
            doivent être signés après ou au plus tôt le même jour que le DER</span
          >
        </li>
        <li>
          Les documents conseil :<span class="body-2">
            doivent être signés après ou au plus tôt le même jour que le DER, recueil d'informations <b>et</b> profil
            investisseur</span
          >
        </li>
        <li>
          Les documents de l'opération : <br />
          <span class="body-2">
            - <b>Courtage</b> : après ou au plus <b>tôt</b> le même jour que les documents conseil<br />
            - <b>PSI</b> : au minimum, 3 jours après la date de signature des documents conseil
          </span>
        </li>
      </ol>
    </oui-accordion>
  </div>
  <app-souscription-file-items
    *ngIf="draftOperationsFilesService.investisseurCategoryFiles.connaissanceClientFiles.missingAndSettedFiles.length > 0"
    [itemsCategory]="'investisseur'"
    [investisseur]="draftOperationsService.investisseur"
    [fichierOperations]="
      draftOperationsFilesService.investisseurCategoryFiles.connaissanceClientFiles.distinctByNatureDocumentRequisFiles
    "
    [showBannerConnaissanceClient]="true"
    (onAddedFile)="addFichierOperation($event)"
    (onRemoveFile)="removeFichierOperationbyId($event)">
  </app-souscription-file-items>

  <app-souscription-file-items
    *ngIf="
      !!draftOperationsService.coInvestisseur &&
      draftOperationsFilesService.coInvestisseurCategoryFiles.connaissanceClientFiles.missingAndSettedFiles.length > 0
    "
    [itemsCategory]="'co-investisseur'"
    [investisseur]="draftOperationsService.coInvestisseur"
    [fichierOperations]="
      draftOperationsFilesService.coInvestisseurCategoryFiles.connaissanceClientFiles.distinctByNatureDocumentRequisFiles
    "
    [showBannerConnaissanceClient]="true"
    (onAddedFile)="addFichierOperation($event)"
    (onRemoveFile)="removeFichierOperationbyId($event)">
  </app-souscription-file-items>

  <app-souscription-file-items
    *ngIf="draftOperationsFilesService.investisseurCategoryFiles.conseilFiles.missingAndSettedFiles.length > 0"
    [itemsCategory]="'conseil'"
    [investisseur]="draftOperationsService.investisseur"
    [fichierOperations]="
      draftOperationsFilesService.investisseurCategoryFiles.conseilFiles.distinctByNatureDocumentRequisFiles
    "
    (onAddedFile)="addFichierOperation($event)"
    (onRemoveFile)="removeFichierOperationbyId($event)"
    [withGlobalAddFilesButton]="false">
  </app-souscription-file-items>

  <ng-container *ngFor="let operation of draftOperationsService.operationListInGroup$ | async; let index = index">
    <app-operation-files-configuration
      [operationId]="operation?.id"
      [index]="index"
      (onAddedFichierOperation)="setIsMissingDoc($event)">
    </app-operation-files-configuration>
  </ng-container>
</div>
