<oui-document-viewer [documentUrl]="documentUrl" [showControls]="true">
  <oui-etat-vide *ngIf="isMissingFile" icon="unknown_document" title="Document en attente de dépôt"></oui-etat-vide>

  <oui-loading *ngIf="!isMissingFile"></oui-loading>

  <ng-container oui-document-controls>
    <lib-document-viewer-header
      [documentName]="selectedFile.natureDocumentRequis?.natureDocument?.nom ?? selectedFile.metadata?.denomination ?? 'Autre'"
      [fileName]="fileName ?? ''"
      [dateAjout]="selectedFile.metadata?.dateDeCreation"
      (close)="close.emit(docUpdated)">
      <app-file-statut-tag [file]="selectedFile"></app-file-statut-tag>
    </lib-document-viewer-header>

    <app-file-conform-status-stamp
      *ngIf="isConformFile"
      [historyEntries]="selectedFile.historyEntry"></app-file-conform-status-stamp>

    <ng-container *ngIf="!isConformFile">
      <ng-container *ngIf="selectedFile.fichierOperationStatut.statut === updated">
        <ng-container *ngIf="!reponseService.isCommentMode">
          <div class="body-2 color-on-surface-medium">Réponse :</div>
          <div class="reponse" [innerText]="selectedFile.reponseConsultant ?? '-'"></div>
          <oui-button type="outlined" iconPrefix="create" (click)="onModifyConsultantResponse()">
            {{ isResponseByFileChanged() ? 'Modifier le commentaire' : 'Modifier la réponse' }}
          </oui-button>
          <oui-button type="text" iconPrefix="undo" (click)="onCancelConsultantResponse()">
            {{ isResponseByFileChanged() ? 'Annuler la mise à jour' : 'Annuler la réponse' }}
          </oui-button>
        </ng-container>

        <ng-container *ngIf="reponseService.isCommentMode">
          <app-consultant-reponse-commentaire (onCancelClicked)="onCancelComment()" (onValidClicked)="onValidComment()">
          </app-consultant-reponse-commentaire>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedFile.fichierOperationStatut.statut !== updated">
        <ng-container *ngIf="selectedFile.fichierOperationStatut.statut !== updated && !reponseService.isCommentMode">
          <div class="flex-vertical gap-8 flex-center">
            <div class="width-100pc">
              <oui-menu [menuItems]="menu" horizontalPosition="left" verticalPosition="bottom" class="width-100pc">
                <oui-button type="contained" class="flex-none width-100pc menubutton">
                  Mettre à jour le document
                </oui-button>
              </oui-menu>
            </div>
            <div class="button color-on-surface-medium">ou</div>
            <div class="width-100pc">
              <oui-button type="outlined" class="flex-none width-100pc" (click)="onRespondByComment()"
                >Répondre par un commentaire</oui-button
              >
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedFile.fichierOperationStatut.statut === updated || reponseService.isCommentMode">
          <app-consultant-reponse-commentaire (onCancelClicked)="onCancelComment()" (onValidClicked)="onValidComment()">
          </app-consultant-reponse-commentaire>
        </ng-container>
      </ng-container>

      <app-non-conformite-accordion
        *ngIf="nonConformiteList && nonConformiteList.length > 0"
        [title]="'Non-conformités ouvertes'"
        [NCs]="nonConformiteList"></app-non-conformite-accordion>
      <app-non-conformite-accordion
        *ngIf="instancePartenaireList && instancePartenaireList.length > 0"
        [title]="'Instances partenaire ouvertes'"
        [NCs]="instancePartenaireList"></app-non-conformite-accordion>
    </ng-container>
  </ng-container>
</oui-document-viewer>
