import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OptionsService, TypeTransmission } from '@lib/services/options.service';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { AbstractSpecificDataComponent } from '../../abstract-specific-data.component';
import { TypeSignaturePartenaire } from '@lib/models/generated/graphql';

const TRANSMISSION_KEY = 'transmissionOriginaux';

@Component({
  selector: 'app-type-signature-partenaire',
  templateUrl: './type-signature-partenaire.component.html',
  styleUrls: ['./type-signature-partenaire.component.scss'],
})
export class TypeSignaturePartenaireComponent extends AbstractSpecificDataComponent {
  transmissionListOptions: OuiSelectOption<string>[];
  metadata: any = {};
  override getDataName(): string {
    return 'typeSignaturePartenaire';
  }

  showTransmissionField: boolean = false;

  signatureOptions: OuiSelectOption<string>[] = [];
  signatureControl = new FormControl<TypeSignaturePartenaire | undefined>(undefined, [Validators.required]);
  transmissionControl = new FormControl<TypeTransmission | undefined>(undefined, [Validators.required]);

  constructor(
    private optionsService: OptionsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.signatureOptions = this.optionsService.getTypeSignaturePartenaireOptions();
    this.transmissionListOptions = this.optionsService.getTransmissionOptions();
  }

  ngOnInit() {
    this.signatureControl.valueChanges.subscribe(value => {
      this.showTransmissionField = value === TypeSignaturePartenaire.Manuscrite;

      if (!this.showTransmissionField) {
        this.transmissionControl.setValue(undefined);
        this.transmissionControl.reset();
      } else {
        const envoi = this.initialData?.data[TRANSMISSION_KEY];
        if (envoi) {
          this.transmissionControl.setValue(envoi);
        }
      }

      // TODO voir pour éditer la valeur autre part ?
      this.operation!.typeSignaturePartenaire = value;
    });

    this.transmissionControl.valueChanges.subscribe(value => {
      this.metadata[TRANSMISSION_KEY] = value;
      this.onSpecificDataChanged.emit({
        dataSource: this.getDataName(),
        data: { isFormValid: this.isValidForm(), data: this.metadata },
      });
    });

    this.signatureControl.setValue(this.operation?.typeSignaturePartenaire);
  }

  isValidForm(): boolean {
    const isSignatureOK = this.signatureControl.valid;
    return this.showTransmissionField ? isSignatureOK && this.transmissionControl.valid : isSignatureOK;
  }
}
