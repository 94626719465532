<oui-accordion [expanded]="fileInfos && fileInfos.length > 0" class="padding-24 with-border rounded">
  <oui-accordion-header class="padding-vertical-8">
    <oui-accordion-title>
      <app-operation-accordion-title
        *ngIf="itemsCategory === 'operation'"
        [operation]="operation"
        [index]="operationIndex"
        [showDetails]="true">
      </app-operation-accordion-title>

      <ng-container *ngIf="itemsCategory === 'investisseur' || itemsCategory === 'co-investisseur'">
        <span class="subtitle-1"
          >Connaissance client - {{ itemsCategory === 'co-investisseur' ? 'Co-' : '' }}Investisseur :
        </span>
        <span class="body-1">{{ investisseur?.investisseurEntite?.displayName }}</span>
      </ng-container>
      <ng-container *ngIf="itemsCategory === 'conseil'">
        <span class="subtitle-1">Conseil </span>
      </ng-container>
    </oui-accordion-title>
  </oui-accordion-header>
  <div class="flex-vertical gap-16">
    <oui-banner
      *ngIf="showBannerConnaissanceClient"
      class="banner-help-documents"
      declination="alert"
      title="Merci de vérifier les documents de la connaissance client automatiquement importés d'Ariane."
      [showCloseButton]="false"></oui-banner>

    <oui-banner
      *ngIf="showBannerSouscription"
      class="banner-help-documents"
      declination="alert"
      title="Merci d'ajouter tous les documents de l'opération (bulletins, justificatifs). La liste ci-dessous n'est pas nécessairement exhaustive."
      [showCloseButton]="false"></oui-banner>

    <div class="file-items-container">
      <app-souscription-file-item
        *ngFor="let fileInfo of fileInfos; let eltIndex = index"
        [fileInfo]="fileInfo"
        [isFileLoading]="draftOperationService.isTryingAutoImport"
        (fileSelected)="displayFile(fileInfo)"
        (addFileClick)="onRequisAddFiles(eltIndex)">
      </app-souscription-file-item>
    </div>
    <div class="flex-horizontal flex-center gap-16">
      <oui-menu
        *ngIf="withGlobalAddFilesButton"
        class="demo-component"
        [menuItems]="defaultMenu"
        horizontalPosition="left"
        verticalPosition="bottom">
        <oui-button type="outlined" (click)="onGlobalAddFiles()" *ngIf="!isGlobalUploadInProgress">
          Ajouter des documents supplémentaires
        </oui-button>
        <oui-loading size="small" *ngIf="isGlobalUploadInProgress"></oui-loading>
      </oui-menu>
      <div class="body-2 color-on-surface-medium">
        Formats acceptés : PDF, JPEG, PNG - Poids maximum 20 Mo par document.
      </div>
    </div>
  </div>
  <div id="target_div" class="display-none"></div>
</oui-accordion>
<ng-template #loading>
  <oui-loading size="small"></oui-loading>
</ng-template>
