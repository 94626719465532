import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OperationInformationConfigService {
  /**
   * GENERATED CODE USING Box\Back\OperationsData\SeedInformation\operationInformationConfig.py
   * on configuration champs information csv file provided by Finzzle
   * and stored in Box\Back\OperationsData\SeedInformation zip file.
   */

  private conf1: number[] = [255];
  private conf2: number[] = [51];
  private conf3: number[] = [248, 273, 267, 281];
  private conf4: number[] = [202, 211, 220, 229];
  private conf5: number[] = [243, 238];
  private conf6: number[] = [1];
  private conf7: number[] = [287, 279];
  private conf8: number[] = [76];
  private conf9: number[] = [121, 161, 101, 141];
  private conf10: number[] = [254];
  private conf11: number[] = [122];
  private conf12: number[] = [128, 148, 108, 168];
  private conf13: number[] = [258, 3, 163, 103, 78, 143, 53, 123, 28];
  private conf14: number[] = [77];
  private conf15: number[] = [2, 162, 102, 142, 52, 27];
  private conf16: number[] = [
    4, 6, 7, 9, 10, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 25, 29, 31, 32, 34, 35, 36, 37, 38, 40, 42, 43, 44, 45,
    46, 47, 48, 50, 54, 56, 57, 59, 60, 61, 62, 63, 65, 67, 68, 69, 70, 71, 72, 73, 75, 79, 81, 82, 83, 84, 85, 86, 87,
    88, 90, 92, 93, 94, 95, 96, 97, 98, 100, 104, 106, 107, 109, 110, 111, 112, 114, 115, 116, 117, 118, 119, 124, 126,
    127, 129, 130, 131, 132, 134, 135, 136, 137, 138, 139, 144, 146, 147, 149, 150, 151, 152, 154, 155, 156, 157, 158,
    159, 164, 166, 167, 169, 170, 171, 172, 174, 175, 176, 177, 178, 179, 239, 240, 241, 242, 244, 245, 246, 247, 249,
    250, 251, 252, 253, 262, 263, 264, 265, 266, 268, 269, 270, 271, 272, 274, 275, 276, 277, 278, 282, 283, 284, 285,
    286,
  ];
  private conf17: number[] = [8, 33, 58];
  private conf18: number[] = [5, 165, 105, 80, 145, 55, 125, 30];
  private conf19: number[] = [
    256, 257, 259, 260, 133, 261, 140, 16, 24, 153, 160, 41, 173, 49, 180, 66, 74, 91, 99, 113, 120,
  ];
  private conf20: number[] = [184];
  private conf21: number[] = [192, 230, 231, 203, 204, 212, 213, 221, 222];
  private conf22: number[] = [
    183, 185, 186, 187, 188, 189, 190, 191, 194, 195, 196, 197, 198, 199, 200, 201, 205, 206, 207, 208, 209, 210, 214,
    215, 216, 217, 218, 219, 223, 224, 225, 226, 227, 228, 232, 233, 234, 235, 236, 237,
  ];
  private conf23: number[] = [64, 89, 14, 39];
  private conf24: number[] = [182];
  private conf25: number[] = [26];
  private conf26: number[] = [193, 181];

  getConf1() {
    return this.conf1;
  }
  getConf2() {
    return this.conf2;
  }
  getConf3() {
    return this.conf3;
  }
  getConf4() {
    return this.conf4;
  }
  getConf5() {
    return this.conf5;
  }
  getConf6() {
    return this.conf6;
  }
  getConf7() {
    return this.conf7;
  }
  getConf8() {
    return this.conf8;
  }
  getConf9() {
    return this.conf9;
  }
  getConf10() {
    return this.conf10;
  }
  getConf11() {
    return this.conf11;
  }
  getConf12() {
    return this.conf12;
  }
  getConf13() {
    return this.conf13;
  }
  getConf14() {
    return this.conf14;
  }
  getConf15() {
    return this.conf15;
  }
  getConf16() {
    return this.conf16;
  }
  getConf17() {
    return this.conf17;
  }
  getConf18() {
    return this.conf18;
  }
  getConf19() {
    return this.conf19;
  }
  getConf20() {
    return this.conf20;
  }
  getConf21() {
    return this.conf21;
  }
  getConf22() {
    return this.conf22;
  }
  getConf23() {
    return this.conf23;
  }
  getConf24() {
    return this.conf24;
  }
  getConf25() {
    return this.conf25;
  }
  getConf26() {
    return this.conf26;
  }
}
