import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from '@lib/components/landing-page/landing-page.component';
import { UnauthorizedComponent } from '@lib/components/unauthorized/unauthorized.component';
import { ConsultantParametersComponent } from 'src/app/components/consultant-parameters/consultant-parameters.component';
import { DeclareOperationComponent } from './components/declare-operation/declare-operation.component';
import { MyOperationsComponent } from './components/my-operations/my-operations.component';
import { OperationDetailsConsultantComponent } from './components/my-operations/operation-details-consultant/operation-details-consultant.component';
import { InitGuard, authGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'actes',
    title: 'Actes de gestion - Box Placement',
    component: DeclareOperationComponent,
    canActivate: [authGuard],
  },
  {
    path: 'actes/:operationId',
    title: (route: ActivatedRouteSnapshot) =>
      'Actes de gestion ' + (route.params['operationId'] ?? '') + ' - Box Placement',
    component: DeclareOperationComponent,
    canActivate: [authGuard],
  },
  {
    path: 'operations',
    title: 'Opérations - Box Placement',
    component: MyOperationsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'operation/:operationId',
    title: (route: ActivatedRouteSnapshot) => 'Opération ' + (route.params['operationId'] ?? '') + ' - Box Placement',
    component: OperationDetailsConsultantComponent,
    canActivate: [authGuard],
  },
  { path: 'souscription', title: 'Souscription', component: DeclareOperationComponent, canActivate: [authGuard] },
  {
    path: 'souscription/:operationId',
    title: (route: ActivatedRouteSnapshot) =>
      'Souscription ' + (route.params['operationId'] ?? '') + ' - Box Placement',
    component: DeclareOperationComponent,
    canActivate: [authGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'parameters', component: ConsultantParametersComponent, canActivate: [authGuard] },
  { path: 'init', component: LandingPageComponent, canActivate: [InitGuard] },
  { path: '**', redirectTo: 'operations' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
