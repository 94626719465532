import { Component, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OptionsService } from '@lib/services/options.service';
import { OuiButtonToggleOption } from 'omnium-ui/button-toggle';
import { AbstractSpecificDataComponent } from '../../abstract-specific-data.component';

const TYPE_TAUX_FRAIS_KEY = 'typeTauxFrais';
const IMPACT_DIMINUTION_FRAIS_KEY = 'impactDiminutionFrais';
const DEROGATION_VALUE = 'derogation';
@Component({
  selector: 'app-type-taux-frais',
  templateUrl: './type-taux-frais.component.html',
  styleUrls: ['./type-taux-frais.component.scss'],
})
export class TypeTauxFraisComponent extends AbstractSpecificDataComponent {
  override getDataName(): string {
    return 'typeTaux';
  }

  metadata: any = {};

  isDerogation: boolean;

  typeControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  typeOptions: OuiButtonToggleOption<string>[];

  impactControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  impactOptions: OuiButtonToggleOption<string>[];

  impactBannerTitle=`En cas d'impact sur la lignée, il est nécessaire de fournir l’accord lignée.`

  constructor(private optionsService: OptionsService) {
    super();
  }

  ngOnInit() {
    this.typeOptions = this.optionsService.getTypeTauxFraisOptions();
    this.impactOptions = this.optionsService.getImpactTauxFraisOptions();

    this.typeControl.valueChanges.subscribe(value => {
      this.metadata[TYPE_TAUX_FRAIS_KEY] = value;
      this.isDerogation = value === DEROGATION_VALUE;
      if (!this.isDerogation && this.metadata[IMPACT_DIMINUTION_FRAIS_KEY]) {
        this.metadata[IMPACT_DIMINUTION_FRAIS_KEY] = undefined;
      }
      if (this.isDerogation && this.impactControl.value) {
        this.metadata[IMPACT_DIMINUTION_FRAIS_KEY] = this.impactControl.value;
      }
      this.onSpecificDataChanged.emit({
        dataSource: this.getDataName(),
        data: { isFormValid: this.isValidForm(), data: this.metadata },
      });
    });
    this.impactControl.valueChanges.subscribe(value => {
      this.metadata[IMPACT_DIMINUTION_FRAIS_KEY] = value;
      this.onSpecificDataChanged.emit({
        dataSource: this.getDataName(),
        data: { isFormValid: this.isValidForm(), data: this.metadata },
      });
    });

    // push initial state
    this.onSpecificDataChanged.emit({
      dataSource: this.getDataName(),
      data: { isFormValid: this.isValidForm(), data: {} },
    });
  }

  isValidForm(): boolean {
    if (this.typeControl.valid) {
      if (!this.isDerogation) {
        return true;
      }
      if (this.isDerogation && this.impactControl.valid) {
        return true;
      }
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    var data = changes['initialData']?.currentValue?.data;
    if (data) {
      const typeTauxFrais = data[TYPE_TAUX_FRAIS_KEY];
      this.typeControl.setValue(typeTauxFrais);
      if (typeTauxFrais === DEROGATION_VALUE) {
        this.isDerogation = true;
        const impact = data[IMPACT_DIMINUTION_FRAIS_KEY];
        if (impact) {
          this.impactControl.setValue(impact);
        }
      }
    }
  }
}
