import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogModule } from 'omnium-ui/dialog';

@Component({
  selector: 'app-non-conformite-response-dialog',
  standalone: true,
  imports: [CommonModule, OuiDialogModule, OuiIconModule, OuiButtonModule],
  templateUrl: './non-conformite-response-dialog.component.html',
  styleUrls: ['./non-conformite-response-dialog.component.scss'],
})
export class NonConformiteResponseDialogComponent {}
