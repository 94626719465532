import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { FileInfo } from '../souscription-file-items.component';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';

@Component({
  selector: 'app-souscription-file-item',
  templateUrl: './souscription-file-item.component.html',
  styleUrls: ['./souscription-file-item.component.scss']
})
export class SouscriptionFileItemComponent {
  @Input({ required: true })
  fileInfo: FileInfo;

  @Input({ required: true })
  isFileLoading: boolean = false;

  @Output()
  addFileClick = new EventEmitter();

  @Output()
  fileSelected = new EventEmitter();

  @HostBinding('class.selectable')
  get isSelectable(): boolean {
    return !this.fileInfo.isMissing;
  }
  get isCoInvestisseur(): boolean {
    return !!this.fileInfo.fichierOperation?.estCoInvestisseur;
  }
  @HostListener('click')


  onClick() {
    if (!this.isSelectable) return;
    this.fileSelected.emit();
  }
}
