<div oui-dialog-title>
  Changer d'enveloppe ?
  <oui-button type="icon" [oui-dialog-close]>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  Attention, tout changement d'enveloppe entraîne la perte des informations produits saisies.<br />
  Êtes-vous sûr de valider ce changement d'enveloppe ?
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" [oui-dialog-close]>Annuler</oui-button>
  <oui-button type="contained" (click)="confirmChange()"> Oui, valider le changement </oui-button>
</oui-dialog-actions>
