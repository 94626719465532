import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OuiBanner } from 'omnium-ui/banner';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiSnackbarModule, OuiSnackbarService } from 'omnium-ui/snackbar';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';

@Component({
  selector: 'app-declare-operation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    OuiDialogModule,
    OuiButtonModule,
    OuiIconModule,
    OuiBanner,
    OuiSnackbarModule,
    OuiLoadingModule,
  ],
  templateUrl: './declare-operation-dialog.component.html',
  styleUrls: ['./declare-operation-dialog.component.scss'],
})
export class DeclareOperationDialogComponent {
  isLoading: boolean = false;
  isSouscription: boolean = false;
  message: string = '';

  constructor(
    private draftOperationService: DraftOperationsService,
    private snackbarService: OuiSnackbarService,
    private dialogRef: MatDialogRef<DeclareOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      isSouscription: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.isSouscription = this.data.isSouscription ?? false;

    this.message =
      "<p>Pensez à bien ajouter <b> tous les documents de l'opération (bulletins, justificatifs)</b>." +
      " La liste des documents exigés n'est pas nécessairement exhaustive.</p>";
  }

  async declareOperation() {
    this.isLoading = true;
    try {
      var result = await this.draftOperationService.declareAllOperationsInGroup();
      if (result) {
        this.snackbarService.open(
          'Votre opération a bien été déclarée. Vous pouvez la retrouver dans l\'onglet "En cours de traitement"',
          'success',
          7000,
          { horizontal: 'left', vertical: 'bottom' }
        );
        this.dialogRef.close(true);
      }
    } catch (error) {
      this.snackbarService.open("Une erreur est survenue durant la déclaration de l'opération", 'error', 5000);
    }

    this.isLoading = false;
  }
}
