import { ChangeDetectorRef, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConsultantParametersConfig } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

const getParameters = gql`
  query getParameters {
    ConsultantParametersConfig {
      id
      code
      notifyOriginauxReceived
      notifyNC
      notifyInstance
      notifyTransferPartenaire
    }
  }
`;

const setParameters = gql`
  mutation saveConsultantParametersConfig($config: ConsultantParametersConfigInput!) {
    saveConsultantParametersConfig(config: $config) {
      id
      code
      notifyOriginauxReceived
      notifyNC
      notifyInstance
      notifyTransferPartenaire
    }
  }
`;

export type ToggleParameter = { id: number; displayedLabel: string; value: boolean };
const NOTIFY_ORIGINAUX_ID = 0;
const NOTIFY_NC_ID = 1;
const NOTIFY_INSTANCE_ID = 2;
const NOTIFY_TRANSFER_ID = 3;

@Component({
  selector: 'app-consultant-parameters',
  templateUrl: './consultant-parameters.component.html',
  styleUrls: ['./consultant-parameters.component.scss'],
})
export class ConsultantParametersComponent {
  onBackArrowClick() {
    window.history.back();
  }

  dataSource: MatTableDataSource<ToggleParameter> = new MatTableDataSource<ToggleParameter>([]);
  config?: ConsultantParametersConfig;

  displayedColumns = ['label', 'toggle'];
  isLoading: boolean = false;

  constructor(
    private queryManager: QueryManagerService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.fetchConfig();
  }

  updateConfig(config: ConsultantParametersConfig) {
    this.config = deepCopy(config) as ConsultantParametersConfig;
    this.dataSource = new MatTableDataSource<ToggleParameter>([
      {
        id: NOTIFY_ORIGINAUX_ID,
        displayedLabel: 'Confirmation de la réception des documents originaux Stellium',
        value: config.notifyOriginauxReceived,
      },
      { id: NOTIFY_NC_ID, displayedLabel: 'Non-conformité à traiter', value: config.notifyNC },
      { id: NOTIFY_INSTANCE_ID, displayedLabel: 'Instance partenaire à traiter', value: config.notifyInstance },
      { id: NOTIFY_TRANSFER_ID, displayedLabel: 'Envoi dossier au partenaire', value: config.notifyTransferPartenaire },
    ]);
  }

  async onParameterToggleClick(param: ToggleParameter) {
    if (!this.config) return;
    switch (param.id) {
      case NOTIFY_ORIGINAUX_ID:
        this.config.notifyOriginauxReceived = !param.value;
        break;
      case NOTIFY_NC_ID:
        this.config.notifyNC = !param.value;
        break;
      case NOTIFY_INSTANCE_ID:
        this.config.notifyInstance = !param.value;
        break;
      case NOTIFY_TRANSFER_ID:
        this.config.notifyTransferPartenaire = !param.value;
    }
    const result = await firstValueFrom(
      this.queryManager.mutate<{ saveConsultantParametersConfig: ConsultantParametersConfig }>({
        mutation: setParameters,
        variables: {
          config: {
            notifyOriginauxReceived: this.config.notifyOriginauxReceived,
            notifyNC: this.config.notifyNC,
            notifyInstance: this.config.notifyInstance,
            notifyTransferPartenaire: this.config.notifyTransferPartenaire,
          },
        },
      })
    );
    if (result?.data?.saveConsultantParametersConfig) {
      this.updateConfig(result.data.saveConsultantParametersConfig);
    }
  }

  async fetchConfig() {
    this.isLoading = true;
    const result = await firstValueFrom(
      this.queryManager.query<{ ConsultantParametersConfig: ConsultantParametersConfig }>({
        query: getParameters,
      })
    );
    if (result?.data?.ConsultantParametersConfig) {
      this.isLoading = false;
      this.updateConfig(result.data.ConsultantParametersConfig);
    } else {
      this.isLoading = false;
    }
  }
}
