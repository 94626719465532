<div class="flex-vertical gap-32">
  <app-type-signature-partenaire
    [operation]="operation"
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-type-signature-partenaire>

  <app-fonds-evenementiels
    (onSpecificDataChanged)="onDataChange($event)"
    [operationIndex]="operationIndex"
    [initialData]="initialData"></app-fonds-evenementiels>
  <app-specific-comment
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-specific-comment>
</div>
