<!-- <oui-select label="Type de taux de frais" [control]="control" [options]="options" class="width-100pc"></oui-select> -->
<div class="flex-vertical width-100pc gap-16">
  <div class="flex-vertical width-100pc gap-8">
    <div class="body-1">Type de taux de frais*</div>
    <oui-button-toggle [options]="typeOptions" [control]="typeControl" class="width-100pc"></oui-button-toggle>
  </div>
  <div class="flex-vertical width-100pc gap-8" *ngIf="isDerogation">
    <div class="flex-horizontal width-100pc flex-center gap-8">
      <div class="body-1">Impact diminution des frais</div>
    </div>
    <oui-button-toggle [options]="impactOptions" [control]="impactControl"></oui-button-toggle>
    <oui-banner [title]="impactBannerTitle" declination="alert" [showCloseButton]="false"></oui-banner>
  </div>
</div>
