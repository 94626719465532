import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Operation } from '@lib/models/generated/graphql';

export interface OperationSpecificDataValue {
  isFormValid: boolean;
  data: any;
}

export interface OperationSpecificDataEntry {
  dataSource: string;
  data: OperationSpecificDataValue;
}

/**
 * Classe component abstraite qui permet d'utiliser le pattern composition pour la construction des ihm de données specifiques
 *
 * Cette classe factorise le code de gestion des remonté d'info depuis les input vers le composant subscribe.
 *
 * Il necessite pour chaque sous-composant de lui attribuer une clé (string) unique qui sert à assurer l'unicité des données remonté
 * (ie qu'on on ne garde que la dernière valeur lorsqu'un un même sous-composant remonte plusieures valeur consecutivement)
 */
@Component({
  template: '',
})
export abstract class AbstractSpecificDataComponent {
  @Input()
  operation?: Operation;

  @Input()
  operationIndex: number;

  @Input()
  initialData?: OperationSpecificDataValue;

  @Output()
  onSpecificDataChanged = new EventEmitter<OperationSpecificDataEntry>();

  currentData: Map<string, OperationSpecificDataValue> = new Map();

  onDataChange(dataEntry: OperationSpecificDataEntry) {
    this.currentData.set(dataEntry.dataSource, dataEntry.data);

    let formValidity = true;
    let mergedData: Record<string, any> = {};

    this.currentData.forEach((value, key) => {
      (formValidity = formValidity && value.isFormValid),
        //formValidity = true // FOR DEV :  pour ne pas avoir à remplir le formulaire set à true sinon formValidity && value.isFormValid;
        (mergedData = { ...mergedData, ...value.data });
    });
    this.onSpecificDataChanged.emit({
      dataSource: this.getDataName(),
      data: {
        isFormValid: formValidity,
        data: mergedData,
      },
    });
  }

  abstract getDataName(): string;
}
