import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  CreateFichierOperationInput,
  FichierOperation,
  FileMetadata,
  Investisseur,
  Operation,
} from '@lib/models/generated/graphql';
import { nonConformiteInstanceFragment } from '@lib/models/graphqlFragments';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { SelectedFileConfig } from 'src/app/components/declare-operation/souscription-file-items/souscription-file-items.component';

const consultantResponseFichierOperationFragment = gql`
  fragment consultantResponseFichierOperation on FichierOperation {
    id
    natureDocumentRequisId
    operationId
    fileId
    estHorsConfiguration
    estCoInvestisseur
    natureDocumentRequis {
      id
      natureDocument {
        id
        nom
        categorieId
        boxDocumentType {
          isParentGedTypeHaveMultipleBoxDocumentTypes
          key
          gedParentTypeCode
        }
        categorie {
          libelle
        }
      }
    }
    commentaire
    reponseConsultant
    fichierOperationStatut {
      id
      statut
    }
    nonConformiteFichiers {
      ...nonConformiteInstance
    }
    metadata {
      fileId
      fileName
      denomination
      typeLibelle
      creePar
      dateDeCreation
      derniereModificationPar
      dateDeDerniereModification
      url
      extension
    }
  }
  ${nonConformiteInstanceFragment}
`;

const createBatchResponseFichierOperation = gql`
  mutation createBatchResponseFichierOperation($batchCreateFichierOperationInput: CreateBatchFichierOperationInput!) {
    createBatchResponseFichierOperation(input: $batchCreateFichierOperationInput) {
      ...consultantResponseFichierOperation
    }
  }
  ${consultantResponseFichierOperationFragment}
`;

const UPDATE_FICHIER_OPERATION_COMMENT = gql`
  mutation updateFichierOperationCommentaire($fichierOperationId: ID!, $commentaire: String!) {
    updateFichierOperationCommentaire(fichierOperationId: $fichierOperationId, commentaire: $commentaire) {
      commentaire
    }
  }
`;
const COMMENT_REQUIRED = 'Indiquez votre réponse';
const COMMENT_OPTIONAL = 'Commentaire (facultatif)';
@Injectable({
  providedIn: 'root',
})
export class ConsultantReponseService {
  investisseurs: Investisseur[];
  coInvestisseurId?: number;
  isCommentMode = false;
  commentaireNCReponse?: string | null;

  commentaireNCControl: FormControl;
  commentaireNCFormLabel: string;

  fichiersOperations: FichierOperation[] = [];

  constructor(private queryManager: QueryManagerService) {}

  setCommentAsRequired() {
    this.commentaireNCControl = new FormControl<string>(this.commentaireNCReponse ?? '', {
      nonNullable: true,
      validators: [Validators.required],
    });
    this.commentaireNCFormLabel = COMMENT_REQUIRED;
    this.isCommentMode = true;
  }

  setCommentAsOptional() {
    this.commentaireNCControl = new FormControl<string | null | undefined>(this.commentaireNCReponse);
    this.commentaireNCFormLabel = COMMENT_OPTIONAL;
    this.isCommentMode = true;
  }

  closeCommentMode() {
    this.isCommentMode = false;
  }

  async addFichierOperationToOperation(selectedFilesConfig: SelectedFileConfig[], operationId: number) {
    if (selectedFilesConfig.length > 0 && operationId) {
      const createInput: CreateFichierOperationInput[] = selectedFilesConfig.map(config => ({
        fileId: config.fileId,
        operationId: operationId,
        natureDocumentRequisId: config.natureDocumentRequisId,
        commentaire: config.comment,
        estHorsConfig: config.isOutOfConfig,
        estCoInvestisseur: config.investisseurId === this.coInvestisseurId,
      }));

      if (createInput && createInput.length > 0) {
        await this.createBatchNewFichierOperationInNCResponse(createInput);
      }
    }
  }
  setFichierOperationsAndInvestisseurs(operation: Operation) {
    this.fichiersOperations = this.sortFichiersOperation(operation.fichierOperations);
    this.investisseurs = [];
    if (operation.investisseur) {
      this.investisseurs.push(operation.investisseur);
    }
    if (operation.coInvestisseur) {
      this.investisseurs.push(operation.coInvestisseur);
      this.coInvestisseurId = operation.coInvestisseur.id;
    }
  }

  private sortFichiersOperation(fichiers: FichierOperation[]): FichierOperation[] {
    return fichiers.sort((a, b) => {
      return (a.natureDocumentRequis?.natureDocument?.categorie?.libelle ?? '').localeCompare(
        b.natureDocumentRequis?.natureDocument?.categorie?.libelle ?? ''
      );
    });
  }
  addUploadedFilesToInvestisseurs(files: FileMetadata[]) {
    this.investisseurs.find(investisseur => investisseur.id === files[0].investisseurId)?.filesMetadata?.push(...files);
  }

  private async createBatchNewFichierOperationInNCResponse(fichierOperationToCreate: CreateFichierOperationInput[]) {
    const newFichierOperations = await firstValueFrom(
      this.queryManager.mutate<{ createBatchResponseFichierOperation: FichierOperation[] }>({
        mutation: createBatchResponseFichierOperation,
        variables: {
          batchCreateFichierOperationInput: { batchConfig: fichierOperationToCreate },
        },
      })
    );
    if (newFichierOperations.data?.createBatchResponseFichierOperation) {
      //update files
      this.fichiersOperations = [
        ...this.fichiersOperations,
        ...(newFichierOperations.data?.createBatchResponseFichierOperation ?? []),
      ];
      //  update Investisseur files
      this.addUploadedFilesToInvestisseurs(
        newFichierOperations.data?.createBatchResponseFichierOperation.map(
          fichierOperation => fichierOperation.metadata!
        ) ?? []
      );
    }
  }

  async updateFichierOperationCommentaire(fichierOperationId: number, commentaire: string) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ updateFichierOperationCommentaire: { id: number; commentaire: string } }>({
        mutation: UPDATE_FICHIER_OPERATION_COMMENT,
        variables: {
          fichierOperationId: fichierOperationId,
          commentaire: commentaire,
        },
      })
    );
    if (result && result.data) {
      const com = result.data.updateFichierOperationCommentaire.commentaire;
      const fileIndex = this.fichiersOperations.findIndex(file => file.id === fichierOperationId);
      if (fileIndex > -1) {
        this.fichiersOperations[fileIndex].commentaire = com;
      }
    }
  }
}
