import { Component, EventEmitter, Output } from '@angular/core';
import { Notification } from '@lib/models/generated/graphql';
import { AuthService } from '@lib/services/auth-service.service';
import { OuiBannerAction } from 'omnium-ui/banner';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponentComponent {
  currentNotification: Notification | undefined;
  @Output() onActionClicked = new EventEmitter<string>();
  action: OuiBannerAction = {
    label: 'Voir',
    action: () => {
      this.onCurrentNotificationValidated();
    },
  };

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.userNotificationsSubject.subscribe(notifications => {
      if (notifications && notifications.length > 0) {
        this.currentNotification = notifications[0];
        this.action.label = this.currentNotification.linkLabel ?? 'Voir';
      } else {
        this.currentNotification = undefined;
      }
    });
  }

  onCurrentNotificationValidated() {
    if (this.currentNotification) {
      if (this.currentNotification.link) {
        this.onActionClicked.emit(this.currentNotification.link);
      }

      //remove notification
      this.authService.deleteNotificationByLink(this.currentNotification.link ?? undefined);
      // current notification while be automatically updated after current notification will be deleted
    }
  }
}
