<div oui-dialog-title>
  Supprimer un produit
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content> Les informations saisies seront perdues. </oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="deleteProduit()"> Supprimer </oui-button>
</oui-dialog-actions>
