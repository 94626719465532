import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionConsultantCreationPMColumn,
  CreationPersonneMoraleDashboardColumn,
  DateDemandeCreationPMColumn,
  FormeSocialeCreationPMColumn,
  InvestisseurPhysiqueCreationPMColumn,
  RaisonSocialeCreationPMColumn,
  StatutCreationPMColumn,
} from '@lib/components/creation-personne-morale-dashboard/creation-personne-morale-dashboard-column';
import {
  DeclarationDashboardColumn,
  DeleteDraftDashboardColumn,
  EnveloppeDashboardColumn,
  InvestisseurDashboardColumn,
  MAJDashboardColumn,
  MontantDashboardColumn,
  NatureDashboardColumn,
  OperationDashboardColumn,
  OperationIDDashboardColumn,
  PictoConsultantDashboardColumn,
  ProduitDashboardColumn,
  StatutConsultantDashboardColumn,
} from '@lib/components/operations-dashboard/operations-dashboard-columns';
import { DashboardFilterEnum } from '@lib/models/dashboardFilterEnum';
import { AuthService } from '@lib/services/auth-service.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiTabGroup } from 'omnium-ui/tabs';
import {
  CreationPersonneMorale,
  CreationPersonneMoraleFilterInput,
  CreationPersonneMoraleStatut,
  Investisseur,
  InvestisseurType,
  Operation,
  OperationFilterInput,
  OperationType,
} from 'projects/box-lib/src/lib/models/generated/graphql';
import { firstValueFrom, Subscription } from 'rxjs';
import { CreationPersonneMoraleCountService } from 'src/service/creation-personne-morale-count.service';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { NoneActiveHabilitationMessageComponent } from '../none-active-habilitation-message/none-active-habilitation-message.component';
import { CreationPMTab, OperationTab } from './consultant-tab';
const INVESTISSEUR_BY_ID = gql`
  query investisseurById($id: Long!) {
    investisseurById(id: $id) {
      id
      code
      investisseurEntite {
        displayName
        type
      }
    }
  }
`;
@Component({
  selector: 'app-my-operations',
  templateUrl: './my-operations.component.html',
  styleUrls: ['./my-operations.component.scss'],
})
export class MyOperationsComponent {
  @ViewChild(OuiTabGroup) tabGroup: OuiTabGroup;
  toTreatItemNumber: string = '';
  waitingItemNumber: string = '';
  historyItemNumber: string = '';
  creationPMItemNumber: string = '';

  noHabilAction: OuiBannerAction = {
    label: 'En savoir plus',
    action: () => {
      this.openHabilitationMessage();
    },
  };
  toTreatColumnSelection: OperationDashboardColumn[] = [
    new OperationIDDashboardColumn(),
    new InvestisseurDashboardColumn(),
    new NatureDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new DeclarationDashboardColumn(),
    new MAJDashboardColumn(),
    new StatutConsultantDashboardColumn(),
    new DeleteDraftDashboardColumn(),
  ];

  inProgressColumnSelection: OperationDashboardColumn[] = [
    new OperationIDDashboardColumn(),
    new InvestisseurDashboardColumn(),
    new NatureDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new DeclarationDashboardColumn(),
    new MAJDashboardColumn(),
    new StatutConsultantDashboardColumn(),
    new PictoConsultantDashboardColumn(),
  ];

  historiqueColumnSelection: OperationDashboardColumn[] = [
    new OperationIDDashboardColumn(),
    new InvestisseurDashboardColumn(),
    // TODO Consultant vendeur
    new NatureDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new DeclarationDashboardColumn(),
    new MAJDashboardColumn(),
    new StatutConsultantDashboardColumn(),
  ];

  creationPMColumnSelection: CreationPersonneMoraleDashboardColumn[] = [
    new RaisonSocialeCreationPMColumn(),
    new FormeSocialeCreationPMColumn(),
    new InvestisseurPhysiqueCreationPMColumn(),
    new DateDemandeCreationPMColumn(),
    new StatutCreationPMColumn(),
    new ActionConsultantCreationPMColumn(),
  ];

  filterSelection: DashboardFilterEnum[] = [
    DashboardFilterEnum.Statut,
    DashboardFilterEnum.Investisseur,
    DashboardFilterEnum.Nature,
    DashboardFilterEnum.Produit,
  ];

  // statut ids strictly lower than 100 cover draft and operation with NC and operation with instance
  toTreatDefaultQueryFilter: OperationFilterInput = {
    statutId: { lt: 100 },
  };
  // statut ids greater than and equal to 100 cover all operation process by back Office - 400 is for done operation
  inProgressDefaultQueryFilter: OperationFilterInput = {
    and: [{ statutId: { gte: 100 } }, { statutId: { lt: 400 } }],
  };

  // statut ids greater than and equal to 400 - opérations terminées
  historiqueDefaultQueryFilter: OperationFilterInput = {
    and: [{ statutId: { gte: 400 } }],
  };

  isNotHabilitedWarning: boolean = false;
  userHabilitationCheckSubscription: Subscription;

  tabs: (CreationPMTab | OperationTab)[];
  toTreatTab: OperationTab = new OperationTab(
    'A traiter',
    'a-traiter',
    this.toTreatColumnSelection,
    this.filterSelection,
    this.toTreatDefaultQueryFilter
  );
  inProgressTab: OperationTab = new OperationTab(
    'En cours de traitement',
    'en-cours',
    this.inProgressColumnSelection,
    this.filterSelection,
    this.inProgressDefaultQueryFilter
  );

  historiqueTab: OperationTab = new OperationTab(
    'Historique',
    'historique',
    this.historiqueColumnSelection,
    this.filterSelection,
    this.historiqueDefaultQueryFilter
  );
  creationPMTab: CreationPMTab = new CreationPMTab('Création personne morale', this.creationPMColumnSelection);
  constructor(
    private router: Router,
    private draftOperationService: DraftOperationsService,
    private authService: AuthService,
    private dialogService: OuiDialogService,
    private creationPMService: CreationPersonneMoraleCountService,
    private queryManager: QueryManagerService
  ) {}

  ngOnInit() {
    this.userHabilitationCheckSubscription = this.authService.userHabilitationCheck.subscribe(value => {
      if (value) {
        this.isNotHabilitedWarning = !this.authService.isAnyHabilitationValid();
      }
    });
    this.setTabs();
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.userHabilitationCheckSubscription) {
      this.userHabilitationCheckSubscription.unsubscribe();
    }
  }
  onDeclareSubsButtonClick() {
    this.draftOperationService.reset();
    this.router.navigate(['souscription']);
  }

  onDeclareActeButtonClick() {
    this.draftOperationService.reset();
    this.router.navigate(['actes']);
  }

  onOperationClick(operation: Operation) {
    if (operation.statutId <= 5) {
      // statutId lower than 5 match to draft souscription that can be displayed in souscription page
      if (operation.operationType == OperationType.ActeDeGestion) {
        this.router.navigate(['actes', operation.id]);
      } else {
        this.router.navigate(['souscription', operation.id]);
      }
    } else {
      this.router.navigate(['operation', operation.id]);
    }
  }

  isAuth(): boolean {
    return this.authService.isAuthenticated();
  }
  openHabilitationMessage() {
    this.dialogService.openDialog(
      NoneActiveHabilitationMessageComponent,
      {
        title: 'Aucune habilitation active',
      },
      'auto',
      '662px'
    );
  }

  isCreationTab(tab: CreationPMTab | OperationTab): tab is CreationPMTab {
    return tab.template === 'creation';
  }

  isOperationTab(tab: CreationPMTab | OperationTab): tab is OperationTab {
    return tab.template === 'operation';
  }

  async setTabs() {
    const withPMs = [this.toTreatTab, this.inProgressTab, this.creationPMTab, this.historiqueTab];
    const withoutPMs = [this.toTreatTab, this.inProgressTab, this.historiqueTab];
    const filter: CreationPersonneMoraleFilterInput = {
      statut: { neq: CreationPersonneMoraleStatut.Utilise },
    };
    const count = await this.creationPMService.getCreationPersonneMoraleCount(filter);
    this.tabs = count > 0 ? withPMs : withoutPMs;
  }

  findCreationPMTabIndex(): number {
    let creationPMTabIndex = this.tabs.findIndex(tab => tab.template === 'creation');
    if (creationPMTabIndex === -1) {
      creationPMTabIndex = 0;
    }
    return creationPMTabIndex;
  }

  goToCreationPM() {
    const creationPMTabIndex = this.findCreationPMTabIndex();
    this.tabGroup.selectedIndex = creationPMTabIndex;
  }

  async onDeclareSouscriptionFromCreationPM(creationPM: CreationPersonneMorale) {
    this.draftOperationService.reset();
    if (creationPM.atlasInvestisseurId) {
      const result = await firstValueFrom(
        this.queryManager.query<{ investisseurById: Investisseur }>({
          query: INVESTISSEUR_BY_ID,
          variables: {
            id: creationPM.atlasInvestisseurId,
          },
        })
      );

      if (result?.data?.investisseurById) {
        this.draftOperationService.reset();
        this.draftOperationService.investisseur = result.data.investisseurById;
        this.draftOperationService.investisseurType = InvestisseurType.PersonneMorale;
        this.router.navigate(['souscription']);
      }
    }
  }
}
