import { gql } from 'apollo-angular';

export const declareAllOperationsInGroup = gql`
  mutation declareAllOperationsInGroup($operationGroupId: Int!) {
    declareAllOperationsInGroup(operationGroupId: $operationGroupId) {
      id
      operations {
        id
        statutId
        statut {
          id
          consultantLibelle
          backOfficeLibelle
        }
        activeOperationStateTransitionTriggers
      }
    }
  }
`;
