import { gql } from 'apollo-angular';

export const fireGroupStateTransitionTriggerMutation = gql`
  mutation fireOperationStateTransitionTriggerForOperationGroup(
    $operationGroupId: Int!
    $trigger: OperationStateTransitionTrigger!
  ) {
    fireOperationStateTransitionTriggerForOperationGroup(operationGroupId: $operationGroupId, trigger: $trigger) {
      id
      operations {
        id
        statutId
        statut {
          id
          consultantLibelle
          backOfficeLibelle
        }
        activeOperationStateTransitionTriggers
      }
    }
  }
`;
