import { createFichierOperationFragment } from '@lib/models/graphqlFragments';
import { gql } from 'apollo-angular';

export const tryCreateBatchDistinctFilesinOperationForGroupMutation = gql`
  mutation tryCreateBatchDistinctFilesinOperationForGroup($input: CreateBatchFichierOperationForGroupInput!) {
    tryCreateBatchDistinctFilesinOperationForGroup(input: $input) {
      ...createFichierOperation
    }
  }
  ${createFichierOperationFragment}
`;
