import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OperationSpecificData } from '@lib/models/donees-specifiques.model';
import { Operation, OperationConfig, OperationType, Produit } from '@lib/models/generated/graphql';
import { OperationConfigService } from '@lib/services/operation-config.service';
import { OperationsService } from '@lib/services/operations.service';
import { ProduitService } from '@lib/services/produit.service';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { NoneActiveHabilitationMessageComponent } from '../../none-active-habilitation-message/none-active-habilitation-message.component';
import { OperationSpecificDataValue } from '../operation-specific/abstract-specific-data.component';

@Component({
  selector: 'app-operation-information-form',
  templateUrl: './operation-information-form.component.html',
  styleUrls: ['./operation-information-form.component.scss'],
})
export class OperationInformationFormComponent implements OnInit, AfterViewInit {
  @Input() operation: Operation | undefined;
  @Input() index: number;
  @Input() expanded: boolean = true;
  @Input() deleteDisabled: boolean = true;

  private _selectableProduits: Produit[] = [];
  public get selectableProduits(): Produit[] {
    return this._selectableProduits;
  }

  @Input()
  public set selectableProduits(value: Produit[]) {
    if (value && value === this._selectableProduits) return;
    this._selectableProduits = value;
    this.updateProduitOptions();
  }

  @Output() onOperationConfigChanged = new EventEmitter<OperationConfig | null>();
  @Output() onProductChanged = new EventEmitter<Produit | null>();
  @Output() onSpecificDataChanged = new EventEmitter<OperationSpecificDataValue>();
  @Output() onDeleteClicked = new EventEmitter<Operation>();

  initialData: any;
  title: string;
  isChoiceDone: boolean = false;
  initSpecificData: OperationSpecificDataValue;
  initOperationConfig: OperationConfig | undefined;

  acteGestionType = OperationType.ActeDeGestion;
  souscriptionType = OperationType.Souscription;
  SOUSCRIPTION_NATURE_ID = 1;

  produits: Produit[] = [];
  produitControl = new FormControl<number | null | undefined>(null, Validators.required);
  produitAutocompleteOptions: OuiAutocompleteOption<number>[] = [];

  private initProduitId?: number;

  constructor(
    private operatioConfigService: OperationConfigService,
    private draftOperationService: DraftOperationsService,
    private dialogService: OuiDialogService,
    private operationsService: OperationsService,
    private produitService: ProduitService
  ) {}

  ngOnInit() {
    if (this.operation?.donneesSpecifiques) {
      let data: OperationSpecificData = this.operationsService.parseSpecificData(this.operation.donneesSpecifiques);
      this.initSpecificData = { isFormValid: false, data }; // la valeur du isFormValid n'a pas d'impact ici. seul la data est utilisée.
    }

    if (this.operation?.operationConfigId) {
      this.initOperationConfig = this.operation.operationConfig!;
    }

    if (this.operation?.produit) {
      this.initProduitId = this.operation.produit.id;
    }

    this.title = this.operation?.operationType === OperationType.Souscription ? 'Produit' : 'Acte de Gestion';
  }

  ngAfterViewInit(): void {
    if (this.operation?.operationType === OperationType.Souscription) {
      this.initProduitAutocomplete();
    }
  }

  private async initProduitAutocomplete() {
    await this.updateProduitOptions();

    setTimeout(() => {
      this.produitControl.setValue(this.initProduitId);
    });

    this.produitControl.valueChanges.subscribe(value => {
      const produit = this.produits.find(p => p.id === value);
      this.onProduitSelected(produit);
    });
  }

  private async updateProduitOptions(): Promise<void> {
    if (this.selectableProduits.length > 0) {
      this.produits = this.selectableProduits;
    } else {
      this.produits = await this.produitService.getAllProduits();
    }

    this.produitAutocompleteOptions = this.produits
      .map(p => <OuiAutocompleteOption<number>>{ value: p.id, label: p.nomTechniqueProduitBox ?? p.nom })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  async onNatureOperationSelected(natureOperationId: number | null) {
    if (this.operation && this.operation.produit?.enveloppeNavigation) {
      this.isChoiceDone = false;
      if (natureOperationId) {
        this.operation.operationConfig = await this.operatioConfigService.fetchSingleOperationConfig(
          natureOperationId,
          this.operation.produit.enveloppeNavigation.code
        );
        this.operation.operationConfigId = this.operation.operationConfig?.id;
        this.isChoiceDone = true;
      } else {
        this.operation.operationConfig = undefined;
        this.operation.operationConfigId = null;
      }
      this.onOperationConfigChanged.emit(this.operation.operationConfig);
    }
  }

  async onProduitSelected(produit: Produit | undefined) {
    if (this.operation && produit?.id != this.operation.produit?.id) {
      this.isChoiceDone = false;
      if (produit?.enveloppeNavigation?.code) {
        this.operation.operationConfig = await this.operatioConfigService.fetchSingleOperationConfig(
          this.SOUSCRIPTION_NATURE_ID,
          produit.enveloppeNavigation.code
        );
        this.operation.operationConfigId = this.operation.operationConfig?.id;
        this.isChoiceDone = true;
      } else {
        this.operation.operationConfig = undefined;
        this.operation.operationConfigId = null;
      }
      this.operation.produit = produit;
      this.onOperationConfigChanged.emit(this.operation.operationConfig);
      this.onProductChanged.emit(this.operation.produit);
    }
  }

  onSpecificDataChange(newData: OperationSpecificDataValue) {
    this.onSpecificDataChanged.emit(newData);
  }

  onDeleteClick() {
    this.onDeleteClicked.emit(this.operation);
  }

  noHabilAction: OuiBannerAction = {
    label: 'En savoir plus',
    action: () => {
      this.openHabilitationMessage();
    },
  };
  isHabilitedProduit() {
    if (!this.operation?.produit) {
      return true;
    }

    return this.draftOperationService.isUserHabilitedOperation(this.operation);
  }

  openHabilitationMessage() {
    this.dialogService.openDialog(
      NoneActiveHabilitationMessageComponent,
      {
        title: 'Aucune habilitation active pour ce produit',
      },
      'auto',
      '662px'
    );
  }
}
