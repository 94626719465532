import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@lib/services/auth-service.service';

export const authGuard: CanActivateFn = (route, state) => {
  if (inject(AuthService).isInitLogin()) {
    inject(Router).navigate(['/init']);
  } else if (!inject(AuthService).isAuthenticated()) {
    inject(Router).navigate(['/unauthorized']);
  }
  return true;
};

export const InitGuard: CanActivateFn = (route, state) => {
  if (!inject(AuthService).isInitLogin()) {
    inject(Router).navigate(['']);
  }
  return true;
};
