import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { OperationSpecificDataEntry, OperationSpecificDataValue } from './abstract-specific-data.component';
import { OperationInformationConfigService } from 'src/service/operation-information-config.service';
import { Operation } from '@lib/models/generated/graphql';

@Component({
  selector: 'app-subscribe-specific',
  templateUrl: './subscribe-specific.component.html',
  styleUrls: ['./subscribe-specific.component.scss'],
})
export class SubscribeSpecificComponent {
  @Input()
  operation?: Operation;

  @Input()
  operationIndex: number;

  @Input()
  operationConfigId?: number;

  @Input()
  initialData?: OperationSpecificDataValue;

  @Output()
  onSpecificDataChanged: EventEmitter<OperationSpecificDataValue> = new EventEmitter<OperationSpecificDataValue>();

  confTemplateMap: Map<number[], TemplateRef<any>> = new Map<number[], TemplateRef<any>>();
  @ViewChild('noConf', { static: true }) noConfTemplate: TemplateRef<any>;
  @ViewChild('conf1', { static: true }) conf1Template: TemplateRef<any>;
  @ViewChild('conf2', { static: true }) conf2Template: TemplateRef<any>;
  @ViewChild('conf3', { static: true }) conf3Template: TemplateRef<any>;
  @ViewChild('conf4', { static: true }) conf4Template: TemplateRef<any>;
  @ViewChild('conf5', { static: true }) conf5Template: TemplateRef<any>;
  @ViewChild('conf6', { static: true }) conf6Template: TemplateRef<any>;
  @ViewChild('conf7', { static: true }) conf7Template: TemplateRef<any>;
  @ViewChild('conf8', { static: true }) conf8Template: TemplateRef<any>;
  @ViewChild('conf9', { static: true }) conf9Template: TemplateRef<any>;
  @ViewChild('conf10', { static: true }) conf10Template: TemplateRef<any>;
  @ViewChild('conf11', { static: true }) conf11Template: TemplateRef<any>;
  @ViewChild('conf12', { static: true }) conf12Template: TemplateRef<any>;
  @ViewChild('conf13', { static: true }) conf13Template: TemplateRef<any>;
  @ViewChild('conf14', { static: true }) conf14Template: TemplateRef<any>;
  @ViewChild('conf15', { static: true }) conf15Template: TemplateRef<any>;
  @ViewChild('conf16', { static: true }) conf16Template: TemplateRef<any>;
  @ViewChild('conf17', { static: true }) conf17Template: TemplateRef<any>;
  @ViewChild('conf18', { static: true }) conf18Template: TemplateRef<any>;
  @ViewChild('conf19', { static: true }) conf19Template: TemplateRef<any>;
  @ViewChild('conf20', { static: true }) conf20Template: TemplateRef<any>;
  @ViewChild('conf21', { static: true }) conf21Template: TemplateRef<any>;
  @ViewChild('conf22', { static: true }) conf22Template: TemplateRef<any>;
  @ViewChild('conf23', { static: true }) conf23Template: TemplateRef<any>;
  @ViewChild('conf24', { static: true }) conf24Template: TemplateRef<any>;
  @ViewChild('conf25', { static: true }) conf25Template: TemplateRef<any>;
  @ViewChild('conf26', { static: true }) conf26Template: TemplateRef<any>;

  ngOnInit() {
    this.confTemplateMap.set(this.infoConfigService.getConf1(), this.conf1Template);
    this.confTemplateMap.set(this.infoConfigService.getConf2(), this.conf2Template);
    this.confTemplateMap.set(this.infoConfigService.getConf3(), this.conf3Template);
    this.confTemplateMap.set(this.infoConfigService.getConf4(), this.conf4Template);
    this.confTemplateMap.set(this.infoConfigService.getConf5(), this.conf5Template);
    this.confTemplateMap.set(this.infoConfigService.getConf6(), this.conf6Template);
    this.confTemplateMap.set(this.infoConfigService.getConf7(), this.conf7Template);
    this.confTemplateMap.set(this.infoConfigService.getConf8(), this.conf8Template);
    this.confTemplateMap.set(this.infoConfigService.getConf9(), this.conf9Template);
    this.confTemplateMap.set(this.infoConfigService.getConf10(), this.conf10Template);
    this.confTemplateMap.set(this.infoConfigService.getConf11(), this.conf11Template);
    this.confTemplateMap.set(this.infoConfigService.getConf12(), this.conf12Template);
    this.confTemplateMap.set(this.infoConfigService.getConf13(), this.conf13Template);
    this.confTemplateMap.set(this.infoConfigService.getConf14(), this.conf14Template);
    this.confTemplateMap.set(this.infoConfigService.getConf15(), this.conf15Template);
    this.confTemplateMap.set(this.infoConfigService.getConf16(), this.conf16Template);
    this.confTemplateMap.set(this.infoConfigService.getConf17(), this.conf17Template);
    this.confTemplateMap.set(this.infoConfigService.getConf18(), this.conf18Template);
    this.confTemplateMap.set(this.infoConfigService.getConf19(), this.conf19Template);
    this.confTemplateMap.set(this.infoConfigService.getConf20(), this.conf20Template);
    this.confTemplateMap.set(this.infoConfigService.getConf21(), this.conf21Template);
    this.confTemplateMap.set(this.infoConfigService.getConf22(), this.conf22Template);
    this.confTemplateMap.set(this.infoConfigService.getConf23(), this.conf23Template);
    this.confTemplateMap.set(this.infoConfigService.getConf24(), this.conf24Template);
    this.confTemplateMap.set(this.infoConfigService.getConf25(), this.conf25Template);
    this.confTemplateMap.set(this.infoConfigService.getConf26(), this.conf26Template);
  }
  constructor(private infoConfigService: OperationInformationConfigService) {}

  getMatchingTemplate(): TemplateRef<any> | null {
    if (!this.operationConfigId) {
      return null;
    }
    for (const [key, value] of this.confTemplateMap) {
      if (key.includes(this.operationConfigId)) {
        return value;
      }
    }
    return this.noConfTemplate;
  }
  onDataChange(newData: OperationSpecificDataEntry) {
    this.onSpecificDataChanged.emit(newData.data);
  }
}
