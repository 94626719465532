import { Router } from '@angular/router';
import { CreationPersonneMoraleDashboardColumn } from '@lib/components/creation-personne-morale-dashboard/creation-personne-morale-dashboard-column';

import { OperationDashboardColumn } from '@lib/components/operations-dashboard/operations-dashboard-columns';
import { DashboardFilterEnum } from '@lib/models/dashboardFilterEnum';
import { Operation, OperationFilterInput, OperationType } from '@lib/models/generated/graphql';

 class ConsultantBasicTab {
  public label: string;
  public readonly template: 'operation' | 'creation';
  public labelPrefix: string;

  constructor(template: 'operation' | 'creation', labelPrefix: string) {
    this.template = template;
    this.labelPrefix = labelPrefix;
    this.label = `${labelPrefix} (0)`;
  }

  updateLabel(count: number) {
    this.label = `${this.labelPrefix} ${this.formatNumberToString(count)}`;
  }

  private formatNumberToString(number: number | undefined): string {
    return Number.isNaN(number) ? `(0)` : `(${number})`;
  }
  
}

export class OperationTab extends ConsultantBasicTab { 
  public dashboardKey: string;
  public columnSelection: OperationDashboardColumn[];
  public filterSelection: DashboardFilterEnum[];
  public defaultQueryFilter: OperationFilterInput;
  constructor(
    labelPrefix: string,
    dashboardKey: string,
    columnSelection: OperationDashboardColumn[],
    filterSelection: DashboardFilterEnum[],
    defaultQueryFilter: OperationFilterInput
  ) {
    super('operation', labelPrefix);
    this.dashboardKey = dashboardKey;
    this.columnSelection = columnSelection;
    this.filterSelection = filterSelection;
    this.defaultQueryFilter = defaultQueryFilter;
  }

}
export class CreationPMTab extends ConsultantBasicTab {
public readonly dashboardConfiguration = 'consultant'; 
  public columnSelection: CreationPersonneMoraleDashboardColumn[];
  constructor( labelPrefix: string, columnSelection: CreationPersonneMoraleDashboardColumn[]) {
    super('creation', labelPrefix);
    this.columnSelection = columnSelection;
  }

}
