import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
  CreateFichierOperationInput,
  FichierOperation,
  FichierOperationInGroupInput,
  NatureDocumentRequi,
  Operation,
  OperationType,
  Produit,
} from '@lib/models/generated/graphql';
import { OPERATION_CATEGORIE_ID } from '@lib/models/graphqlData';
import { MissingFichierOperation } from '@lib/models/types';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import { firstValueFrom } from 'rxjs';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { createAndReplaceRequiredFichierOperationInOperationGroup } from 'src/service/draft-operation/mutations/create-and-replace-required-fichierOperation-in-operationGroup';
import { createBatchFichierOperationAllOperations } from 'src/service/draft-operation/mutations/create-batch-fichier-operation-multiple.mutation';
import { NoneActiveHabilitationMessageComponent } from '../../none-active-habilitation-message/none-active-habilitation-message.component';
import {
  DEFAULT_ID_FOR_MISSING_DOC,
  SelectedFileConfig,
} from '../souscription-file-items/souscription-file-items.component';

@Component({
  selector: 'app-operation-files-configuration',
  templateUrl: './operation-files-configuration.component.html',
  styleUrls: ['./operation-files-configuration.component.scss'],
})
export class OperationFilesConfigurationComponent {
  @Input() operationId?: number;
  @Input() index: number;
  // @Input() operation?: Operation;
  // @Input() investisseur?: Investisseur;
  @Output() onAddedFichierOperation = new EventEmitter<{ isMissingDoc: boolean; operationId: number }>();

  filesInformationList: (FichierOperation | MissingFichierOperation)[];
  connaissanceClientRequiredFichierOperation: (FichierOperation | MissingFichierOperation)[];
  OpeRequiredFichierOperations: (FichierOperation | MissingFichierOperation)[];
  operationFiles: (FichierOperation | MissingFichierOperation)[];
  produit: Produit;

  operation?: Operation;

  acteGestionType = OperationType.ActeDeGestion;

  isHabilited: boolean;
  noHabilAction: OuiBannerAction = {
    label: 'En savoir plus',
    action: () => {
      this.openHabilitationMessage();
    },
  };
  constructor(
    private queryManager: QueryManagerService,
    protected draftOperationsService: DraftOperationsService,
    private dialogService: OuiDialogService
  ) {
    draftOperationsService.operationListInGroup$.subscribe(async operationList => {
      let operation = operationList.find(op => op.id === this.operationId);
      if (operation) {
        this.operation = operation;
        await this.initValues(operation);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    const id = changes['operationId']?.currentValue;
    if (id) {
      const operationFound = this.draftOperationsService.operationGroupData?.operations.find(
        op => op.id === this.operationId
      );
      if (operationFound) {
        this.operation = operationFound;
        await this.initValues(operationFound);
      }
    }
  }

  async initValues(operation: Operation) {
    if (operation?.produit) {
      this.updateFileInformation(operation);
      this.produit = operation.produit;
      this.isHabilited = this.draftOperationsService.isUserHabilitedOperation(operation);
    }
  }
  updateFileInformation(operation?: Operation) {
    const investisseur = this.draftOperationsService.investisseur;
    const coInvestisseur = this.draftOperationsService.coInvestisseur;
    let newFilesInformationList: (FichierOperation | MissingFichierOperation)[] = [];
    if (operation?.fichierOperations) {
      newFilesInformationList = [...operation.fichierOperations];
    }
    if (operation?.operationConfig?.filteredNatureDocumentRequis && investisseur?.id) {
      operation.operationConfig.filteredNatureDocumentRequis.forEach(docRequis => {
        // check if document requis already covered by an existing fichierOperation

        this.pushMissingFileInListIfNatureNotCovered(newFilesInformationList, docRequis, operation.id, investisseur.id);
        if (docRequis.withAutoImportCoInv && coInvestisseur) {
          this.pushMissingFileInListIfNatureNotCovered(
            newFilesInformationList,
            docRequis,
            operation.id,
            coInvestisseur.id
          );
        }
      });
    }

    this.filesInformationList = newFilesInformationList;

    this.OpeRequiredFichierOperations = newFilesInformationList?.filter(
      fileInfo => fileInfo?.natureDocumentRequis?.natureDocument.categorieId === OPERATION_CATEGORIE_ID
    );
    this.operationFiles = newFilesInformationList?.filter(fileInfo => !fileInfo?.natureDocumentRequis);
    this.operationFiles = this.OpeRequiredFichierOperations.concat(this.operationFiles);
    const isMissingDoc =
      this.OpeRequiredFichierOperations.some(fi => fi.id === DEFAULT_ID_FOR_MISSING_DOC) ||
      this.operationFiles.length === 0;
    if (operation) {
      this.onAddedFichierOperation.emit({ isMissingDoc, operationId: operation.id });
    }
  }

  pushMissingFileInListIfNatureNotCovered(
    newFilesInformationList: (FichierOperation | MissingFichierOperation)[],
    docRequis: NatureDocumentRequi,
    operationId: number,
    investisseurId: number
  ) {
    const estCoInv = investisseurId === this.draftOperationsService.coInvestisseur?.id;
    if (!newFilesInformationList.find(fileInfo => this.isNatureAlreadyCoveredByFile(docRequis, fileInfo, estCoInv))) {
      //file not already covered by a FichierOperation ==> create a dedicated FichierOperation Entry with -1 for id
      newFilesInformationList.push({
        id: DEFAULT_ID_FOR_MISSING_DOC,
        fileId: '',
        natureDocumentRequisId: docRequis.id,
        natureDocumentRequis: docRequis,
        operationId: operationId,
        estHorsConfiguration: false,
        estCoInvestisseur: estCoInv,
      });
    }
  }
  isNatureAlreadyCoveredByFile(
    nature: NatureDocumentRequi,
    file: FichierOperation | MissingFichierOperation,
    estCoInvestisseurFile: boolean
  ): boolean {
    return file.natureDocumentRequisId === nature.id && file.estCoInvestisseur === estCoInvestisseurFile;
  }

  async addFichierOperation(selectedFilesConfig: SelectedFileConfig[]) {
    if (Array.isArray(selectedFilesConfig) && selectedFilesConfig.length > 0) {
      if (
        selectedFilesConfig.length === 1 &&
        selectedFilesConfig[0].forceInAllOperations &&
        selectedFilesConfig[0].natureDocumentRequisId && //if there is only one selectedFileConfig and it is forced in all operations and it is required file
        this.draftOperationsService.operationGroupData?.id
      ) {
        const groupId = this.draftOperationsService.operationGroupData.id;
        const fichierOperationToCreate = this.buildFichierOperationInGroupInput(selectedFilesConfig[0], groupId);
        if (fichierOperationToCreate) {
          const updatedOperations = await this.createAndReplaceFichichierOperationInCurrentGroup(
            fichierOperationToCreate
          );
          this.draftOperationsService.updateOperationGroupDataFiles(updatedOperations);
        }
      } else if (this.operation?.id) {
        const operationId = this.operation.id;
        const fichierOperationToCreate = this.buildCreateFichierOperationInputList(selectedFilesConfig, operationId);
        if (fichierOperationToCreate && fichierOperationToCreate.length > 0) {
          await this.createAndAddFichierOperations(fichierOperationToCreate);
        }
      }
    }
  }
  buildFichierOperationInGroupInput(sfo: SelectedFileConfig, groupId: number): FichierOperationInGroupInput {
    const input: FichierOperationInGroupInput = {
      fileId: sfo.fileId,
      natureDocumentRequisId: sfo.natureDocumentRequisId,
      commentaire: sfo.comment,
      estHorsConfig: sfo.isOutOfConfig ?? false,
      estCoInvestisseur: sfo.investisseurId === this.draftOperationsService.coInvestisseur?.id,
      operationGroupId: groupId,
    };

    return input;
  }

  buildCreateFichierOperationInputList(
    selectedFilesConfig: SelectedFileConfig[],
    clickedOperationId: number
  ): CreateFichierOperationInput[] {
    const operationIds = this.draftOperationsService.operationGroupData?.operations.map(op => op.id);
    const fichierOperationToCreateInput: CreateFichierOperationInput[] = [];
    selectedFilesConfig.forEach(sfo => {
      if (sfo.forceInAllOperations) {
        operationIds.forEach(operationId => {
          fichierOperationToCreateInput.push(this.mapSelectedFileConfigToCreateFichierOperationInput(sfo, operationId));
        });
      } else {
        fichierOperationToCreateInput.push(
          this.mapSelectedFileConfigToCreateFichierOperationInput(sfo, clickedOperationId)
        );
      }
    });
    return fichierOperationToCreateInput;
  }
  mapSelectedFileConfigToCreateFichierOperationInput(
    sfo: SelectedFileConfig,
    operationId: number
  ): CreateFichierOperationInput {
    const fichierOperationToCreateInput: CreateFichierOperationInput = {
      fileId: sfo.fileId,
      operationId: operationId,
      natureDocumentRequisId: sfo.natureDocumentRequisId,
      commentaire: sfo.comment,
      estHorsConfig: sfo.isOutOfConfig ?? false,
      estCoInvestisseur: sfo.investisseurId === this.draftOperationsService.coInvestisseur?.id,
    };
    return fichierOperationToCreateInput;
  }
  async createAndReplaceFichichierOperationInCurrentGroup(input: FichierOperationInGroupInput): Promise<Operation[]> {
    const newFichierOperationResult = await firstValueFrom(
      this.queryManager.mutate<{ createAndReplaceRequiredFichierOperationInOperationGroup: FichierOperation[] }>({
        mutation: createAndReplaceRequiredFichierOperationInOperationGroup,
        variables: {
          input: input,
        },
      })
    );
    if (
      newFichierOperationResult.data?.createAndReplaceRequiredFichierOperationInOperationGroup?.every(f => f.operation)
    ) {
      return newFichierOperationResult.data?.createAndReplaceRequiredFichierOperationInOperationGroup.map(
        f => f.operation!
      );
    }
    return [];
  }

  async createAndAddFichierOperations(fichierOperationToCreate: CreateFichierOperationInput[]) {
    const newFichierOperations = await firstValueFrom(
      this.queryManager.mutate<{ createBatchFichierOperation: FichierOperation[] }>({
        mutation: createBatchFichierOperationAllOperations,
        variables: {
          batchCreateFichierOperationInput: { batchConfig: fichierOperationToCreate },
        },
      })
    );

    //if FichierOperation have been created we update current operation with then (TODO : transfer this behavior in apollo layer)
    if (newFichierOperations.data?.createBatchFichierOperation?.every(f => f.operation) && this.operation) {
      const updatedOperations = newFichierOperations.data?.createBatchFichierOperation.map(f => f.operation!);

      this.draftOperationsService.updateOperationGroupDataFiles(updatedOperations);

      this.updateFileInformation(this.operation);
    }
  }

  async onFileRemoved() {
    this.updateFileInformation(this.operation);
  }

  openHabilitationMessage() {
    this.dialogService.openDialog(
      NoneActiveHabilitationMessageComponent,
      {
        title: 'Aucune habilitation active pour ce produit',
      },
      'auto',
      '662px'
    );
  }
}
