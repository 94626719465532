import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorInterceptor } from '../interceptors/auth-interceptor.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatDialogModule } from '@angular/material/dialog';

import { environment } from '../environments/environment';

// Omnium UI
import { OuiAccordionModule } from 'omnium-ui/accordion';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiButtonToggle } from 'omnium-ui/button-toggle';
import { OuiChipModule } from 'omnium-ui/chip';
import { OuiDialogModule } from 'omnium-ui/dialog';
import {
  OuiAutocomplete,
  OuiCheckbox,
  OuiRadioButton,
  OuiRadioButtonGroup,
  OuiSelect,
  OuiTextarea,
  OuiTextfield,
} from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiInfosBulle } from 'omnium-ui/infos-bulle';
import { OuiLayoutModule, OuiNavButtonModule, OuiNavigationPanelModule } from 'omnium-ui/layout';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiMenuModule } from 'omnium-ui/menu';
import { OuiNumber } from 'omnium-ui/number';
import { OuiPaginatorModule } from 'omnium-ui/paginator';
import { OuiSearchbar } from 'omnium-ui/searchbar';
import { OuiSelectFilter } from 'omnium-ui/select-filter';
import { OuiSlideToggle } from 'omnium-ui/slide-toggle';
import { OuiStepper } from 'omnium-ui/stepper';
import { OuiTableModule } from 'omnium-ui/table';
import { OuiTabsModule } from 'omnium-ui/tabs';
import { OuiTagModule } from 'omnium-ui/tag';

import { OuiTooltipModule } from 'omnium-ui/tooltip';
import { ContratSelectorComponent } from './components/declare-operation/contrat-selector/contrat-selector.component';
import { ContratsTableComponent } from './components/declare-operation/contrats-table/contrats-table.component';
import { DeclareOperationComponent } from './components/declare-operation/declare-operation.component';
import { OperationFilesConfigurationComponent } from './components/declare-operation/operation-files-configuration/operation-files-configuration.component';
import { OperationInformationFormComponent } from './components/declare-operation/operation-information-form/operation-information-form.component';
import { OperationSpecificDataModule } from './components/declare-operation/operation-specific/operation-specific-data.module';
import { SouscriptionFileItemsComponent } from './components/declare-operation/souscription-file-items/souscription-file-items.component';
import { MyOperationsComponent } from './components/my-operations/my-operations.component';
import { OperationDetailsConsultantComponent } from './components/my-operations/operation-details-consultant/operation-details-consultant.component';

import { BoxLibModule } from '@lib/box-lib.module';
import { ApplicationInsightsErrorHandler } from '@lib/utils/ApplicationInsightsErrorHandler';
import { OuiBanner } from 'omnium-ui/banner';
import { ConsultantParametersComponent } from 'src/app/components/consultant-parameters/consultant-parameters.component';
import { UserNotificationComponentComponent } from 'src/app/components/my-operations/user-notification/user-notification.component';
import { CreationPersonneMoraleConsultantDialogComponent } from './components/declare-operation/investisseur-step-declaration/creation-personne-morale-consultant-dialog/creation-personne-morale-consultant-dialog.component';
import { InvestisseurDuplicatesDialogComponent } from './components/declare-operation/investisseur-step-declaration/investisseur-duplicates-dialog/investisseur-duplicates-dialog.component';
import { InvestisseurStepDeclarationComponent } from './components/declare-operation/investisseur-step-declaration/investisseur-step-declaration.component';
import { OperationAccordionTitleComponent } from './components/declare-operation/operation-accordion-title/operation-accordion-title.component';
import { OperationGroupFilesConfigurationComponent } from './components/declare-operation/operation-group-files-configuration/operation-group-files-configuration.component';
import { NoConfigBannerComponent } from './components/declare-operation/operation-specific/no-config-banner/no-config-banner.component';
import { SouscriptionFileItemComponent } from './components/declare-operation/souscription-file-items/souscription-file-item/souscription-file-item.component';
import { StepInformationsComponent } from './components/declare-operation/step-informations/step-informations.component';
import { CancelDeclarationConfirmComponent } from './components/my-operations/operation-details-consultant/cancel-declaration-confirm/cancel-declaration-confirm.component';
import { ResponseNcDocumentViewerComponent } from './components/my-operations/response-nc-pdf-viewer/response-nc-document-viewer.component';
import { NoneActiveHabilitationMessageComponent } from './components/none-active-habilitation-message/none-active-habilitation-message.component';
import { GraphQLModule } from './graphql.module';

@NgModule({
  declarations: [
    AppComponent,
    SouscriptionFileItemsComponent,
    MyOperationsComponent,
    OperationDetailsConsultantComponent,
    ContratsTableComponent,
    ContratSelectorComponent,
    OperationInformationFormComponent,
    OperationFilesConfigurationComponent,
    DeclareOperationComponent,
    OperationGroupFilesConfigurationComponent,
    NoConfigBannerComponent,
    NoneActiveHabilitationMessageComponent,
    InvestisseurStepDeclarationComponent,
    StepInformationsComponent,
    CancelDeclarationConfirmComponent,
    ConsultantParametersComponent,
    SouscriptionFileItemComponent,
    CreationPersonneMoraleConsultantDialogComponent,
    InvestisseurDuplicatesDialogComponent,
    UserNotificationComponentComponent,
  ],
  imports: [
    BoxLibModule,
    OuiLayoutModule,
    OuiIconModule,
    OuiButtonModule,
    OuiTabsModule,
    OuiTagModule,
    OuiTableModule,
    OuiPaginatorModule,
    OuiNavButtonModule,
    OuiNavigationPanelModule,
    OuiStepper,
    OuiSlideToggle,
    OuiSelect,
    OuiBanner,
    OuiRadioButton,
    OuiRadioButtonGroup,
    OuiLoadingModule,
    OuiSelectFilter,
    OuiAccordionModule,
    OuiTextfield,
    OuiTextarea,
    OuiCheckbox,
    OuiAutocomplete,
    OuiButtonToggle,
    OuiMenuModule,
    OuiSearchbar,
    OuiChipModule,
    OuiNumber,
    OuiTooltipModule,
    OuiDialogModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GraphQLModule,
    OperationSpecificDataModule,
    OperationAccordionTitleComponent,
    OuiInfosBulle,
    ResponseNcDocumentViewerComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthInterceptorInterceptor },
    { provide: 'environment', useValue: environment },
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    CurrencyPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localeFr, 'fr-FR');
