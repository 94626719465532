<div class="page-layout flex-horizontal">
  <div class="flex-1"></div>
  <div class="flex-none flex-vertical gap-16">
    <oui-button type="icon" (click)="onBackArrowClick()"><oui-icon>arrow_back</oui-icon></oui-button>
    <h4>Notifications e-mails</h4>
    <div class="flex-vertical oui-elevation-04 background-white rounded padding-32 gap-40 panel-layout min-height-500">
      <div class="width-100pc">
        <oui-table>
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef>Label</th>
              <td mat-cell *matCellDef="let element">{{ element?.displayedLabel }}</td>
            </ng-container>

            <ng-container matColumnDef="toggle">
              <th mat-header-cell *matHeaderCellDef>Toggle</th>

              <td mat-cell *matCellDef="let element">
                <div class="text-end" [ngClass]="element?.value ? 'color-primary-500' : ''">
                  {{ element?.value ? 'Activé' : 'Désactivé' }}
                  <oui-slide-toggle
                    [label]=""
                    (checkedChange)="onParameterToggleClick(element)"
                    [checked]="element?.value ?? true"
                    (click)="$event.stopPropagation()"
                    class="padding-lateral-8">
                  </oui-slide-toggle>
                </div>
              </td>
            </ng-container>
            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
            <tr mat-row [class.interactive]="true" *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
        </oui-table>
      </div>
    </div>
  </div>
  <div class="flex-1"></div>
</div>
