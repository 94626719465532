<div class="flex-horizontal">
  <div class="stepper flex-vertical gap-56 background-white">
    <h5>{{ stepperTitle }}</h5>
    <oui-stepper
      [steps]="steps"
      [displayNumbers]="false"
      direction="vertical"
      (currentStepChange)="onStepChange($event)"
      #stepper></oui-stepper>
  </div>
  <div class="content contain">
    <div *ngIf="isLoading; else dataLoaded">
      <oui-loading class="loadingCenterItem"></oui-loading>
    </div>
    <ng-template #dataLoaded>
      <div class="flex-vertical background-white width-100pc oui-elevation-04 padding-32 gap-32 card">
        <div class="flex-vertical gap-8">
          <h6>{{ this.stepper?.currentStep?.title }}</h6>
          <div
            class="body-2"
            *ngIf="
              !draftOperationsService.operationGroupData.contrat && stepper?.currentStep?.title == CONTRAT_STEP_TITLE
            ">
            Sélectionnez un contrat
          </div>
        </div>
        <oui-banner
          *ngIf="!isActiveHabilitation"
          title="Vous n'avez aucune habilitation active en placement"
          declination="error"
          [action]="noHabilAction"></oui-banner>
        <ng-container *ngIf="this.stepper?.currentStep?.title == CONTRAT_STEP_TITLE">
          <app-contrat-selector
            (onContratSelection)="onContratSelected($event)"
            [contratId]="draftOperationsService.operationGroupData.contrat?.id!"
            [isModifierDisabled]="!!draftOperationsService.operationGroupData.id"></app-contrat-selector>
        </ng-container>

        <ng-container *ngIf="this.stepper?.currentStep?.title == INVESTISSEUR_STEP_TITLE">
          <app-investisseur-step-declaration></app-investisseur-step-declaration>
        </ng-container>

        <ng-container *ngIf="this.stepper?.currentStep?.title == INFORMATION_STEP_TITLE">
          <app-step-informations></app-step-informations>
        </ng-container>
        <ng-container *ngIf="this.stepper?.currentStep?.title == FILE_STEP_TITLE">
          <app-operation-group-files-configuration> </app-operation-group-files-configuration>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
<app-stepper-footer
  [isNextStepDisabled]="!isNextButonEnabled()"
  [previousStepButtonText]="previousLabel"
  [nextStepButtonText]="ctaLabel"
  [nextStepButtonIcon]="!isLastStep"
  [isSaveDisplayed]="isSaveButtonDisplayed()"
  (nextStepClicked)="onNextStepClick()"
  (previousStepClicked)="onPreviousStepClick()"
  (saveAndExitClicked)="onSaveAndExit()">
</app-stepper-footer>
