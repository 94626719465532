import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Commande,
  Operation,
  OperationConfig,
  OperationStateTransitionTrigger,
  OperationType,
  Produit,
} from '@lib/models/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { OuiStep, OuiStepper } from 'omnium-ui/stepper';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { OperationFilesConfigurationComponent } from './operation-files-configuration/operation-files-configuration.component';
import { OperationSpecificDataValue } from './operation-specific/abstract-specific-data.component';

import { AuthService } from '@lib/services/auth-service.service';
import { OperationsService } from '@lib/services/operations.service';

import { Location } from '@angular/common';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import { DraftOperationsFilesService } from 'src/service/draft-operation/draft-operation-files.service';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { NoneActiveHabilitationMessageComponent } from '../none-active-habilitation-message/none-active-habilitation-message.component';
import { DeclareOperationDialogComponent } from './declare-operation-dialog/declare-operation-dialog.component';

@Component({
  selector: 'app-declare-operation',
  templateUrl: './declare-operation.component.html',
  styleUrls: ['./declare-operation.component.scss'],
})
export class DeclareOperationComponent implements OnInit, AfterViewInit {
  readonly INFORMATION_STEP_TITLE = 'Informations';
  readonly CONTRAT_STEP_TITLE = 'Contrat';
  readonly FILE_STEP_TITLE = 'Documents & justificatifs';
  readonly INVESTISSEUR_STEP_TITLE = 'Investisseur(s)';

  @ViewChild('stepper') stepper?: OuiStepper;
  @ViewChild('operationFilesConfiguration') operationFilesConfiguration?: OperationFilesConfigurationComponent;

  isLoading = true;
  isProcessingNextStep: boolean = false;

  steps: OuiStep[] = [];
  currentStep?: OuiStep;

  acteDeGestionSteps: OuiStep[] = [
    {
      title: this.CONTRAT_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
    {
      title: this.INFORMATION_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
    {
      title: this.FILE_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
  ];

  souscriptionSteps: OuiStep[] = [
    {
      title: this.INVESTISSEUR_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
    {
      title: this.INFORMATION_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
    {
      title: this.FILE_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
  ];

  previousLabel: string = 'Retour';

  updatePreviousLabel() {
    if (this.currentStep === this.stepper?.steps[0]) {
      this.previousLabel = 'Retour';
      return;
    }

    this.previousLabel = 'Etape précédente';
  }

  get ctaLabel(): string {
    if (this.currentStep?.title === this.FILE_STEP_TITLE) {
      return this.draftOperationsService.operationGroupData.operations.length > 1
        ? 'Déclarer les opérations'
        : "Déclarer l'opération";
    }

    return 'Etape suivante';
  }

  get isLastStep(): boolean {
    return this.currentStep?.title === this.steps[this.steps.length - 1].title;
  }

  specificData: OperationSpecificDataValue;
  initOperationConfig: Maybe<OperationConfig> | undefined;
  selectedProduit: Produit | undefined | null;

  isSpecificDataValid: boolean;
  stepperTitle: string;

  pageType: OperationType;
  addButtonLabel: string;

  noHabilAction: OuiBannerAction = {
    label: 'En savoir plus',
    action: () => {
      this.openHabilitationMessage();
    },
  };

  isProduitHabilited: boolean;
  isActiveHabilitation: boolean = true;
  userHabilitedCheckSubscription: Subscription;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected draftOperationsService: DraftOperationsService,
    protected draftOperationsFilesServices: DraftOperationsFilesService,
    private dialogService: OuiDialogService,
    private authService: AuthService,
    private operationsService: OperationsService,
    private cdr: ChangeDetectorRef,
    private location: Location
  ) {
    this.draftOperationsService.operationGroup$.pipe(takeUntil(this.destroy$)).subscribe(operationGroup => {
      if (operationGroup.opertionType) this.updateLocationUrl(operationGroup.opertionType);
    });

    this.draftOperationsService.isProduitHabilited$.pipe(takeUntil(this.destroy$)).subscribe(isProduitHabilited => {
      this.isProduitHabilited = isProduitHabilited;
      if (!isProduitHabilited) {
        this.setStepperToError();
      }
    });

    this.authService.userHabilitationCheck.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        this.checkActiveHabilitions();
        this.draftOperationsService.checkProduitHabilited();
      }
    });
  }

  ngOnInit() {
    const path = this.route.snapshot.url[0].path;
    // set stepper according to path (souscription or acte de gestion)
    if (path == 'souscription') {
      this.steps = this.souscriptionSteps;
      this.pageType = OperationType.Souscription;
      this.stepperTitle = 'Déclarer une souscription';
      this.draftOperationsService.operationGroupData.opertionType = OperationType.Souscription;
      this.addButtonLabel = 'Ajouter un produit';

    } else {
      this.steps = this.acteDeGestionSteps;
      this.pageType = OperationType.ActeDeGestion;
      this.stepperTitle = 'Déclarer un acte de gestion';
      this.draftOperationsService.operationGroupData.opertionType = OperationType.ActeDeGestion;
      this.addButtonLabel = 'Ajouter un acte de gestion';
    }
    this.currentStep = this.steps[0];

    this.updatePreviousLabel();

    // check if we need to fetch data for existing operation
    this.tryInitPageOnExistingOperation();
  }

  ngAfterViewInit() {
    // force change detect in order to display the right stepper label and previous button label
    this.cdr.detectChanges();
  }

  checkActiveHabilitions() {
    let isHabilited = true;
    if (this.authService.userHabilitationCheck.getValue()) {
      isHabilited = this.authService.isAnyHabilitationValid();
    }
    if (!isHabilited) {
      this.setStepperToError();
    }
    this.isActiveHabilitation = isHabilited;
  }

  private updateLocationUrl(type: OperationType) {
    const firstOperationId = this.draftOperationsService.operationGroupData.operations[0]?.id;
    const route = type == OperationType.Souscription ? 'souscription' : 'actes';
    if (firstOperationId) {
      this.location.replaceState(`${route}/${firstOperationId}`);
    } else {
      this.location.replaceState(`${route}`);
    }
  }

  /****************
   *  FETCH DATA  *
   ***************/

  private async tryInitPageOnExistingOperation() {
    const operationToOpenId = Number(this.route.snapshot.paramMap.get('operationId'));
    //if inputs contain an operationToOpenId value this means we are oppenning an existing operation in draft

    if (operationToOpenId && !isNaN(operationToOpenId)) {
      // init current operation and files
      await this.draftOperationsService.fetchAndUpdateCurrentGroup(operationToOpenId);

      if (this.draftOperationsService.getMinOperationsStateId() === 1) {
        this.stepper?.setStep(1);
        this.steps[0].state = 'done';
        this.steps[0].icon = 'done';
        this.steps[1].state = 'doing';
      } else if (this.draftOperationsService.getMinOperationsStateId() == 5) {
        this.stepper?.setStep(2);
        this.steps[0].state = 'done';
        this.steps[0].icon = 'done';
        this.steps[1].state = 'done';
        this.steps[1].icon = 'done';
        this.steps[2].state = 'doing';
      }

      //init fields
      if (this.draftOperationsService.investisseur?.id) {
        this.draftOperationsService.fetchAndUpdateInvestisseurData(this.draftOperationsService.investisseur?.id);
      }
      if (this.draftOperationsService.coInvestisseur?.id) {
        this.draftOperationsService.fetchAndUpdateInvestisseurData(
          this.draftOperationsService.coInvestisseur?.id,
          true
        );
      }
    }

    this.isLoading = false;
  }

  /******************
   * FOOTER BUTTONS *
   ******************/

  //** footer buttons enabling */

  isSaveButtonDisplayed(): boolean {
    if (!this.stepper) {
      return false;
    }
    switch (this.stepper.currentStep?.title) {
      case this.INVESTISSEUR_STEP_TITLE:
        return false;
      case this.CONTRAT_STEP_TITLE:
        return false;
      case this.INFORMATION_STEP_TITLE:
        if (this.draftOperationsService.operationGroupData?.id) {
          return true;
        }
        return false;
      case this.FILE_STEP_TITLE:
        return true;
      default:
        return false;
    }
  }

  isNextButonEnabled(): boolean {
    if (!this.currentStep || !this.stepper) return false;

    this.evaluateStepValidity(this.currentStep);
    return this.currentStep.state === 'done';
  }

  private evaluateStepsValidity() {
    if (!this.stepper || this.stepper.stepIndex >= this.steps.length || !this.stepper.currentStep) {
      return;
    }

    let isPreviousStepValid = true;
    for (let index = 0; index < this.stepper.steps.length; index++) {
      const step = this.stepper.steps[index];
      isPreviousStepValid = this.evaluateStepValidity(step, isPreviousStepValid);
    }
  }

  private evaluateStepValidity(step: OuiStep, isPreviousStepValid: boolean = true): boolean {
    let isValid: boolean = false;

    const stepIndex = this.stepper!.steps.indexOf(step);
    const currentStepIndex = this.stepper!.steps.indexOf(this.stepper!.currentStep!);

    if (stepIndex < currentStepIndex) {
      step.state = 'done';
      step.icon = 'done';
      return false;
    }

    if (stepIndex > currentStepIndex) {
      step.state = 'todo';
      step.icon = '';
      return false;
    }

    switch (step.title) {
      case this.INVESTISSEUR_STEP_TITLE:
        isValid = this.isInvestisseurStepValid();
        break;
      case this.CONTRAT_STEP_TITLE:
        isValid = !!this.draftOperationsService.operationGroupData?.contrat;
        break;
      case this.INFORMATION_STEP_TITLE:
        isValid = this.isInformationStepValid();
        break;
      case this.FILE_STEP_TITLE:
        isValid = this.isFileStepValid();
        break;
      //final available only if all required files are selected (ie no more file with DEFAULT_ID_FOR_MISSING_DOC  for id)
    }

    if (isValid) {
      step.state = 'done';
      step.icon = 'done';
    } else {
      step.state = this.stepper?.currentStep === step ? 'doing' : 'todo';
      step.icon = '';
    }

    return isValid;
  }

  isInvestisseurStepValid(): boolean {
    return this.draftOperationsService.isInvestisseurStepValid();
  }
  isInformationStepValid(): boolean {
    const isCompleted =
      this.draftOperationsService.isAllOperationsConfigured() &&
      this.draftOperationsService.isAllFormsValid() &&
      this.draftOperationsService.operationGroupData?.operations.length > 0;
    return isCompleted;
  }

  isFileStepValid(): boolean {
    return (
      this.draftOperationsFilesServices.isGroupMissingFilesFilled(!!this.draftOperationsService.coInvestisseur) &&
      this.isActiveHabilitation &&
      this.isProduitHabilited
    );
  }

  //** footer buttons clicked */
  async onNextStepClick() {
    // Eviter l'envoi de plusieurs requêtes
    if (this.isProcessingNextStep) return;

    this.isProcessingNextStep = true;

    const currentStep = this.stepper?.currentStep?.title;
    switch (currentStep) {
      case this.FILE_STEP_TITLE:
        const isSouscription =
          this.draftOperationsService?.operationGroupData?.opertionType === OperationType.Souscription;
        const dialogRef = this.dialogService.openDialog(
          DeclareOperationDialogComponent,
          { isSouscription: isSouscription },
          'auto',
          '561px'
        );
        dialogRef.afterClosed().subscribe(value => {
          if (value === true) {
            this.exit();
          }
        });
        break;
      case this.CONTRAT_STEP_TITLE:
        if (!this.draftOperationsService.operationGroupData?.id) {
          //if we dont have an id, we create an operation
          if (
            this.draftOperationsService.operationGroupData.contrat?.investisseurCommande &&
            this.draftOperationsService.operationGroupData.contrat.investisseurCommande[0] &&
            this.draftOperationsService.operationGroupData.contrat.produitId
          ) {
            if (await this.draftOperationsService.addNewOperationInGroup()) {
              this.stepper?.completeCurrentStep();
              this.stepper?.nextStep();
            }
          }
        } else {
          if (await this.updateAllOperation()) {
            // we have an id, we update the existing operation
            this.stepper?.completeCurrentStep();
            this.stepper?.nextStep();
          }
        }
        break;
      case this.INVESTISSEUR_STEP_TITLE:
        if (!this.draftOperationsService.operationGroupData?.id) {
          //if we dont have an id, we create an operation
          if (this.draftOperationsService.investisseur?.id) {
            await this.draftOperationsService.addNewOperationInGroup();
            this.stepper?.completeCurrentStep();
            this.stepper?.nextStep();
          }
        } else {
          if (await this.updateAllOperation()) {
            // we have an id, we update the existing operation
            this.stepper?.completeCurrentStep();
            this.stepper?.nextStep();
          }
        }

        break;
      case this.INFORMATION_STEP_TITLE:
        const success = await this.updateAllOperation();

        if (success) {
          // we have updated the operations, we trigger the state transition
          await this.draftOperationsService.fireGroupStateTransitionTrigger(
            OperationStateTransitionTrigger.ConsultantDefinesProduitInSouscriptionDraft
          );
          if (this.draftOperationsService.investisseur?.id) {
            await this.draftOperationsService.fetchAndUpdateInvestisseurData(
              this.draftOperationsService.investisseur?.id
            );
          }
          if (this.draftOperationsService.coInvestisseur?.id) {
            await this.draftOperationsService.fetchAndUpdateInvestisseurData(
              this.draftOperationsService.coInvestisseur?.id,
              true
            );
          }
          this.stepper?.completeCurrentStep();
          this.stepper?.nextStep();
        }
        break;
    }

    this.isProcessingNextStep = false;
  }

  onPreviousStepClick() {
    if (this.currentStep === this.stepper?.steps[0]) {
      this.router.navigate(['operations']);
      return;
    }

    this.stepper?.prevStep();
  }

  onSaveAndExit() {
    this.updateAllOperation().then(success => {
      if (success) {
        this.exit();
      }
    });
  }

  exit() {
    this.router.navigate(['operations']);
  }

  onStepChange(step: OuiStep) {
    if (!this.stepper) return;

    this.currentStep = step;
    this.updatePreviousLabel();
    this.evaluateStepsValidity();
  }

  /**************************************
   * CONTRAT_STEP: ACTE DE GESTION ONLY *
   **************************************/
  onContratSelected(contrat: Commande | null) {
    this.draftOperationsService.operationGroupData.contrat = contrat ?? undefined;
    if (
      this.draftOperationsService.operationGroupData.contrat &&
      this.draftOperationsService.operationGroupData.contrat.investisseurCommande?.[0]
    ) {
      this.draftOperationsService.fetchAndUpdateInvestisseurData(
        this.draftOperationsService.operationGroupData.contrat.investisseurCommande?.[0]?.investisseurId
      );
      if (this.draftOperationsService.operationGroupData.contrat.investisseurCommande?.[1]) {
        this.draftOperationsService.fetchAndUpdateInvestisseurData(
          this.draftOperationsService.operationGroupData.contrat.investisseurCommande?.[1]?.investisseurId,
          true
        );
      }
    }
  }

  /********************
   * INFORMATION_STEP *
   ********************/

  async updateAllOperation(): Promise<boolean> {
    return this.draftOperationsService.updateAllOperations();
  }

  /*************
   * FILE_STEP *
   *************/

  updateFileInformation(operation?: Operation) {
    if (operation?.fichierOperations) {
      this.operationFilesConfiguration?.updateFileInformation(operation);
    }
  }

  openHabilitationMessage() {
    this.dialogService.openDialog(
      NoneActiveHabilitationMessageComponent,
      {
        title: 'Aucune habilitation active',
      },
      'auto',
      '662px'
    );
  }

  setStepperToError() {
    this.stepper?.steps.forEach(step => {
      if (step.icon || step.state === 'doing') {
        step.icon = 'warning';
        step.iconColor = 'error';
      }
    });
    this.cdr.detectChanges();
  }
}
