<div oui-dialog-title>
  Annuler la déclaration ?
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content class="flex-vertical gap-8">
  <ng-container *ngIf="!operationGroupId">
    <div>
      <oui-loading class="flex-horizontal flex-center padding-16 width-100pc justify-center"></oui-loading>
    </div>
  </ng-container>
  <ng-container *ngIf="operationGroupId">
    <div class="padding-bottom-8">{{ confirmationQuestion }}</div>
    <div class="subtitle-2">{{ frameLabel }}</div>
    <div class="padding-16 rounded with-border">
      {{ investisseurLabel }}
      <ul class="margin-none">
        <li *ngFor="let operationInfo of operationInfos">{{ operationInfo }}</li>
      </ul>
    </div>
    <div class="body-1">{{ confirmationStatement }}</div>
  </ng-container>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="onConfirmCancelDeclaration()">Oui, annuler la déclaration </oui-button>
</oui-dialog-actions>
