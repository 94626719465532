import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { lastValueFrom } from 'rxjs';
import { Produit, ProduitFilterInput } from '../models/generated/graphql';
import { AuthService } from './auth-service.service';
import { QueryManagerService } from './queryManagerService';

const allProduits = gql`
  query allProduits($where: ProduitFilterInput) {
    allProduits(where: $where) {
      id
      nom
      nomTechniqueProduitBox
      enveloppeNavigation {
        code
        libelle
      }
      habilitationNavigation {
        id
        libelle
      }
      produitExtension {
        souscriptionPossible
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ProduitService {
  constructor(private queryManager: QueryManagerService, private userService: AuthService) {}

  async getAllProduits(): Promise<Produit[]> {
    const result = await lastValueFrom(
      this.queryManager.query<{ allProduits: Produit[] }>({
        query: allProduits,
      })
    );

    return result.data.allProduits;
  }

  async getProduitsActifsHabilites(): Promise<Produit[]> {
    const result = await lastValueFrom(
      this.queryManager.query<{ allProduits: Produit[] }>({
        query: allProduits,
        variables: {
          where: <ProduitFilterInput>{
            and: [
              { habilitation: { in: this.userService.getUserValidHabilitationIds() } },
              { etat: { eq: 2 } }, // etat: ouvert à la souscription
            ],
          },
        },
      })
    );

    return result.data.allProduits.filter(p => p.produitExtension?.souscriptionPossible);
  }
}
