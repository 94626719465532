import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-none-active-habilitation-message',
  templateUrl: './none-active-habilitation-message.component.html',
  styleUrls: ['./none-active-habilitation-message.component.scss'],
})
export class NoneActiveHabilitationMessageComponent {
  title: string = 'Aucune habilitation active';
  readonly articleUri =
    'https://prodemial.zendesk.com/hc/fr/articles/360019721932-2-Obtenir-votre-habilitation-de-MIA-pour-le-compte-de-Stellium-Courtage';
  readonly relationUri = 'https://prodemial.zendesk.com/hc/fr/articles/360027728731-Transfert-de-relation-secondaire';
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string }) {
    this.title = data.title;
  }
}
