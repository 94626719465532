import { Component } from '@angular/core';
import { AbstractSpecificDataComponent } from '../../abstract-specific-data.component';

@Component({
  selector: 'app-specific-data-conf10',
  templateUrl: './specific-data-conf10.component.html',
  styleUrls: ['../specific-data-conf.common.scss'],
})
export class SpecificDataConf10Component extends AbstractSpecificDataComponent {
  override getDataName(): string {
    return 'specific-data-conf10';
  }
}
