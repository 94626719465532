import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BoxEnvelope } from '@lib/models/box-enveloppe.model';
import { ENVELOPPE_TO_MERGE_LIST, FAKE_ENVELOPPE_MERGED } from '@lib/models/graphqlData';
import { OptionsService } from '@lib/services/options.service';
import { ProduitService } from '@lib/services/produit.service';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import {
  Operation,
  OperationConfig,
  OperationType,
  Produit,
  TransactionPersonnelleEnum,
} from 'projects/box-lib/src/public-api';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { DeleteProduitDialogComponent } from '../delete-produit-dialog/delete-produit-dialog.component';
import { OperationSpecificDataValue } from '../operation-specific/abstract-specific-data.component';
import { EnveloppeChangeConfirmationDialogComponent } from './enveloppe-change-confirmation-dialog/enveloppe-change-confirmation-dialog.component';

@Component({
  selector: 'app-step-informations',
  templateUrl: './step-informations.component.html',
  styleUrls: ['./step-informations.component.scss'],
})
export class StepInformationsComponent implements OnInit, AfterViewInit {
  @Output() specificDataChange = new EventEmitter<{ value: OperationSpecificDataValue; index: number }>();
  @Output() operationConfigChange = new EventEmitter<{ value: OperationConfig | null; index: number }>();
  @Output() productChange = new EventEmitter<{ value: Produit | null; index: number }>();
  @Output() addOperationClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter<Operation>();

  addButtonLabel: string;

  enveloppeControl = new FormControl<number | null>(null, Validators.required);
  enveloppeAutocompleteOptions: OuiAutocompleteOption<number>[] = [];
  selectedEnveloppeId?: number;

  protected operationTypeEnum = OperationType;

  private allProduitsActifsHabilites: Produit[] = [];

  selectableProduits: Produit[] = [];

  selectableProduitHabiliationIds: Set<number>;

  private readonly enveloppeToMergeIds = ENVELOPPE_TO_MERGE_LIST.map(e => e.code);

  constructor(
    protected draftOperationsService: DraftOperationsService,
    private dialogService: OuiDialogService,
    private optionsService: OptionsService,
    private snackbarService: OuiSnackbarService,
    private produitService: ProduitService
  ) {
    this.addButtonLabel =
      this.draftOperationsService.operationGroupData.opertionType === OperationType.ActeDeGestion
        ? 'Ajouter un acte de gestion'
        : 'Ajouter un produit';
  }

  ngOnInit(): void {
    this.enveloppeControl.valueChanges.subscribe(enveloppeId => {
      if (!enveloppeId || enveloppeId === this.selectedEnveloppeId) {
        return;
      }
      if (!this.selectedEnveloppeId) {
        this.selectedEnveloppeId = enveloppeId;
        this.updateSelectableProducts();
        return;
      }

      //if we have already a product in the operation group, then we display the popup
      if (this.draftOperationsService.operationGroupData.operations.some(op => op.produit)) {
        const dialogRef = this.dialogService.openDialog(
          EnveloppeChangeConfirmationDialogComponent,
          undefined,
          'auto',
          '480px'
        );
        const dialogSub = dialogRef.componentInstance.confirm.subscribe(async () => {
          try {
            await this.draftOperationsService.resetDraftOperationGroup();
            // this.draftOperationsService.operationGroupData.operations.forEach(async op => {
            //   await this.draftOperationsService.deleteDraftOperation(op);
            // });
            // this.addOperationClicked.emit();
            this.selectedEnveloppeId = enveloppeId;
            this.updateSelectableProducts();
            dialogRef.close(true);
          } catch (err) {
            this.snackbarService.open("Une erreur est survenue lors du changement d'enveloppe", 'error');
          }
        });

        dialogRef.afterClosed().subscribe((res: boolean | undefined) => {
          // Pas de confirmation => on remet d'ancienne valeur de l'enveloppe
          if (!res) {
            this.enveloppeControl.setValue(this.selectedEnveloppeId!);
          }
          dialogSub.unsubscribe();
        });

        return;
      }

      this.selectedEnveloppeId = enveloppeId;
      this.updateSelectableProducts();
    });
  }

  ngAfterViewInit(): void {
    this.initEnveloppes();
  }

  private async initEnveloppes() {
    this.allProduitsActifsHabilites = await this.produitService.getProduitsActifsHabilites();
    let enveloppes: BoxEnvelope[] = [];
    let idsEnveloppes = new Set();

    // Récupérer la liste des enveloppes disponibles
    this.allProduitsActifsHabilites.forEach(produit => {
      if (produit.enveloppeNavigation) {
        // Here we add a little trick to merge FIP and FCPI enveloppe as requested by business
        let isMerged = false;
        if (this.enveloppeToMergeIds.includes(produit.enveloppeNavigation.code)) {
          isMerged = true;
        }
        let enveloppe: BoxEnvelope = isMerged ? FAKE_ENVELOPPE_MERGED : (produit.enveloppeNavigation! as BoxEnvelope);
        if (!idsEnveloppes.has(enveloppe.code)) {
          idsEnveloppes.add(enveloppe.code);
          enveloppes.push(enveloppe);
        }
      }
    });

    this.enveloppeAutocompleteOptions = this.optionsService.getEnveloppeAutocompleteOptions(enveloppes);

    const currentEnveloppeCode =
      this.draftOperationsService.operationGroupData.operations[0]?.produit?.enveloppeNavigation?.code;
    if (currentEnveloppeCode) {
      setTimeout(() => {
        // Here we also check if the current enveloppe is in the list of enveloppe to merge
        const currentValue = this.enveloppeToMergeIds.includes(currentEnveloppeCode)
          ? FAKE_ENVELOPPE_MERGED.code
          : currentEnveloppeCode;
        this.enveloppeControl.setValue(currentValue);
      });
    }
  }

  private updateSelectableProducts() {
    this.selectableProduits = this.allProduitsActifsHabilites.filter(p => {
      return (
        p.enveloppeNavigation?.code === this.selectedEnveloppeId ||
        (this.selectedEnveloppeId === FAKE_ENVELOPPE_MERGED.code &&
          this.enveloppeToMergeIds.includes(p.enveloppeNavigation?.code))
      );
    });
    this.selectableProduitHabiliationIds = new Set(this.selectableProduits.map(p => p.habilitationNavigation?.id));
  }
  updateTransactionPersonelleStatut(value: TransactionPersonnelleEnum | undefined) {
    this.draftOperationsService.setTransactionPersonnelleStatutValue(value);
  }
  onOperationConfigChanged(config: OperationConfig | null, index: number) {
    if (config?.id && config.id != this.draftOperationsService.operationGroupData.operations[index].operationConfigId) {
      this.draftOperationsService.operationGroupData.operations[index].donneesSpecifiques = '{}';
      this.draftOperationsService.operationGroupData.operations[index].specificDataValues = {
        isFormValid: false,
        data: {},
      };
    }
    this.draftOperationsService.operationGroupData.operations[index].operationConfig = config;
    this.draftOperationsService.operationGroupData.operations[index].operationConfigId = config?.id;
  }

  onSpecificDataChange(newData: OperationSpecificDataValue, index: number) {
    const operation = this.draftOperationsService.operationGroupData.operations[index];
    operation.specificDataValues = newData;
    operation.donneesSpecifiques = JSON.stringify(newData.data);
    // Mapping montant qui n'est plus une donnée spécifique
    operation.montant = newData.data.montant;
  }

  onProductChanged(produit: Produit | null, index: number) {
    this.draftOperationsService.operationGroupData.operations[index].produit = produit;
    this.draftOperationsService.operationGroupData.operations[index].produitId = produit?.id;
  }

  deleteOperation(operation: Operation): void {
    this.dialogService.openDialog(DeleteProduitDialogComponent, { operation: operation }, 'auto', '448px');
  }

  addNewOperationInGroup() {
    return this.draftOperationsService.addNewOperationInGroup();
  }
}
