<div class="flex-vertical gap-16 width-100pc">
  <div class="body-2">
    {{ totalCount }} <span *ngIf="totalCount > 1">contrats</span><span *ngIf="totalCount < 2">contrat</span>
  </div>
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Investisseur">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Investisseur</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.investisseurCommande[0]?.investisseur?.investisseurEntite.displayName ?? '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Co-investisseur">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Co-investisseur</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.investisseurCommande[1]?.investisseur?.investisseurEntite.displayName ?? '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Contrat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contrat</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-vertical">
              <div class="body-2 color-on-surface-emphasis">N° {{ element?.numeroContrat ?? 'non renseigné' }}</div>
              <div class="caption color-on-surface-medium">
                {{ element?.dateActe ? 'le ' + (element.dateActe | date : 'dd-MM-yyyy') : 'date non renseignée' }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Enveloppe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Enveloppe</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.produit?.enveloppeNavigation?.libelle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Produit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Produit</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-vertical">
              <div class="body-2 color-on-surface-emphasis">
                {{ element?.produit?.nomTechniqueProduitBox ?? element?.produit?.nom ?? 'non renseigné' }}
              </div>
              <div class="caption color-on-surface-medium">
                {{ element?.produit?.partenaire?.nom | uppercase }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <oui-button type="icon" color="secondary"><oui-icon>chevron_right</oui-icon></oui-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container>
          <tr
            [class.interactive]="true"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>
