import { Component, EventEmitter } from '@angular/core';
import {
  CreateBatchFichierOperationForGroupInput,
  CreateFichierOperationInput,
  FichierOperation,
  Operation,
} from '@lib/models/generated/graphql';
import { DraftOperationsFilesService } from 'src/service/draft-operation/draft-operation-files.service';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { SelectedFileConfig } from '../souscription-file-items/souscription-file-items.component';

@Component({
  selector: 'app-operation-group-files-configuration',
  templateUrl: './operation-group-files-configuration.component.html',
  styleUrls: ['./operation-group-files-configuration.component.scss'],
})
export class OperationGroupFilesConfigurationComponent {
  onAddedFichierOperation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected draftOperationsService: DraftOperationsService,
    protected draftOperationsFilesService: DraftOperationsFilesService
  ) {}

  async addFichierOperation(selectedFilesConfig: SelectedFileConfig[]) {
    if (
      Array.isArray(selectedFilesConfig) &&
      selectedFilesConfig.length > 0 &&
      this.draftOperationsService?.operationGroupData?.id
    ) {
      const operationGroupId = this.draftOperationsService.operationGroupData?.id;
      const batch: Array<CreateFichierOperationInput> = [];
      this.draftOperationsService.operationGroupData?.operations.forEach(op => {
        selectedFilesConfig.forEach(sfo => {
          if (
           this.isInOperationConfig(op, sfo) || this.isOutOfConfigAddition(sfo)
          ) {
            batch.push({
              fileId: sfo.fileId,
              operationId: op.id,
              natureDocumentRequisId: sfo.natureDocumentRequisId,
              commentaire: sfo.comment,
              estHorsConfig: this.isOutOfConfigAddition(sfo),
              estCoInvestisseur: sfo.investisseurId === this.draftOperationsService.coInvestisseur?.id,
            });
          }
        });
      });

      const batchconnaissanceClientFilesToCreate: CreateBatchFichierOperationForGroupInput = {
        groupId: operationGroupId,
        batchConfig: batch,
      };

      if (batchconnaissanceClientFilesToCreate && batchconnaissanceClientFilesToCreate.batchConfig.length > 0) {
        await this.draftOperationsService.addFichierOperationToCurrentGroup(batchconnaissanceClientFilesToCreate);
      }
    }
  }

  async removeFichierOperationbyId(fichierOperation: FichierOperation) {
    await this.draftOperationsService.removeFichierOperationToCurrentGroup(fichierOperation);
  }

  setIsMissingDoc(value: { isMissingDoc: boolean; operationId: number }) {
    this.draftOperationsFilesService.setMissingFile(value.operationId, value.isMissingDoc);
  }

  private isInOperationConfig(operation: Operation, sfo: SelectedFileConfig) {
    if (!sfo.natureDocumentRequisId) {
      return false;
    }
    return operation.operationConfig?.filteredNatureDocumentRequis
      ?.map(ndr => ndr.id)
      .includes(sfo.natureDocumentRequisId);
  }

  private isOutOfConfigAddition(sfo: SelectedFileConfig): boolean {
    return sfo.isOutOfConfig??false;
  }
}
