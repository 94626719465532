import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiButtonModule } from 'omnium-ui/button';

@Component({
  selector: 'app-enveloppe-change-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, OuiDialogModule, OuiIconModule, OuiButtonModule],
  templateUrl: './enveloppe-change-confirmation-dialog.component.html',
  styleUrls: ['./enveloppe-change-confirmation-dialog.component.scss'],
})
export class EnveloppeChangeConfirmationDialogComponent {
  constructor() {}

  @Output() confirm = new EventEmitter();

  confirmChange() {
    this.confirm.emit();
  }
}
