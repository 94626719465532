import { gql } from 'apollo-angular';
import { createActDeGestionFragment } from '@lib/models/graphqlFragments';

export const createActeDeGestionMutation = gql`
  mutation createActeDeGestion(
    $investisseurId: Int!
    $contratId: Long!
    $produitId: Int!
    $operationGroupId: Int
    $coInvestisseurId: Long
  ) {
    createActeDeGestion(
      investisseurId: $investisseurId
      contratId: $contratId
      produitId: $produitId
      operationGroupId: $operationGroupId
      coInvestisseurId: $coInvestisseurId
    ) {
      ...createActeDeGestionDetails
    }
  }
  ${createActDeGestionFragment}
`;
