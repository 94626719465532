import { Component } from '@angular/core';
import { AbstractSpecificFieldComponent } from '../abstract-specific-field.component';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { OptionsService } from '@lib/services/options.service';

@Component({
  selector: 'app-objet-modification',
  templateUrl: './objet-modification.component.html',
  styleUrls: ['./objet-modification.component.scss'],
})
export class ObjetModificationComponent extends AbstractSpecificFieldComponent<string> {
  override getDataName(): string {
    return 'objetModification';
  }
  options: OuiSelectOption<string>[];

  constructor(private optionsService: OptionsService) {
    super();
    this.options = this.optionsService.getObjetModificationOptions();
  }

  override wrapData(value: string): string {
    return value;
  }
}
