import { Component, SimpleChanges } from '@angular/core';
import { AbstractSpecificDataComponent } from '../../abstract-specific-data.component';
import { AbstractSpecificFieldComponent } from '../abstract-specific-field.component';
import { FormControl } from '@angular/forms';

const VP_TRUE = 'Oui';
const VP_FALSE = 'Non';
@Component({
  selector: 'app-versements-programmes',
  templateUrl: './versements-programmes.component.html',
  styleUrls: ['./versements-programmes.component.scss'],
})

/*
 * this class extends the AbstractSpecificFieldComponent
 *  and overrides some methods because it's a toogle field that needs a control with a boolean value,
 *  but the component needs the value to be a string : 'oui' or 'non'
 *
 */
export class VersementsProgrammesComponent extends AbstractSpecificFieldComponent<string> {
  vpsControl = new FormControl<boolean>(false);
  override getDataName(): string {
    return 'versementsProgrammes';
  }

  override wrapData(value: string): string {
    return value;
  }

  override isFormValid(): boolean {
    return this.vpsControl.valid;
  }

  wrapValue(value: boolean): string {
    return value ? VP_TRUE : VP_FALSE;
  }
  override ngOnInit(): void {
    this.vpsControl.valueChanges.subscribe(value => {
      if (value != null) {
        this.metadata[this.getDataName()] = this.wrapValue(value);
        this.onDataChange({
          dataSource: this.getDataName(),
          data: { isFormValid: this.isFormValid(), data: this.metadata },
        });
      }
    });
  }

  override ngOnChanges(changes: SimpleChanges): void {
    var data = changes['initialData']?.currentValue;
    if (data) {
      const dataValueString = data.data[this.getDataName()];
      if (dataValueString) {
        var value: string = this.wrapData(dataValueString);
        if (value) {
          const booleanValue = value === VP_TRUE;
          this.vpsControl.setValue(booleanValue);
        }
      }
    }
  }
}
