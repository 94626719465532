<div class="flex-horizontal flex-center width-100pc rounded">
  <div class="flex-vertical width-100pc background-error-pastel rounded padding-16 border-left-error">
    <div class="flex-horizontal flex-center">
      <oui-icon color="error">report_problem</oui-icon>
      <div class="subtitle-2 color-on-surface-emphasis padding-8">Note de Stellium Placement</div>
    </div>
    <div class="body-2 color-on-surface-medium">
      Ce type d'operation sur ce produit n'est actuellement pas disponible. Veuillez contacter le support.
    </div>
  </div>
</div>
