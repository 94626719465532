<div oui-dialog-title>
    Demande de création de personne morale
    <oui-button type="icon" oui-dialog-close>
        <oui-icon>close</oui-icon>
    </oui-button>
</div>
<oui-dialog-content>
    <div class="flex-vertical gap-24">
        <div class="body-1">Remplissez le formulaire ci-dessous pour demander la création d’une nouvelle personne morale
            dans votre portefeuille.</div>
        <oui-textfield label="Raison Sociale" placeholder="Raison Sociale"
            [control]="raisonSocialeControl"></oui-textfield>

        <oui-select label="Forme Sociale" [options]="formeJuridiqueOptions"
            [control]="formeJuridiqueControl"></oui-select>

        <app-search-investisseur-autocomplete [isRequired]="true" label="Personne Physique"
            (onInvestisseurIdSelected)="onInvestisseurIdSelected($event)" [isContactStatutExcluded]="false"
            [typePersonnes]="[investisseurType.PersonnePhysique]"></app-search-investisseur-autocomplete>
    </div>
</oui-dialog-content>
<oui-dialog-actions>
    <oui-button type="text" oui-dialog-close> Annuler </oui-button>
    <oui-button type="contained" (click)="onSend()"
        [disabled]="!(!!physiqueInvestisseurId&&raisonSocialeControl.valid&&formeJuridiqueControl.valid)"> Envoyer
    </oui-button>
</oui-dialog-actions>