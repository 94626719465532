import { createSouscriptionFragment } from '@lib/models/graphqlFragments';
import { gql } from 'apollo-angular';

export const resetDraftOperationGroupMutation = gql`
    mutation resetDraftOperationGroup($groupId: Int!) {
        resetDraftOperationGroup(groupId: $groupId)
        {
            operations {
                ...createSouscriptionDetails
            }
        }
    }  
    ${createSouscriptionFragment}
`;