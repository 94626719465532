import { createFichierOperationFragment } from '@lib/models/graphqlFragments';
import { gql } from 'apollo-angular';

export const createBatchFichierOperations = gql`
  mutation createBatchFichierOperation($batchCreateFichierOperationInput: CreateBatchFichierOperationInput!) {
    createBatchFichierOperation(input: $batchCreateFichierOperationInput) {
      ...createFichierOperation
    }
  }
  ${createFichierOperationFragment}
`;
