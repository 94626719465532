import { Injectable } from '@angular/core';
import {
  CreateBatchFichierOperationForGroupInput,
  CreateFichierOperationInput,
  FichierOperation,
  FileMetadata,
  Investisseur,
  NatureDocumentRequi,
} from '@lib/models/generated/graphql';
import {
  CONNAISSANCE_CLIENT_CATEGORIE_ID,
  CONSEIL_CATEGORIE_ID,
  JPEG_EXTENSIONS,
  OPERATION_CATEGORIE_ID,
  PDF_EXTENSIONS,
  PNG_EXTENSIONS,
} from '@lib/models/graphqlData';
import { DEFAULT_BOX_DOCUMENT_TYPE, MissingFichierOperation } from '@lib/models/types';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import { createBatchFichierOperations } from 'src/service/draft-operation/mutations/create-batch-fichier-operation.mutation';
import {
  CategoryFilesHandler,
  CoInvestisseurFilesHandler,
  InvestisseurFilesHandler,
} from './models/connaissance-client-files-handling.model';
import { OperationGroupData } from './models/operation-group-data.model';
import { removeFichierOperationMutation } from './mutations/remove-ficher-operation.mutation';
import { tryCreateBatchDistinctFilesinOperationForGroupMutation } from './mutations/try-create-batch-distinct-files-in-operation-for-group';
import { updateFichierOperationCommentMutation } from './mutations/update-fichier-operation-comment.mutation';

const DEFAULT_ID_FOR_MISSING_DOC = -1;

@Injectable({
  providedIn: 'root',
})
export class DraftOperationsFilesService {
  private _isTryingAutoImport: boolean = false;
  public get isTryingAutoImport(): boolean {
    return this._isTryingAutoImport;
  }
  private set isTryingAutoImport(value: boolean) {
    this._isTryingAutoImport = value;
  }

  investisseurCategoryFiles: InvestisseurFilesHandler = {
    connaissanceClientFiles: {
      missingFichierOperations: [],
      settedFichierOperations: [],
      distinctByNatureDocumentRequisFiles: [],
      missingAndSettedFiles: [],
    },
    conseilFiles: {
      missingFichierOperations: [],
      settedFichierOperations: [],
      distinctByNatureDocumentRequisFiles: [],
      missingAndSettedFiles: [],
    },
    missingAndSettedFiles: [],
    createdFichierOperations: [],
  };
  coInvestisseurCategoryFiles: CoInvestisseurFilesHandler = {
    connaissanceClientFiles: {
      missingFichierOperations: [],
      settedFichierOperations: [],
      distinctByNatureDocumentRequisFiles: [],
      missingAndSettedFiles: [],
    },
    missingAndSettedFiles: [],
    createdFichierOperations: [],
  };

  groupOtherMissingDocs = new Map<number, boolean>();

  constructor(private queryManager: QueryManagerService) {}

  public reset() {
    this.investisseurCategoryFiles = {
      connaissanceClientFiles: {
        missingFichierOperations: [],
        settedFichierOperations: [],
        distinctByNatureDocumentRequisFiles: [],
        missingAndSettedFiles: [],
      },
      conseilFiles: {
        missingFichierOperations: [],
        settedFichierOperations: [],
        distinctByNatureDocumentRequisFiles: [],
        missingAndSettedFiles: [],
      },
      missingAndSettedFiles: [],
      createdFichierOperations: [],
    };

    this.coInvestisseurCategoryFiles = {
      connaissanceClientFiles: {
        missingFichierOperations: [],
        settedFichierOperations: [],
        distinctByNatureDocumentRequisFiles: [],
        missingAndSettedFiles: [],
      },
      missingAndSettedFiles: [],
      createdFichierOperations: [],
    };
    this.groupOtherMissingDocs = new Map<number, boolean>();
  }

  /**
   * Assigns files to an operation group for the specified investisseur and co-investisseur.
   * Updates the files handlers, imports the connaissance client and conseil documents automatically, and imports the operation documents if not updating the co-investisseur only.
   * If there is a co-investisseur, only imports the connaissance client and conseil files.
   *
   * @param {Investisseur | undefined} investisseur - The investisseur for which to assign files.
   * @param {Investisseur | undefined} coInvestisseur - The co-investisseur for which to assign files.
   * @param {OperationGroupData} operationGroupData - The operation group data.
   * @param {boolean} isCoInvestisseur - Indicates if the update is for the co-investisseur.
   * @return {Promise<void>} A promise that resolves when the files are assigned.
   */
  public async assignFilesToOperationInGroup(
    investisseur: Investisseur | undefined,
    coInvestisseur: Investisseur | undefined,
    operationGroupData: OperationGroupData,
    isCoInvestisseur: boolean
  ): Promise<void> {
    this.updateFilesHandlers(investisseur, coInvestisseur, operationGroupData);
    if (investisseur) {
      this.investisseurCategoryFiles = (await this.importCategoryDocAutomaticallyToGroup(
        operationGroupData,
        investisseur,
        coInvestisseur
      )) as InvestisseurFilesHandler;

      // here we check if we need to import the operation files for Investisseur or Co-Investisseur
      const investisseurForImport = isCoInvestisseur && coInvestisseur ? coInvestisseur : investisseur;
      await this.importOperationDocAutomatically(operationGroupData, investisseurForImport, isCoInvestisseur);

      if (coInvestisseur) {
        // in case of co-investisseur, we only auto import connaissance client and conseil files, not operation files
        this.coInvestisseurCategoryFiles = await this.importCategoryDocAutomaticallyToGroup(
          operationGroupData,
          investisseur,
          coInvestisseur,
          true
        );
      }
    }
  }

  /**
   * Provide files to create, group to add filte to and information about investisseur and co-investisseur.
   * Allocate files to investisseur and co-investisseur based on file metadata user id
   *
   * @param createInputConfig
   * @param operationGroupData
   * @param investisseur
   * @param coInvestisseur
   */
  public async addFichierOperationToGroup(
    createInputConfig: CreateBatchFichierOperationForGroupInput,
    operationGroupData: OperationGroupData,
    investisseur: Investisseur | undefined,
    coInvestisseur: Investisseur | undefined
  ) {
    await this.addFichierOperationToInvestisseur(createInputConfig);
    this.updateFilesHandlers(investisseur, coInvestisseur, operationGroupData);
  }

  public async updateFichierOperationCommentaire(
    fichierOperationId: number,
    commentaire: string,
    operationGroupData: OperationGroupData
  ) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ updateFichierOperationCommentaire: { id: number; commentaire: string } }>({
        mutation: updateFichierOperationCommentMutation,
        variables: {
          fichierOperationId: fichierOperationId,
          commentaire: commentaire,
        },
      })
    );
    if (result && result.errors == null) {
      // manually update comment in displayed info
      operationGroupData.operations.forEach(operation => {
        operation.fichierOperations.forEach(file => {
          if (file.id === fichierOperationId) {
            file.commentaire = commentaire;
          }
        });
      });

      this.investisseurCategoryFiles.createdFichierOperations.forEach(file => {
        if (file.id === fichierOperationId) {
          file.commentaire = commentaire;
        }
      });
      this.coInvestisseurCategoryFiles.createdFichierOperations.forEach(file => {
        if (file.id === fichierOperationId) {
          file.commentaire = commentaire;
        }
      });
    }
  }

  public async removeFichierOperationToCurrentGroup(
    fichierOperation: FichierOperation,
    operationGroupData: OperationGroupData,
    investisseur: Investisseur | undefined,
    coInvestisseur: Investisseur | undefined
  ) {
    // Fisrt check if user want to delete a connaissance client/conseil file or an other operation file. If it is a connaissance client/conseil file
    // it is defined at group level so we should also remove fichier operation of other operations (based on investisseur and natureDocumentRequisId)
    const coInvestisseurIndex = this.coInvestisseurCategoryFiles.missingAndSettedFiles.findIndex(
      file => file.id === fichierOperation.id
    );
    const investisseurIndex = this.investisseurCategoryFiles.missingAndSettedFiles.findIndex(
      file => file.id === fichierOperation.id
    );

    if (coInvestisseurIndex >= 0 || investisseurIndex >= 0) {
      // should remove for each operation
      let isCoInvest = coInvestisseurIndex >= 0;

      // coInvestisseur files
      if (isCoInvest) {
        const createdfichierOperationList = this.coInvestisseurCategoryFiles.createdFichierOperations.filter(
          file =>
            file.natureDocumentRequisId === fichierOperation.natureDocumentRequisId &&
            file.fileId === fichierOperation.fileId
        );
        for (const fo of createdfichierOperationList) {
          await this.removeFichierOperationToOneOperation(fo, operationGroupData, investisseur, coInvestisseur);
        }
        const allSettedFiles = this.coInvestisseurCategoryFiles.connaissanceClientFiles.settedFichierOperations;
        const settedfichierOperationList = allSettedFiles.filter(
          file => file.natureDocumentRequisId === fichierOperation.natureDocumentRequisId
        );
        for (const fo of settedfichierOperationList) {
          await this.removeFichierOperationToOneOperation(fo, operationGroupData, investisseur, coInvestisseur);
        }
      } else {
        //investisseur files
        const createdfichierOperationList = this.investisseurCategoryFiles.createdFichierOperations.filter(
          file => file.natureDocumentRequisId === fichierOperation.natureDocumentRequisId
        );
        for (const fo of createdfichierOperationList) {
          await this.removeFichierOperationToOneOperation(fo, operationGroupData, investisseur, coInvestisseur);
        }
        const allSettedFiles: FichierOperation[] = [
          ...this.investisseurCategoryFiles.conseilFiles.settedFichierOperations,
          ...this.investisseurCategoryFiles.connaissanceClientFiles.settedFichierOperations,
        ];
        const settedfichierOperationList = allSettedFiles.filter(
          file => file.natureDocumentRequisId === fichierOperation.natureDocumentRequisId
        );
        for (const fo of settedfichierOperationList) {
          await this.removeFichierOperationToOneOperation(fo, operationGroupData, investisseur, coInvestisseur);
        }
      }
    } else {
      await this.removeFichierOperationToOneOperation(
        fichierOperation,
        operationGroupData,
        investisseur,
        coInvestisseur
      );
    }
  }

  public isGroupMissingFilesFilled(hasCoInvestisseur: boolean): boolean {
    const allOtherRequireFilesdAreFilled = Array.from(this.groupOtherMissingDocs.values()).every(
      value => value === false
    );
    const allConnaissanceClientFilesAreFilled = hasCoInvestisseur
      ? this.investisseurCategoryFiles.connaissanceClientFiles.missingFichierOperations.length === 0 &&
        this.coInvestisseurCategoryFiles.connaissanceClientFiles.missingFichierOperations.length === 0
      : this.investisseurCategoryFiles.connaissanceClientFiles.missingFichierOperations.length === 0;

    const allConseilFilesAreFilled = this.investisseurCategoryFiles.conseilFiles.missingFichierOperations.length === 0;

    return allOtherRequireFilesdAreFilled && allConnaissanceClientFilesAreFilled && allConseilFilesAreFilled;
  }

  public setMissingFile(operationId: number, isMissing: boolean) {
    this.groupOtherMissingDocs.set(operationId, isMissing);
  }

  private updateFilesHandlers(
    investisseur: Investisseur | undefined,
    coInvestisseur: Investisseur | undefined,
    operationGroupData: OperationGroupData
  ) {
    if (investisseur) {
      this.investisseurCategoryFiles = this.refreshInvestisseurFilesHandler(
        investisseur,
        operationGroupData,
        this.investisseurCategoryFiles
      ) as InvestisseurFilesHandler;
    }
    if (coInvestisseur) {
      this.coInvestisseurCategoryFiles = this.refreshInvestisseurFilesHandler(
        coInvestisseur,
        operationGroupData,
        this.coInvestisseurCategoryFiles,
        true
      );
    }
  }

  refreshInvestisseurFilesHandler(
    investisseur: Investisseur,
    operationGroupData: OperationGroupData,
    filesHandler: InvestisseurFilesHandler | CoInvestisseurFilesHandler,
    forCoInvestisseur: boolean = false
  ) {
    filesHandler.connaissanceClientFiles = this.getCategoryFilesHandler(
      operationGroupData,
      CONNAISSANCE_CLIENT_CATEGORIE_ID,
      filesHandler.createdFichierOperations,
      forCoInvestisseur
    );
    filesHandler.missingAndSettedFiles = [
      ...filesHandler.connaissanceClientFiles.missingFichierOperations,
      ...filesHandler.connaissanceClientFiles.settedFichierOperations,
    ];
    if (this.isInvestisseurFilesHandler(filesHandler) && !forCoInvestisseur) {
      filesHandler.conseilFiles = this.getCategoryFilesHandler(
        operationGroupData,
        CONSEIL_CATEGORIE_ID,
        filesHandler.createdFichierOperations,
        forCoInvestisseur
      );
      filesHandler.missingAndSettedFiles = [
        ...filesHandler.missingAndSettedFiles,
        ...filesHandler.conseilFiles.missingFichierOperations,
        ...filesHandler.conseilFiles.settedFichierOperations,
      ];
    }

    return filesHandler;
  }
  /**
   * Checks if the given `filesHandler` is an instance of `InvestisseurFilesHandler`.
   *
   * @param {CoInvestisseurFilesHandler | InvestisseurFilesHandler} filesHandler - The files handler to check.
   * @return {filesHandler is InvestisseurFilesHandler} Returns `true` if the `filesHandler` is an instance of `InvestisseurFilesHandler`, otherwise `false`.
   */
  private isInvestisseurFilesHandler(
    filesHandler: CoInvestisseurFilesHandler | InvestisseurFilesHandler
  ): filesHandler is InvestisseurFilesHandler {
    return (filesHandler as InvestisseurFilesHandler).conseilFiles !== undefined;
  }

  private isMissingConseilFileToAutoImport(file: MissingFichierOperation): boolean {
    if (file.fileId) {
      return true;
    }
    return file.natureDocumentRequis.withAutoImport;
  }

  /**
   * Import required connaissance client and conseil documents automatically to group.
   */
  private async importCategoryDocAutomaticallyToGroup(
    operationGroupData: OperationGroupData,
    investisseur: Investisseur,
    coInvestisseur: Investisseur | undefined,
    isCoInvestisseur: boolean = false
  ): Promise<InvestisseurFilesHandler | CoInvestisseurFilesHandler> {
    this.isTryingAutoImport = true;
    let inputConfigs: CreateFichierOperationInput[] = [];
    // define investisseur files
    const existingInvestisseurFiles = isCoInvestisseur
      ? this.filterActiveAndReadableFiles(coInvestisseur?.filesMetadata)
      : this.filterActiveAndReadableFiles(investisseur?.filesMetadata);

    // define missing files: connaissance client and conseil
    const missingConnaissanceClientFiles = isCoInvestisseur
      ? this.coInvestisseurCategoryFiles.connaissanceClientFiles.missingFichierOperations.filter(
          file => file.natureDocumentRequis.withAutoImport
        )
      : this.investisseurCategoryFiles.connaissanceClientFiles.missingFichierOperations.filter(
          file => file.natureDocumentRequis.withAutoImport
        );

    const missingConseilFiles = isCoInvestisseur
      ? []
      : this.investisseurCategoryFiles.conseilFiles.missingFichierOperations.filter(file =>
          this.isMissingConseilFileToAutoImport(file)
        );
    const filesToImport = [...missingConnaissanceClientFiles, ...missingConseilFiles];

    //for each doc to import
    filesToImport.forEach(fileInfo => {
      if (fileInfo.fileId) {
        // case of existing out of config doc
        inputConfigs.push({
          fileId: fileInfo.fileId,
          natureDocumentRequisId: fileInfo.natureDocumentRequisId,
          operationId: fileInfo.operationId,
          commentaire: fileInfo.commentaire,
          estHorsConfig: fileInfo.estHorsConfiguration,
          estCoInvestisseur: isCoInvestisseur,
        });
      } else {
        // case of doc to import from Arianne
        // check if there is a matching doc in investisseur files
        let matchingFiles = existingInvestisseurFiles?.filter(
          fileMetadata =>
            fileMetadata.typeDocumentCode ===
            fileInfo?.natureDocumentRequis?.natureDocument?.boxDocumentType?.gedParentTypeCode
        );
        //if there is at least one matching file
        if (matchingFiles && matchingFiles.length > 0 && operationGroupData?.id) {
          //select the more recent one handling undefined
          const getTime = (date?: string) => {
            let dateObject = date ? new Date(date) : null;
            return dateObject != null ? dateObject.getTime() : 0;
          };
          let file = matchingFiles.sort((file1, file2) => {
            return getTime(file2.dateDeDerniereModification) - getTime(file1.dateDeDerniereModification);
          })[0];

          // save ConnaissanceClientFileInput
          if (file.fileId) {
            let connaissanceClientFileInput: CreateFichierOperationInput = {
              fileId: file.fileId,
              natureDocumentRequisId: fileInfo?.natureDocumentRequisId,
              operationId: fileInfo?.operationId,
              estHorsConfig: fileInfo?.estHorsConfiguration,
              estCoInvestisseur: isCoInvestisseur,
            };
            inputConfigs.push(connaissanceClientFileInput);
          }
        }
      }
    });

    if (inputConfigs.length > 0 && operationGroupData?.id) {
      const operationGroupId = operationGroupData.id;
      const batchFilesToCreate: CreateBatchFichierOperationForGroupInput = {
        groupId: operationGroupId,
        batchConfig: inputConfigs,
      };
      await this.addFichierOperationToInvestisseur(batchFilesToCreate);
    }

    this.isTryingAutoImport = false;

    if (isCoInvestisseur && coInvestisseur) {
      return this.refreshInvestisseurFilesHandler(
        coInvestisseur,
        operationGroupData,
        this.coInvestisseurCategoryFiles,
        true
      );
    }
    const investisseurFilesHandler = this.refreshInvestisseurFilesHandler(
      investisseur,
      operationGroupData,
      this.investisseurCategoryFiles
    );
    return investisseurFilesHandler;
  }

  private isAutoImportOperationDoc(docRequis: NatureDocumentRequi, isCoInv: boolean = false): boolean {
    const isAutoImportable =
      docRequis?.withAutoImport &&
      docRequis?.natureDocument?.categorieId === OPERATION_CATEGORIE_ID &&
      docRequis?.natureDocument?.boxDocumentType?.id !== DEFAULT_BOX_DOCUMENT_TYPE.id;

    return isCoInv ? isAutoImportable && docRequis?.withAutoImportCoInv : isAutoImportable;
  }

  /**
   * Import required operation documents (all documents that are not connaissance client/conseil==> operation category files) automatically to group.
   */
  private async importOperationDocAutomatically(
    operationGroupData: OperationGroupData,
    investisseur: Investisseur | undefined,
    isForCoInv: boolean = false
  ) {
    this.isTryingAutoImport = true;

    const existingInvestisseurFiles = this.filterActiveAndReadableFiles(investisseur?.filesMetadata);
    const filesToImport: MissingFichierOperation[] = [];
    const createConfig: CreateFichierOperationInput[] = [];

    operationGroupData.operations.forEach(operation => {
      operation.operationConfig?.filteredNatureDocumentRequis?.forEach((docRequis: NatureDocumentRequi) => {
        if (this.isAutoImportOperationDoc(docRequis, isForCoInv)) {
          const alreadyExistingFichierOperation = operation.fichierOperations?.find(
            file => file.natureDocumentRequisId === docRequis.id && file.metadata?.investisseurId === investisseur?.id
          );
          // if we don't already have this doc, add it to files to import
          if (!alreadyExistingFichierOperation) {
            filesToImport.push({
              id: DEFAULT_ID_FOR_MISSING_DOC,
              natureDocumentRequisId: docRequis.id,
              natureDocumentRequis: docRequis,
              operationId: operation.id,
              estHorsConfiguration: false,
              estCoInvestisseur: isForCoInv,
            });
          }
        }
      });
    });
    //for each doc to import
    filesToImport.forEach(fileInfo => {
      let matchingFiles = [];
      if (
        fileInfo?.natureDocumentRequis?.natureDocument?.boxDocumentType?.isParentGedTypeHaveMultipleBoxDocumentTypes
      ) {
        // multiple box type for the same GED type ==> should import with strict matching (a doc with same boxDocumentType)
        matchingFiles = existingInvestisseurFiles?.filter(
          fileMetadata =>
            fileMetadata.denomination === fileInfo?.natureDocumentRequis?.natureDocument?.boxDocumentType.key
        );
      } else {
        // single box type for the same GED type ==> should import with loose matching (a doc with same GED type)
        matchingFiles = existingInvestisseurFiles?.filter(
          fileMetadata =>
            fileMetadata.typeDocumentCode ===
            fileInfo?.natureDocumentRequis?.natureDocument?.boxDocumentType.gedParentTypeCode
        );
      }

      //if there is at least one matching file
      if (matchingFiles && matchingFiles.length > 0 && operationGroupData?.id) {
        //select the more recent one handling undefined
        const getTime = (date?: string) => {
          let dateObject = date ? new Date(date) : null;
          return dateObject != null ? dateObject.getTime() : 0;
        };
        let file = matchingFiles.sort((file1, file2) => {
          return getTime(file2.dateDeDerniereModification) - getTime(file1.dateDeDerniereModification);
        })[0];

        // save CreateFichierOperationInput
        if (file.fileId) {
          let createFichierOperationInput: CreateFichierOperationInput = {
            fileId: file.fileId,
            natureDocumentRequisId: fileInfo?.natureDocumentRequisId,
            operationId: fileInfo?.operationId,
            estHorsConfig: false,
            estCoInvestisseur: isForCoInv,
          };
          createConfig.push(createFichierOperationInput);
        }
      }
    });

    if (createConfig.length > 0) {
      const newFichierOperations = await firstValueFrom(
        this.queryManager.mutate<{ createBatchFichierOperation: FichierOperation[] }>({
          mutation: createBatchFichierOperations,
          variables: {
            batchCreateFichierOperationInput: { batchConfig: createConfig },
          },
        })
      );

      //if FichierOperation have been created we update operation fichier operation with them
      if (newFichierOperations.data?.createBatchFichierOperation) {
        newFichierOperations.data?.createBatchFichierOperation.forEach(fichierOperation => {
          operationGroupData.operations
            .find(operation => operation.id === fichierOperation.operationId)
            ?.fichierOperations?.push(fichierOperation);
        });
      }
    }

    this.isTryingAutoImport = false;
  }

  /**
   * Provide files to create and information about investisseur and co-investisseur. allocate files to investisseur and co-investisseur based on file metadata user id
   *
   * @param createInputConfig
   * @param investisseur
   * @param coInvestisseur
   */
  private async addFichierOperationToInvestisseur(createInputConfig: CreateBatchFichierOperationForGroupInput) {
    const newFichierOperations = await firstValueFrom(
      this.queryManager.mutate<{ tryCreateBatchDistinctFilesinOperationForGroup: FichierOperation[] }>({
        mutation: tryCreateBatchDistinctFilesinOperationForGroupMutation,
        variables: {
          input: createInputConfig,
        },
      })
    );
    const filesOperationGroup = newFichierOperations.data?.tryCreateBatchDistinctFilesinOperationForGroup;
    if (filesOperationGroup && filesOperationGroup.length > 0) {
      const coInvFiles = filesOperationGroup.filter(file => file.estCoInvestisseur);
      const invFiles = filesOperationGroup.filter(file => !file.estCoInvestisseur);

      if (coInvFiles.length > 0) {
        this.coInvestisseurCategoryFiles.createdFichierOperations.push(...coInvFiles);
      }
      this.investisseurCategoryFiles.createdFichierOperations.push(...invFiles);
    }
  }

  /**
   * Get the connaissance client files handler.
   *
   * @return {CategoryFilesHandler} an object containing required missing, setted, the sum of missing and setted and created fichierOperations as well as a common for the groupData unique natureDocumentRequisId files
   */
  private getCategoryFilesHandler(
    operationGroupData: OperationGroupData,
    categorieId: number,
    createdFiles: FichierOperation[],
    isCoInv: boolean
  ): CategoryFilesHandler {
    // list all fichierOperation from fetched operation data and fichier operation created
    const alreadyInOperationsFiles: FichierOperation[] = [];
    operationGroupData?.operations.forEach(operation => {
      operation.fichierOperations?.forEach(fichierOperation => {
        if (fichierOperation.natureDocumentRequis?.natureDocument.categorieId === categorieId) {
          alreadyInOperationsFiles.push(fichierOperation);
        }
      });
    });
    alreadyInOperationsFiles.push(...createdFiles);
    // FIRST identify all  required files for this category
    const configRequiredFiles = this.getNatureDocumentCategoryNeededFiles(operationGroupData, categorieId, isCoInv);
    // SECOND identify all  files added by the user (not in config)
    const additionalFilesNeeded = this.getUserAdditionalFilesAsMissing(
      operationGroupData.operations.map(operation => operation.id),
      alreadyInOperationsFiles,
      categorieId
    );
    const allNeededFiles = [...configRequiredFiles, ...additionalFilesNeeded];

    //identify  missing and setted files
    const filesClassification = this.classifyMissingAndRequiredCategoryFiles(
      categorieId,
      alreadyInOperationsFiles,
      allNeededFiles,
      isCoInv
    );
    const allFiles = filesClassification.allFiles;
    //  we reduce files by natureDocumentRequisId
    const distinctNatureDocumentRequisFiles = this.reduceByNatureDocumentRequisId(allFiles);
    return {
      //allfiles: missingAndSetted,
      missingFichierOperations: filesClassification.missingFichierOperations,
      settedFichierOperations: filesClassification.settedFichierOperations,
      distinctByNatureDocumentRequisFiles: distinctNatureDocumentRequisFiles,
      missingAndSettedFiles: allFiles,
    };
  }

  private getUserAdditionalFilesAsMissing(
    allOperationIds: number[],
    allOperationFiles: FichierOperation[],
    categorieId: number
  ): MissingFichierOperation[] {
    const additionnalAsMissing: MissingFichierOperation[] = [];
    // const allOperationIds = operationGroupData.operations.map(operation => operation.id);
    const additionals = allOperationFiles.filter(file => this.isOutOfConfigAddition(file));
    additionals.forEach(fichierOperation => {
      if (
        fichierOperation.natureDocumentRequisId &&
        fichierOperation.natureDocumentRequis &&
        fichierOperation.fileId &&
        fichierOperation.natureDocumentRequis?.natureDocument.categorieId === categorieId && //file is in the right category
        additionnalAsMissing.findIndex(
          // file is not already added
          addition =>
            this.isSameConcernFile(fichierOperation, addition) && addition.operationId === fichierOperation.operationId
        ) === -1
      ) {
        // if file is not already added, we add it on every operations
        allOperationIds.forEach(operationId => {
          const additionnal: MissingFichierOperation = {
            id: DEFAULT_ID_FOR_MISSING_DOC,
            natureDocumentRequisId: fichierOperation.natureDocumentRequisId!,
            natureDocumentRequis: fichierOperation.natureDocumentRequis!,
            operationId: operationId,
            fileId: fichierOperation.fileId!,
            commentaire: fichierOperation.commentaire,
            estHorsConfiguration: fichierOperation.estHorsConfiguration,
            estCoInvestisseur: fichierOperation.estCoInvestisseur,
          };
          additionnalAsMissing.push(additionnal);
        });
      }
    });
    return additionnalAsMissing;
  }

  isSameConcernFile(
    file1: FichierOperation | MissingFichierOperation,
    file2: FichierOperation | MissingFichierOperation
  ): boolean {
    return file1.fileId === file2.fileId && file1.natureDocumentRequisId === file2.natureDocumentRequisId;
  }
  private reduceByNatureDocumentRequisId(
    files: (FichierOperation | MissingFichierOperation)[]
  ): (FichierOperation | MissingFichierOperation)[] {
    const reduced = files.reduce(
      (acc: (FichierOperation | MissingFichierOperation)[], current: FichierOperation | MissingFichierOperation) => {
        const existingNatureDocumentRequis = acc.find((file: FichierOperation | MissingFichierOperation) =>
          this.isSameConcernFile(file, current)
        );
        if (!existingNatureDocumentRequis) {
          acc.push(current);
        }
        return acc;
      },
      []
    );
    return reduced;
  }
  private isOutOfConfigAddition(file: FichierOperation): boolean {
    return !!file.estHorsConfiguration;
  }
  private classifyMissingAndRequiredCategoryFiles(
    categorieId: number,
    allExistingFiles: FichierOperation[],
    neededFiles: MissingFichierOperation[],
    forCoInvestisseur: boolean = false
  ): {
    missingFichierOperations: MissingFichierOperation[];
    settedFichierOperations: FichierOperation[];
    allFiles: (FichierOperation | MissingFichierOperation)[];
  } {
    // we iterate over all existing files
    // if the files match, we remove it from neededFiles and add it to settedFiles
    // if not, we keep it in the missing needed files

    const settedFiles: FichierOperation[] = [];
    allExistingFiles.forEach(fichierOperation => {
      if (
        fichierOperation.natureDocumentRequis?.natureDocument?.categorieId === categorieId &&
        fichierOperation.estCoInvestisseur === forCoInvestisseur &&
        fichierOperation.natureDocumentRequisId
      ) {
        const index = neededFiles.findIndex(
          file =>
            file.natureDocumentRequisId === fichierOperation.natureDocumentRequisId &&
            (!file.fileId || file.fileId === fichierOperation.fileId) &&
            file.operationId === fichierOperation.operationId
        );
        if (index !== -1) {
          neededFiles.splice(index, 1);
          settedFiles.push(fichierOperation);
        }
      }
    });

    const allCategoryFiles = [...neededFiles, ...settedFiles];
    return { missingFichierOperations: neededFiles, settedFichierOperations: settedFiles, allFiles: allCategoryFiles };
  }
  private getNatureDocumentCategoryNeededFiles(
    operationGroupData: OperationGroupData,
    categorieId: number,
    forCoInvestisseur: boolean
  ): MissingFichierOperation[] {
    const neededFiles: MissingFichierOperation[] = [];
    operationGroupData?.operations?.forEach(operation => {
      operation.operationConfig?.filteredNatureDocumentRequis?.forEach(docRequis => {
        if (docRequis.natureDocument?.categorieId === categorieId) {
          neededFiles.push({
            id: DEFAULT_ID_FOR_MISSING_DOC,
            natureDocumentRequisId: docRequis.id,
            natureDocumentRequis: docRequis,
            operationId: operation.id,
            estHorsConfiguration: false,
            estCoInvestisseur: forCoInvestisseur,
          });
        }
      });
    });
    return neededFiles;
  }

  private async removeFichierOperationToOneOperation(
    fichierOperation: FichierOperation,
    operationGroupData: OperationGroupData,
    investisseur: Investisseur | undefined,
    coInvestisseur: Investisseur | undefined
  ) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ removeFichierOperationFromOperation: { id: number } }>({
        mutation: removeFichierOperationMutation,
        variables: {
          fichierOperationId: fichierOperation.id,
        },
      })
    );
    if (result && result.errors == null) {
      operationGroupData.operations.forEach(operation => {
        let foToRemoveIndex = operation.fichierOperations.findIndex(file => file.id === fichierOperation.id);
        if (foToRemoveIndex >= 0) {
          operation.fichierOperations.splice(foToRemoveIndex, 1);
        }
      });

      // want to delete a connaissance client file so should delete if for all operations
      this.coInvestisseurCategoryFiles.createdFichierOperations =
        this.coInvestisseurCategoryFiles.createdFichierOperations.filter(
          file => file.natureDocumentRequisId !== fichierOperation.natureDocumentRequisId
        );
      this.investisseurCategoryFiles.createdFichierOperations =
        this.investisseurCategoryFiles.createdFichierOperations.filter(
          file => file.natureDocumentRequisId !== fichierOperation.natureDocumentRequisId
        );

      this.updateFilesHandlers(investisseur, coInvestisseur, operationGroupData);
    }
  }

  private filterActiveAndReadableFiles(files: FileMetadata[] | undefined): FileMetadata[] {
    if (!files) {
      return [];
    }
    const READABLE = [...JPEG_EXTENSIONS, ...PDF_EXTENSIONS, ...PNG_EXTENSIONS];
    return files.filter(file => file.actif && !!file.extension && READABLE.includes(file.extension));
  }
}
