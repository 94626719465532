<span class="subtitle-1">
  {{ operation.operationType === typeOperationEnum.ActeDeGestion ? 'Acte de gestion' : 'Souscription' }}
  N°{{ index + 1 }}</span
>
<span *ngIf="showDetails" class="body-1">
  <ng-container
    *ngIf="
      operation.operationType === typeOperationEnum.ActeDeGestion && operation.operationConfig?.natureOperation?.libelle
    ">
    - {{ operation.operationConfig?.natureOperation?.libelle }}
  </ng-container>

  <ng-container
    *ngIf="
      operation.operationType === typeOperationEnum.Souscription &&
      (operation.produit?.nomTechniqueProduitBox || operation.produit?.nom)
    ">
    - {{ operation.produit?.nomTechniqueProduitBox ?? operation.produit?.nom }}
  </ng-container>

  <ng-container *ngIf="operation.montant">
    -
    {{ operation.montant | currency: 'EUR' : 'symbol' : '1.0-2' }}
  </ng-container>
</span>
