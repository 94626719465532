import { Injectable } from '@angular/core';
import { CreationPersonneMoraleFilterInput } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
const CHECK = gql`
  query allCreationPersonneMoralesPaginated($filter: CreationPersonneMoraleFilterInput) {
    allCreationPersonneMoralesPaginated(skip: 0, take: 0, where: $filter, order: []) {
      totalCount
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class CreationPersonneMoraleCountService {
  constructor(private queryManager: QueryManagerService) {}

  async getCreationPersonneMoraleCount(filter: CreationPersonneMoraleFilterInput) : Promise<number> {
    const requestResult = await firstValueFrom(
      this.queryManager.query<{ allCreationPersonneMoralesPaginated: { totalCount: number } }>({
        query: CHECK,
        variables: {
          filter: filter,
        },
        fetchPolicy: 'network-only',
      })
    );

    return requestResult.data.allCreationPersonneMoralesPaginated.totalCount;
  }


}
