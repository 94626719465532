import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryComponent } from '@lib/components/history/history';
import { OperationHistoriqueDialogComponent } from '@lib/components/operations-panel-info/operation-historique-dialog/operation-historique-dialog.component';
import { Operation } from '@lib/models/generated/graphql';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiSnackbarModule, OuiSnackbarService } from 'omnium-ui/snackbar';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';

@Component({
  selector: 'app-delete-produit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    OuiDialogModule,
    OuiButtonModule,
    OuiIconModule,
    OuiSnackbarModule,
    OuiLoadingModule,
    HistoryComponent,
  ],
  templateUrl: './delete-produit-dialog.component.html',
  styleUrls: ['./delete-produit-dialog.component.scss'],
})
export class DeleteProduitDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { operation: Operation },
    private dialogRef: MatDialogRef<OperationHistoriqueDialogComponent>,
    private snackbarService: OuiSnackbarService,
    private draftOperationsService: DraftOperationsService
  ) {}

  async deleteProduit() {
    try {
      await this.draftOperationsService.deleteDraftOperation(this.data.operation);
      this.dialogRef.close(true);
    } catch (error) {
      this.snackbarService.open("Une erreur s'est produite durant la suppression du produit", 'error', 5000);
    }
  }
}
