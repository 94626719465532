<div class="page-layout flex-vertical gap-32 align-self-stretch">
  <oui-banner
    *ngIf="isNotHabilitedWarning"
    title="Vous n'avez aucune habilitation active en placement"
    declination="error"
    [action]="noHabilAction"></oui-banner>
  <app-user-notification (onActionClicked)="goToCreationPM()"> </app-user-notification>

  <div class="flex-horizontal width-100pc">
    <h4 class="flex-none">Mes opérations</h4>
    <div class="flex-auto"></div>
    <div class="flex-none">
      <div class="flex-horizontal height-100pc gap-16">
        <oui-button
          class="align-self-center"
          type="outlined"
          size="large"
          (click)="onDeclareActeButtonClick()"
          [disabled]="isNotHabilitedWarning"
          >Déclarer un acte de gestion</oui-button
        >
        <oui-button
          class="align-self-center"
          type="contained"
          size="large"
          (click)="onDeclareSubsButtonClick()"
          [disabled]="isNotHabilitedWarning"
          >Déclarer une souscription</oui-button
        >
      </div>
    </div>
  </div>
  <ng-container *ngIf="tabs; else loading">
    <oui-tab-group class="tabs">
      <oui-tab *ngFor="let tab of tabs; let i = index" [label]="tab.label">
        <ng-container *ngIf="isCreationTab(tab)">
          <lib-creation-personne-morale-dashboard
            [columnSelection]="tab.columnSelection"
            [config]="tab.dashboardConfiguration"
            (onTotalCount)="tab.updateLabel($event)"
            (onDeclareSouscription)="onDeclareSouscriptionFromCreationPM($event)">
          </lib-creation-personne-morale-dashboard>
        </ng-container>
        <ng-container *ngIf="isOperationTab(tab)">
          <app-operation-dashboard
            [columnSelection]="tab.columnSelection"
            [filterSelection]="filterSelection"
            [defaultQueryFilter]="tab.defaultQueryFilter"
            (onTotalCount)="tab.updateLabel($event)"
            (onSelectedOperation)="onOperationClick($event)"
            [dashboardKey]="tab.dashboardKey"></app-operation-dashboard>
        </ng-container>
      </oui-tab>
    </oui-tab-group>
  </ng-container>
  <ng-template #loading>
    <oui-loading class="flex-horizontal width-100pc justify-center flex-center min-height-500"></oui-loading>
  </ng-template>
</div>
