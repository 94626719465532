import { Injectable } from '@angular/core';
import {
  CreateBatchFichierOperationForGroupInput,
  FichierOperation,
  Investisseur,
  InvestisseurType,
  Operation,
  OperationGroup,
  OperationStateTransitionTrigger,
  OperationType,
  OperationsPaginatedCollectionSegment,
  TransactionPersonnelleEnum,
  UpdateOperationInput,
} from '@lib/models/generated/graphql';
import { AuthService } from '@lib/services/auth-service.service';
import { OperationsService } from '@lib/services/operations.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { declareAllOperationsInGroup } from 'src/service/draft-operation/mutations/declare-all-operation-in-group.mutation';
import { DraftOperationsFilesService } from './draft-operation-files.service';
import { OperationDraft } from './models/operation-draft.model';
import { OperationGroupData } from './models/operation-group-data.model';
import { createActeDeGestionMutation } from './mutations/create-acte-de-gestion.mutation';
import { createSouscriptionMutation } from './mutations/create-souscription.mutation';
import { deleteDraftOperationMutation } from './mutations/delete-draft-operation.mutation';
import { fireGroupStateTransitionTriggerMutation } from './mutations/fire-group-state-transition-trigger.mutation';
import { resetDraftOperationGroupMutation } from './mutations/reset-draft-operation-group.mutation';
import { fetchInvestisseurFileDataQuery } from './queries/investisseur-file-data.query';
import { operationGroupDetailsQuery } from './queries/operation-group-details.query';
const DUPLICAT_INV = gql`
  query investisseurAndDuplicatesById($id: Long!) {
    investisseurAndDuplicatesById(id: $id) {
      id
      code
      creationDate
      statutCode
      statutCodeNavigation {
        libelle
        actif
      }
      investisseurEntite {
        displayName
        personnePhysique {
          dateNaissance
          prenom
          nom
          genre
        }
      }
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class DraftOperationsService {
  private _isProduitHabilitedSubject = new BehaviorSubject<boolean>(true);
  public readonly isProduitHabilited$: Observable<boolean> = this._isProduitHabilitedSubject.asObservable();

  operationGroupData: OperationGroupData = {
    operations: [],
  };

  private _operationGroupSubject = new BehaviorSubject<OperationGroupData>(this.operationGroupData);
  public readonly operationGroup$: Observable<OperationGroupData> = this._operationGroupSubject.asObservable();
  public readonly operationListInGroup$: Observable<OperationDraft[]> = this._operationGroupSubject.pipe(
    map(operationGroupData => operationGroupData.operations)
  );

  investisseur?: Investisseur | undefined;
  investisseurType: InvestisseurType = InvestisseurType.PersonnePhysique;

  coInvestisseur?: Investisseur | undefined;

  withCoInvestisseur = false;

  isTryingAutoImport = false;

  constructor(
    private operationsService: OperationsService,
    private queryManager: QueryManagerService,
    private authService: AuthService,
    private draftOperationsFilesService: DraftOperationsFilesService
  ) {}

  public async reset() {
    this.operationGroupData = {
      operations: [],
    };

    this.coInvestisseur = undefined;
    this.investisseur = undefined;

    this.withCoInvestisseur = false;

    this.draftOperationsFilesService.reset();

    this._operationGroupSubject.next(this.operationGroupData);
  }

  public setTransactionPersonnelleStatutValue(statut: TransactionPersonnelleEnum | undefined) {
    this.operationGroupData.transactionPersonnelleStatut = statut;
    this._operationGroupSubject.next(this.operationGroupData);
  }

  public async getInvestisseurAndDuplicatesById(investisseurId: number): Promise<Investisseur[]> {
    const result = await firstValueFrom(
      this.queryManager.query<{ investisseurAndDuplicatesById: Investisseur[] | null }>({
        query: DUPLICAT_INV,
        variables: {
          id: investisseurId,
        },
      })
    );
    return result.data?.investisseurAndDuplicatesById ?? [];
  }
  /**
   * Fetches and updates the investisseur/Co-Investisseur data, according to the provided investisseur ID, it will update the Investisseur or the Co-Investisseur.
   * The 'isForCoInvestisseur' parameter will force the update on the Co-Investisseur (use it when the the Co-Investisseur is not set).
   * This will also assign the files to the operations in the group
   *
   * @param {number | undefined | null} investisseurId - The ID of the investisseur/co-investisseur.
   * @param {boolean} isForCoInvestisseur - Force the data for the co-investisseur.
   * @return {Promise<void>} A promise that resolves when the data is fetched and updated.
   */

  async fetchAndUpdateInvestisseurData(
    investisseurId: number | undefined | null,
    isForCoInvestisseur: boolean = false
  ): Promise<void> {
    await this.refreshInvestisseurFilesData(investisseurId, isForCoInvestisseur);
    const isCoInvestisseur = isForCoInvestisseur || investisseurId === this.coInvestisseur?.id;
    await this.assignFilesToOperationInGroup(isCoInvestisseur);
  }

  /**
   * Fetches and updates the investisseur/Co-Investisseur data, according top the provided investisseur ID, it will update the Investisseur or the Co-Investisseur
   * The 'isForCoInvestisseur' parameter will force the update on  the Co-Investisseur (use it when the the Co-Investisseur is not set)
   *
   * @param {number | undefined | null} investisseurId - The ID of the investisseur/co-investisseur.
   * @param {boolean} isForCoInvestisseur - Force the data for the co-investisseur.
   * @return {Promise<void>} A promise that resolves when the data is fetched and updated.
   */
  async refreshInvestisseurFilesData(
    investisseurId: number | undefined | null,
    isForCoInvestisseur: boolean = false
  ): Promise<void> {
    const isCoInvestisseur = isForCoInvestisseur || investisseurId === this.coInvestisseur?.id;
    if (investisseurId) {
      const result = await firstValueFrom(
        this.queryManager.query<{ investisseurById: Investisseur }>({
          query: fetchInvestisseurFileDataQuery,
          variables: {
            id: investisseurId,
          },
          fetchPolicy: 'network-only', // this ensure that the data is fetched from the server
        })
      );
      if (result.data?.investisseurById) {
        // merge fileData with existing data for investisseur
        isCoInvestisseur
          ? (this.coInvestisseur = { ...this.coInvestisseur, ...result.data.investisseurById })
          : (this.investisseur = { ...this.investisseur, ...result.data.investisseurById });
      }
    }
  }

  async updateAllOperations(): Promise<boolean> {
    if (!this.operationGroupData?.id) {
      return false;
    }
    const updateDatas: UpdateOperationInput[] = [];
    this.operationGroupData.operations.forEach(op => {
      const operationData: UpdateOperationInput = {
        operationId: op.id,
        produitId: op.produitId,
        contratId: this.operationGroupData.contrat?.id,
        investisseurId: this.investisseur?.id,
        coInvestisseurId: this.coInvestisseur?.id,
        operationConfigId: op.operationConfigId,
        donneesSpecifiques: op.donneesSpecifiques,
        operationGroupId: this.operationGroupData.id ?? 0,
        typeSignaturePartenaire: op.typeSignaturePartenaire,
        transactionPersonnelleStatut: this.operationGroupData.transactionPersonnelleStatut,
      };
      updateDatas.push(operationData);
    });
    try {
      const updateOperationsResult = await this.operationsService.updateOperations(updateDatas);
      if (updateOperationsResult && updateOperationsResult.length > 0) {
        this.operationGroupData.operations = updateOperationsResult.map(op => {
          return this.getOperationDraft(op);
        });
        this.checkProduitHabilited();
        this._operationGroupSubject.next(this.operationGroupData);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  updateOperationGroupDataFiles(updatedOperations: Operation[]): void {
    this.operationGroupData.operations.forEach(op => {
      const updated = updatedOperations.find(o => o.id === op.id);
      if (updated) {
        op.fichierOperations = updated.fichierOperations;
      }
    });
    this._operationGroupSubject.next(this.operationGroupData);
  }
  /**
   * Deletes a draft operation
   *
   * @param {Operation} operation - the operation to be deleted
   * @return {Promise<void>} a Promise that resolves when the operation is deleted
   */
  async deleteDraftOperation(operation: Operation): Promise<void> {
    const index = this.operationGroupData.operations.findIndex(op => op.id === operation.id);
    if (index === -1) {
      Promise.reject('Operation not found');
    }
    const result = await firstValueFrom(
      this.queryManager.mutate<{ deleteDraftOperation: Number }>({
        mutation: deleteDraftOperationMutation,
        variables: {
          operationId: operation.id,
        },
      })
    );
    if (result.data?.deleteDraftOperation === operation.id) {
      this.operationGroupData.operations.splice(index, 1);
      this._operationGroupSubject.next(this.operationGroupData);
      return Promise.resolve();
    }
    return Promise.reject(result.errors);
  }

  async resetDraftOperationGroup(): Promise<void> {
    if (!this.operationGroupData?.id) {
      return;
    }
    const result = await firstValueFrom(
      this.queryManager.mutate<{ resetDraftOperationGroup: OperationGroup }>({
        mutation: resetDraftOperationGroupMutation,
        variables: {
          groupId: this.operationGroupData.id,
        },
      })
    );
    if (result.data?.resetDraftOperationGroup) {
      this.operationGroupData.operations = result.data.resetDraftOperationGroup.operations.map(op => {
        return this.getOperationDraft(op);
      });
      this._operationGroupSubject.next(this.operationGroupData);
    }
  }
  async fireGroupStateTransitionTrigger(trigger: OperationStateTransitionTrigger): Promise<OperationGroup | undefined> {
    if (this.operationGroupData?.operations.some(op => op.activeOperationStateTransitionTriggers?.includes(trigger))) {
      const result = await firstValueFrom(
        this.queryManager.mutate<{ fireOperationStateTransitionTriggerForOperationGroup: OperationGroup }>({
          mutation: fireGroupStateTransitionTriggerMutation,
          variables: {
            operationGroupId: this.operationGroupData?.id,
            trigger,
          },
        })
      );

      const updatedOperations = result.data?.fireOperationStateTransitionTriggerForOperationGroup?.operations;
      if (updatedOperations && updatedOperations[0]?.statut) {
        this.operationGroupData.operations.forEach(opdraft => {
          const updated = updatedOperations.find(o => o.id === opdraft.id);
          if (updated) {
            opdraft.statutId = updated.statutId;
            opdraft.statut = updated.statut;
            opdraft.activeOperationStateTransitionTriggers = updated.activeOperationStateTransitionTriggers;
          }
        });
      }
    }
    return undefined;
  }

  getOperationDraft(operation: Operation): OperationDraft {
    const operationDraft = deepCopy(operation) as OperationDraft;
    operationDraft.specificDataValues = {
      isFormValid: false,
      data: operation.donneesSpecifiques ? JSON.parse(operation.donneesSpecifiques) : {},
    };
    return operationDraft;
  }

  isUserHabilitedOperation(operation: Operation) {
    if (!operation?.produit) {
      return true;
    }
    const isHabilited = this.authService
      .getUserValidHabilitationIds()
      .includes(operation?.produit?.habilitationNavigation?.id);

    return isHabilited;
  }

  isUserHabilitedGroup() {
    const isHabilitedForGroup = this.operationGroupData.operations
      .map(op => this.isUserHabilitedOperation(op))
      .every(isHabilited => isHabilited);
    return isHabilitedForGroup;
  }

  getMinOperationsStateId(): number {
    return Math.min(...this.operationGroupData.operations.map(operation => operation.statutId));
  }
  getMaxOperationsStateId(): number {
    return Math.max(...this.operationGroupData.operations.map(operation => operation.statutId));
  }
  isAllFormsValid(): boolean {
    return this.operationGroupData.operations.every(operation => operation.specificDataValues?.isFormValid);
  }
  isAllOperationsConfigured(): boolean {
    return this.operationGroupData.operations.every(operation => !!operation.operationConfigId);
  }

  isInvestisseurStepValid(): boolean {
    const Investisseurvalid = this.withCoInvestisseur
      ? !!this.investisseur && !!this.coInvestisseur
      : !!this.investisseur;
    return Investisseurvalid;
  }

  checkProduitHabilited() {
    let isHabilited = true;
    if (this.authService.userHabilitationCheck.getValue()) {
      isHabilited = this.isUserHabilitedGroup();
    }

    this._isProduitHabilitedSubject.next(isHabilited);
  }

  async addNewOperationInGroup(): Promise<Operation | undefined> {
    // depending the type of operation, we call the right function to create a new souscription/acte de gestion
    const isSouscription = this.operationGroupData.opertionType === OperationType.Souscription;
    const operationResult = isSouscription
      ? await this.createNewSouscription(this.investisseur?.id, this.coInvestisseur?.id)
      : await this.createNewActeDeGestion(
          this.operationGroupData?.contrat?.investisseurCommande?.[0]?.investisseurId,
          this.operationGroupData?.contrat?.id,
          this.operationGroupData?.contrat?.produit?.id
        );

    if (!operationResult) {
      return;
    }

    this.operationGroupData?.operations.push(this.getOperationDraft(operationResult));
    this.operationGroupData.id = operationResult.operationGroupId;

    this.checkProduitHabilited();
    this._operationGroupSubject.next(this.operationGroupData);
    return operationResult;
  }

  async declareAllOperationsInGroup(): Promise<OperationGroup | undefined> {
    if (
      this.operationGroupData?.operations.some(op =>
        op.activeOperationStateTransitionTriggers?.includes(
          OperationStateTransitionTrigger.ConsultantTransfersNewSouscription
        )
      )
    ) {
      const result = await firstValueFrom(
        this.queryManager.mutate<{ declareAllOperationsInGroup: OperationGroup }>({
          mutation: declareAllOperationsInGroup,
          variables: {
            operationGroupId: this.operationGroupData?.id,
          },
        })
      );

      const updatedOperations = result.data?.declareAllOperationsInGroup?.operations;
      if (!updatedOperations || updatedOperations.length === 0 || (result.errors?.length ?? 0) > 0) {
        return undefined;
      }

      this.operationGroupData.operations.forEach(opdraft => {
        const updated = updatedOperations.find(o => o.id === opdraft.id);
        if (updated) {
          opdraft.statutId = updated.statutId;
          opdraft.statut = updated.statut;
          opdraft.activeOperationStateTransitionTriggers = updated.activeOperationStateTransitionTriggers;
        }
      });
      return result.data?.declareAllOperationsInGroup;
    }
    return undefined;
  }

  private async createNewSouscription(
    investisseurId: string,
    coInvestisseurId?: string
  ): Promise<Operation | undefined> {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ createSouscription: Operation }>({
        mutation: createSouscriptionMutation,
        variables: {
          investisseurId,
          operationGroupId: this.operationGroupData?.id,
          coInvestisseurId: coInvestisseurId,
        },
      })
    );
    const resultOperation = result.data?.createSouscription;
    return resultOperation;
  }

  private async createNewActeDeGestion(
    investisseurId: number,
    contratId: number,
    produitId: number,
    coInvestisseurId?: number
  ): Promise<Operation | undefined> {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ createActeDeGestion: Operation }>({
        mutation: createActeDeGestionMutation,
        variables: {
          investisseurId,
          contratId,
          produitId,
          operationGroupId: this.operationGroupData?.id,
          coInvestisseurId: coInvestisseurId,
        },
      })
    );
    const resultOperation = result.data?.createActeDeGestion;
    return resultOperation;
  }

  async fetchAndUpdateCurrentGroup(operationId: number | undefined) {
    if (operationId) {
      const result = await firstValueFrom(
        this.queryManager.query<{
          allOperationsPaginated: OperationsPaginatedCollectionSegment;
        }>({
          query: operationGroupDetailsQuery,
          variables: {
            id: operationId,
          },
        })
      );

      if (result.data.allOperationsPaginated.items && result.data.allOperationsPaginated.items.length > 0) {
        const operationItem = result.data.allOperationsPaginated.items[0];
        if (operationItem.operationGroup.operations) {
          this.updateOperationGroupData(operationItem.operationGroup.operations);
          this.checkProduitHabilited();
        }
      }
    }
  }

  updateOperationGroupData(operations: Operation[]): void {
    this.operationGroupData.operations = operations.map(op => {
      return this.getOperationDraft(op);
    });
    const firstOp = operations[0];
    this.investisseur = firstOp.investisseur ? deepCopy(firstOp.investisseur) : undefined;
    this.coInvestisseur = firstOp.coInvestisseur ? deepCopy(firstOp.coInvestisseur) : undefined;

    if (this.coInvestisseur) {
      this.withCoInvestisseur = true;
    }
    this.operationGroupData.contrat = firstOp.contrat ? deepCopy(firstOp.contrat) : undefined;
    this.operationGroupData.transactionPersonnelleStatut = deepCopy(firstOp.transactionPersonnelleStatut) ?? undefined;

    if (this.operationGroupData?.contrat && firstOp.produit) {
      // we add produit here for convenience and to avoid to get it twice from the query (operation and contrat)
      this.operationGroupData.contrat.produit = deepCopy(firstOp.produit);
    }
    this.operationGroupData.opertionType = firstOp.operationType;
    this.operationGroupData.id = firstOp.operationGroupId;

    this._operationGroupSubject.next(this.operationGroupData);
  }

  /** FICHIERS */

  public async assignFilesToOperationInGroup(isCoInvestisseur: boolean = false) {
    if (!this.investisseur) {
      return;
    }
    const result = await this.draftOperationsFilesService.assignFilesToOperationInGroup(
      this.investisseur,
      this.coInvestisseur,
      this.operationGroupData,
      isCoInvestisseur
    );
    this._operationGroupSubject.next(this.operationGroupData);
    return result;
  }

  public async addFichierOperationToCurrentGroup(
    batchconnaissanceClientFilesToCreate: CreateBatchFichierOperationForGroupInput
  ) {
    await this.draftOperationsFilesService.addFichierOperationToGroup(
      batchconnaissanceClientFilesToCreate,
      this.operationGroupData,
      this.investisseur,
      this.coInvestisseur
    );
  }

  public async removeFichierOperationToCurrentGroup(fichierOperation: FichierOperation): Promise<void> {
    return this.draftOperationsFilesService.removeFichierOperationToCurrentGroup(
      fichierOperation,
      this.operationGroupData,
      this.investisseur,
      this.coInvestisseur
    );
  }

  public async updateFichierOperationCommentaire(fichierOperationId: number, commentaire: string) {
    return this.draftOperationsFilesService.updateFichierOperationCommentaire(
      fichierOperationId,
      commentaire,
      this.operationGroupData
    );
  }
}
