<ng-container *ngTemplateOutlet="getMatchingTemplate()"></ng-container>

<ng-template #conf1>
  <app-specific-data-conf1
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf1>
</ng-template>
<ng-template #conf2>
  <app-specific-data-conf2
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf2>
</ng-template>
<ng-template #conf3>
  <app-specific-data-conf3
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf3>
</ng-template>
<ng-template #conf4>
  <app-specific-data-conf4
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf4>
</ng-template>
<ng-template #conf5>
  <app-specific-data-conf5
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf5>
</ng-template>
<ng-template #conf6>
  <app-specific-data-conf6
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf6>
</ng-template>
<ng-template #conf7>
  <app-specific-data-conf7
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf7>
</ng-template>
<ng-template #conf8>
  <app-specific-data-conf8
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf8>
</ng-template>
<ng-template #conf9>
  <app-specific-data-conf9
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf9>
</ng-template>
<ng-template #conf10>
  <app-specific-data-conf10
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf10>
</ng-template>
<ng-template #conf11>
  <app-specific-data-conf11
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf11>
</ng-template>
<ng-template #conf12>
  <app-specific-data-conf12
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf12>
</ng-template>
<ng-template #conf13>
  <app-specific-data-conf13
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf13>
</ng-template>
<ng-template #conf14>
  <app-specific-data-conf14
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf14>
</ng-template>
<ng-template #conf15>
  <app-specific-data-conf15
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf15>
</ng-template>
<ng-template #conf16>
  <app-specific-data-conf16
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf16>
</ng-template>
<ng-template #conf17>
  <app-specific-data-conf17
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf17>
</ng-template>
<ng-template #conf18>
  <app-specific-data-conf18
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf18>
</ng-template>
<ng-template #conf19>
  <app-specific-data-conf19
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf19>
</ng-template>
<ng-template #conf20>
  <app-specific-data-conf20
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf20>
</ng-template>
<ng-template #conf21>
  <app-specific-data-conf21
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf21>
</ng-template>
<ng-template #conf22>
  <app-specific-data-conf22
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf22>
</ng-template>
<ng-template #conf23>
  <app-specific-data-conf23
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf23>
</ng-template>
<ng-template #conf24>
  <app-specific-data-conf24
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf24>
</ng-template>
<ng-template #conf25>
  <app-specific-data-conf25
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf25>
</ng-template>
<ng-template #conf26>
  <app-specific-data-conf26
    [operation]="operation"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    (onSpecificDataChanged)="onDataChange($event)"></app-specific-data-conf26>
</ng-template>

<ng-template #noConf>
  <div class="flex-horizontal flex-center width-100pc rounded">
    <div class="flex-vertical width-100pc background-error-pastel rounded padding-16 border-left-error">
      <div class="flex-horizontal flex-center">
        <oui-icon color="error">report_problem</oui-icon>
        <div class="subtitle-2 color-on-surface-emphasis padding-8">Note de Stellium Placement</div>
      </div>
      <div class="body-2 color-on-surface-medium">
        Ce type d'operation sur ce produit n'est actuellement pas disponible. Veuillez contacter le support.
      </div>
    </div>
  </div>
</ng-template>
