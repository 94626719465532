import { Component, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionsService } from '@lib/services/options.service';
import { OuiButtonToggleOption } from 'omnium-ui/button-toggle';
import { AbstractSpecificDataComponent } from '../../abstract-specific-data.component';

@Component({
  selector: 'app-demembrement',
  templateUrl: './demembrement.component.html',
  styleUrls: ['./demembrement.component.scss'],
})
export class DemembrementComponent extends AbstractSpecificDataComponent {
  override getDataName(): string {
    return 'demembrement';
  }

  metadata: any = {};
  dismemberedChecked = false;
  isKnown: boolean | undefined;
  isViager = false;
  dismemberedControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  dismemberedOptions: OuiButtonToggleOption<string>[];
  contrepartieOptions: OuiButtonToggleOption<boolean>[] = [
    { label: 'Oui', value: true },
    { label: 'Non', value: false },
  ];
  contrepartieControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  contrepartieNameControl = new FormControl<string | undefined>(undefined, [Validators.required]);

  dismemberedGroup = new FormGroup({
    demembrement: this.dismemberedControl,
    contrepartieIsKnown: this.contrepartieControl,
    contrepartieName: this.contrepartieNameControl,
  });

  constructor(private optionsService: OptionsService) {
    super();
  }
  ngOnInit() {
    this.dismemberedControl.valueChanges.subscribe(value => {
      this.isViager = value == 'Viager';
    });
    this.dismemberedOptions = this.optionsService.getDemembrementOptions();
    this.dismemberedGroup.valueChanges.subscribe(value => {
      this.metadata = this.computeData(value);
      this.onSpecificDataChanged.emit({
        dataSource: this.getDataName(),
        data: { isFormValid: this.isFormValid(), data: this.metadata },
      });
    });
    // push initial state
    this.onSpecificDataChanged.emit({
      dataSource: this.getDataName(),
      data: { isFormValid: this.isFormValid(), data: {} },
    });
  }

  onDemembrementToggled() {
    this.metadata = this.computeData(this.dismemberedGroup.value);
    this.onSpecificDataChanged.emit({
      dataSource: this.getDataName(),
      data: { isFormValid: this.isFormValid(), data: this.metadata },
    });
  }

  computeData(formvalue: any): any {
    const data = {} as any;
    if (!this.dismemberedChecked) {
      data.demembrement = undefined;
      return data;
    }
    if (formvalue.demembrement == 'Viager') {
      data.demembrement = formvalue.demembrement;
      data.contrepartieName = formvalue.contrepartieName;
      data.contrepartieIsKnown = undefined;
    } else if (formvalue.demembrement) {
      data.demembrement = formvalue.demembrement;
      data.contrepartieIsKnown = formvalue.contrepartieIsKnown;
      this.isKnown = data.contrepartieIsKnown;
      data.contrepartieName = data.contrepartieIsKnown ? formvalue.contrepartieName : undefined;
    }

    return data;
  }

  isFormValid(): boolean {
    if (!this.dismemberedChecked) {
      return true;
    }
    if (this.dismemberedGroup.value.demembrement === 'Viager' && this.dismemberedGroup.value.contrepartieName) {
      return true;
    } else if (
      this.dismemberedGroup.value.demembrement &&
      this.dismemberedGroup.value.contrepartieIsKnown != null &&
      (!this.dismemberedGroup.value.contrepartieIsKnown ||
        (this.dismemberedGroup.value.contrepartieIsKnown && this.dismemberedGroup.value.contrepartieName))
    ) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    var data = changes['initialData']?.currentValue?.data;
    if (data) {
      const demembrementValue = data.demembrement;
      const contrepartieValue = data.contrepartieIsKnown;
      const contrepartieNameValue = data.contrepartieName;
      if (demembrementValue) {
        this.dismemberedChecked = true;
        this.dismemberedControl.setValue(demembrementValue);
      }
      if (contrepartieValue) {
        this.contrepartieControl.setValue(contrepartieValue);
      }
      if (contrepartieNameValue) {
        this.contrepartieNameControl.setValue(contrepartieNameValue);
      }
    }
  }
}
