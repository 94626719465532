import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AbstractSpecificDataComponent } from '../abstract-specific-data.component';

@Component({
  template: '',
})
export abstract class AbstractSpecificFieldComponent<T> extends AbstractSpecificDataComponent {
  metadata: any = {};

  @Input()
  isRequired?: boolean = false;

  control: FormControl<T | undefined | null>;

  constructor() {
    super();
    this.control = new FormControl<T | undefined>(undefined);
  }

  ngOnInit() {
    if (this.isRequired) {
      this.control.setValidators(Validators.required);
    }
    this.control.valueChanges.subscribe(value => {
      this.metadata[this.getDataName()] = value;
      this.onDataChange({
        dataSource: this.getDataName(),
        data: { isFormValid: this.isFormValid(), data: this.metadata },
      });
    });

    this.onDataChange({ dataSource: this.getDataName(), data: { isFormValid: this.isFormValid(), data: {} } });
  }

  isFormValid() {
    return this.control.valid || this.control.disabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    var data = changes['initialData']?.currentValue;
    if (data) {
      const dataName = this.getDataName();
      const dataValueString = data.data[this.getDataName()];
      if (dataValueString) {
        var value: T = this.wrapData(dataValueString);
        if (value) {
          this.control.setValue(value);
        }
      }
    }
  }

  abstract wrapData(value: string): T;
}
