import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { AbstractPaginatedTableComponent } from '@lib/components/paginated-table/paginated-table.component';
import { Commande } from '@lib/models/generated/graphql';

@Component({
  selector: 'app-contrats-table',
  templateUrl: './contrats-table.component.html',
  styleUrls: ['./contrats-table.component.scss'],
})
export class ContratsTableComponent extends AbstractPaginatedTableComponent<Commande> {
  @ViewChildren('contratsSort') contratsSort!: QueryList<MatSort>;
  @ViewChild(MatSort) sort: MatSort;
  @Output() onRowClicked = new EventEmitter<Commande>();

  displayedColumns = ['Investisseur', 'Co-investisseur', 'Contrat', 'Enveloppe', 'Produit', 'Action'];

  constructor() {
    super();
  }

  ngOnInit() {
    this.fetchData(true);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.dataSource.sort = this.sort;

    // we should define how to acces to data value to sort mecanism
    this.dataSource.sortingDataAccessor = (data: Commande, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'Investisseur':
          return data.investisseurCommande[0]?.investisseur?.investisseurEntite?.displayName ?? '';
        case 'Co-investisseur':
          return data.investisseurCommande[1]?.investisseur?.investisseurEntite?.displayName ?? '';
        case 'Contrat':
          return data?.numeroContrat ?? '';
        case 'Enveloppe':
          return data?.produit?.enveloppeNavigation?.libelle ?? '';
        case 'Produit':
          return data.produit?.nomTechniqueProduitBox ?? data?.produit?.nom ?? '';
        default:
          return '';
      }
    };
  }

  onRowClick(commande: Commande) {
    this.onRowClicked.emit(commande);
  }
}
