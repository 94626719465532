<oui-banner
  *ngIf="!isHabilited"
  title="Vous n'avez aucune habilitation active pour ce produit"
  declination="error"
  [action]="noHabilAction"></oui-banner>
<div class="flex-vertical gap-32">
  <app-souscription-file-items
    *ngIf="operation"
    [operation]="operation"
    [operationIndex]="index"
    [fichierOperations]="operationFiles"
    [showBannerSouscription]="true"
    (onAddedFile)="addFichierOperation($event)"
    [withGlobalAddFilesButton]="true"
    (onRemoveFile)="onFileRemoved()">
  </app-souscription-file-items>
</div>
