<ng-container *ngIf="operation">
  <oui-accordion #accordion class="with-border rounded padding-32" [expanded]="expanded">
    <oui-accordion-header class="height-auto">
      <oui-accordion-title>
        <app-operation-accordion-title [operation]="operation" [index]="index" [showDetails]="!accordion.expanded">
        </app-operation-accordion-title>
      </oui-accordion-title>

      <oui-accordion-actions>
        <oui-button type="text" (click)="onDeleteClick()" *ngIf="!deleteDisabled"> Supprimer </oui-button>
      </oui-accordion-actions>
    </oui-accordion-header>
    <div class="flex-vertical gap-32 padding-top-32">
      <app-search-nature-autocomplete
        *ngIf="operation.operationType == acteGestionType"
        [isRequired]="true"
        (onNatureSelected)="onNatureOperationSelected($event)"
        [currentNatureId]="initOperationConfig?.natureOperation?.id"
        [enveloppeId]="operation.produit?.enveloppeNavigation?.code">
      </app-search-nature-autocomplete>
      <oui-autocomplete
        *ngIf="operation.operationType == souscriptionType && produitAutocompleteOptions"
        label="Produit"
        [control]="produitControl"
        [options]="produitAutocompleteOptions"></oui-autocomplete>
      <oui-banner
        *ngIf="!isHabilitedProduit()"
        title="Vous n'avez aucune habilitation active pour ce produit"
        declination="error"
        [action]="noHabilAction"></oui-banner>
      <ng-container *ngIf="operation.operationConfigId; else noConfigFound">
        <app-subscribe-specific
          [operation]="operation"
          [operationConfigId]="operation.operationConfigId"
          (onSpecificDataChanged)="onSpecificDataChange($event)"
          [initialData]="initSpecificData"
          [operationIndex]="index"></app-subscribe-specific> </ng-container
      ><ng-template #noConfigFound>
        <app-no-config-banner *ngIf="isChoiceDone"></app-no-config-banner>
      </ng-template>
    </div>
  </oui-accordion>
</ng-container>
