export const environment = {
  authConfig: {
    loginUrl: 'https://auth-int.prodemial.fr/cas/oauth2.0/authorize',
    tokenUrl: 'https://auth-int.prodemial.fr/cas/oauth2.0/token',
    logoutUrl: 'https://auth-int.prodemial.fr/cas/logout?service=' + encodeURI(window.location.origin),
    clientId: 'dj4knr49up.stellium',
    authGroup: 'APP_MYAPP',
    consultantGroups: ['CN=GOF_CONSULTANT'],
    backOfficeGroups: ['CN=APP_PLACEMENT_BACKOFFICE_COURTAGE_RECETTE'],
  },
  backEndUrl: 'https://api-box-hp-int.azurewebsites.net/graphql',
  appInsightsKey: 'e76e0f40-7f7f-4c19-be2c-849ccb88225c',
};
