<div oui-dialog-title>
  Envoyer la mise à jour
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  En cliquant sur "Envoyer", vos corrections seront envoyées à Stellium Placement et vous ne pourrez plus les modifier.
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" [oui-dialog-close]="true">Envoyer</oui-button>
</oui-dialog-actions>
