<div *ngIf="!operation" class="flex-horizontal flex-center padding-16 width-100pc height-100pc">
  <oui-loading class="flex-horizontal flex-center padding-16 width-100pc justify-center"></oui-loading>
</div>
<div *ngIf="operation" class="page-body flex-horizontal background-white height-100pc">
  <app-operations-panel-info [operation]="operation" [isConsultant]="true" (onBackArrowClicked)="onBackArrowClicked()">
  </app-operations-panel-info>
  <div class="page-content padding-72 gap-32 flex-vertical width-100pc background-background">
    <div class="flex-horizontal width-100pc gap-16 flex-center">
      <h6>Documents & justificatifs</h6>
      <div class="flex-1"></div>
      <ng-container *ngIf="!hideStatusChangeButton">
        <ng-container *ngIf="!isEditableMode && !isOperationEndable">
          <oui-button type="text" iconPrefix="undo" [disabled]="!isDeclarationCancelable"
            (click)="onCancelDeclaration()">
            Annuler la déclaration
          </oui-button>
          <oui-infos-bulle *ngIf="!isDeclarationCancelable" bulleTitle="Traitement en cours">Vous ne pouvez pas annuler
            la
            déclaration car l’opération est en cours de traitement.</oui-infos-bulle>
        </ng-container>
        <ng-container *ngIf="isOperationEndable">
          <oui-button type="outlined" (click)="onEndOperation()">Marquer comme terminé</oui-button>
        </ng-container></ng-container>
      <oui-menu class="demo-component" *ngIf="isEditableMode" [menuItems]="fileMenu" horizontalPosition="left"
        verticalPosition="bottom">
        <oui-button *ngIf="!isFilesLoading" type="outlined" iconPrefix="add_circle_outline">
          Ajouter des documents
        </oui-button>
        <oui-loading size="small" *ngIf="isFilesLoading"></oui-loading>
      </oui-menu>
      <oui-button *ngIf="isEditableMode" (click)="openNonConformiteResponseDialog()"
        [disabled]="!isSendUpdatePossible || !isUserHabilited">Envoyer la mise à jour</oui-button>
    </div>
    <oui-banner *ngIf="isSendUpdatePossible && isUserHabilited"
      title="Les retours traités n'ont pas été envoyés à Stellium Placement. Cliquez sur “Envoyer la mise à jour” ci-dessus."
      declination="error" [showCloseButton]="false"></oui-banner>
    <oui-banner *ngIf="isEditableMode && !isUserHabilited"
      title="Vous ne pouvez pas envoyer la mise à jour à Stellium Placement car vous n'avez aucune habilitation active pour ce produit."
      declination="error" [showCloseButton]="false"></oui-banner>
    <oui-banner *ngIf="operation.commentaireGestionnaire" type="complex" title="Commentaire de Stellium Placement"
      [description]="operation.commentaireGestionnaire" declination="alert" class="no-shrink"
      [showCloseButton]="false"></oui-banner>
    <div class="flex-vertical" *ngIf="!isEditableMode">
      <lib-operation-files-list class="files-list" audience="consultant" [checkbox]="false"
        [files]="responseService.fichiersOperations" [displayStatus]="false"
        (fileClicked)="onfileViewClick($event)"></lib-operation-files-list>
    </div>
    <app-files-list-status *ngIf="isEditableMode" [files]="responseService.fichiersOperations"
      (fileClicked)="onEditableFileClick($event)" [checkbox]="false">
    </app-files-list-status>
  </div>
</div>

<ng-template #documentViewerPortalContent>
  <app-response-nc-document-viewer [selectedFile]="selectedFile" [operation]="operation"
    (close)="closeDocumentViewer($event)"></app-response-nc-document-viewer>
</ng-template>