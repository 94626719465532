<div class="flex-vertical gap-32">
  <app-objet-modification
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-objet-modification>
  <app-type-signature-partenaire
    [operation]="operation"
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-type-signature-partenaire>
  <app-specific-comment
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-specific-comment>
</div>
