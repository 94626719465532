import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Operation, OperationGroup, OperationsPaginatedCollectionSegment } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

const fireConsultantCancelDeclarationforOperationGroup = gql`
  mutation fireConsultantCancelDeclarationforOperationGroup($operationGroupId: Int!) {
    fireConsultantCancelDeclarationforOperationGroup(operationGroupId: $operationGroupId) {
      id
      operations {
        id
        operationType
        statutId
      }
    }
  }
`;

const operationGroupInfos = gql`
  query operationGroupInfos($id: Int) {
    allOperationsPaginated(skip: 0, take: 1, where: { id: { eq: $id } }) {
      items {
        operationGroup {
          id
          operations {
            id
            operationConfig {
              natureOperation {
                libelle
              }
            }
            montant
            coInvestisseurPreset {
              id
              nom
              prenom
            }
            investisseurPreset {
              id
              nom
              prenom
            }
            produitPreset {
              enveloppeLibelle
            }
          }
        }
      }
    }
  }
`;
@Component({
  selector: 'app-cancel-declaration-confirm',
  templateUrl: './cancel-declaration-confirm.component.html',
  styleUrls: ['./cancel-declaration-confirm.component.scss'],
})
export class CancelDeclarationConfirmComponent {
  readonly QUESTION_PLURAL: string = 'Êtes-vous sûr de vouloir annuler la déclaration des opérations suivantes ?';
  readonly QUESTION_SINGULAR: string = "Êtes-vous sûr de vouloir annuler la déclaration de l'opération suivante ?";

  readonly STATEMENT_PLURAL: string =
    'Vos opérations ne seront pas traitées tant que vous ne les aurez pas déclarées à nouveau.';
  readonly STATEMENT_SINGULAR: string =
    "Votre opération ne sera pas traitée tant que vous ne l'aurez pas déclaré à nouveau.";

  readonly FRAME_LABEL_PLURAL: string = 'Opérations concernées :';
  readonly FRAME_LABEL_SINGULAR: string = 'Opération concernée :';

  confirmationQuestion: string = '';
  confirmationStatement: string = '';
  frameLabel: string = '';
  investisseurLabel: string = '';
  operationInfos: string[] = [];
  operationId: number;
  operationGroupId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { operationId: number },
    private dialogRef: MatDialogRef<CancelDeclarationConfirmComponent>,
    private queryManager: QueryManagerService
  ) {
    this.operationId = this.data.operationId;
  }

  ngOnInit(): void {
    this.asyncInit();
  }

  async getOperationGroupInfos() {
    if (this.operationId) {
      const result = await firstValueFrom(
        this.queryManager.query<{
          allOperationsPaginated: OperationsPaginatedCollectionSegment;
        }>({
          query: operationGroupInfos,
          variables: {
            id: this.operationId,
          },
        })
      );

      if (result.data.allOperationsPaginated.items && result.data.allOperationsPaginated.items.length > 0) {
        const operationItem = result.data.allOperationsPaginated.items[0];
        if (operationItem.operationGroup.operations) {
          return operationItem.operationGroup;
        }
      }
    }
    return null;
  }

  setDisplayedData(operations: Operation[]) {
    if (operations && operations.length > 0) {
      this.setInvestisseurLabel(operations[0]);
      this.operationInfos = operations.map<string>(operation => this.getOperationInfos(operation));
      if (operations.length > 1) {
        this.confirmationQuestion = this.QUESTION_PLURAL;
        this.confirmationStatement = this.STATEMENT_PLURAL;
        this.frameLabel = this.FRAME_LABEL_PLURAL;
      } else {
        this.confirmationQuestion = this.QUESTION_SINGULAR;
        this.confirmationStatement = this.STATEMENT_SINGULAR;
        this.frameLabel = this.FRAME_LABEL_SINGULAR;
      }
    }
  }

  async asyncInit() {
    const operationGroup = await this.getOperationGroupInfos();
    if (operationGroup?.operations) {
      this.operationGroupId = operationGroup.id;
      this.setDisplayedData(operationGroup.operations);
    }
  }

  setInvestisseurLabel(operation: Operation) {
    this.investisseurLabel = operation.investisseurPreset?.nom + ' ' + operation.investisseurPreset?.prenom;
    if (operation.coInvestisseurPreset) {
      this.investisseurLabel =
        this.investisseurLabel +
        ' & ' +
        operation.coInvestisseurPreset.nom +
        ' ' +
        operation.coInvestisseurPreset.prenom;
    }
  }

  getOperationInfos(operation: Operation): string {
    const operationNature = operation.operationConfig?.natureOperation?.libelle ?? 'Nature non renseignée';
    const operationEnveloppe = operation?.produitPreset?.enveloppeLibelle ?? 'Enveloppe non renseignée';
    const operationMontant = operation?.montant?.toFixed(2).replace('.', ',') ?? '0';
    return `${operationNature} - ${operationEnveloppe} - ${operationMontant} €`;
  }

  async onConfirmCancelDeclaration() {
    if (this.operationGroupId) {
      const result = await firstValueFrom(
        this.queryManager.mutate<{
          fireConsultantCancelDeclarationforOperationGroup: OperationGroup;
        }>({
          mutation: fireConsultantCancelDeclarationforOperationGroup,
          variables: {
            operationGroupId: this.operationGroupId,
          },
        })
      );
      const cancelledOperations = result?.data?.fireConsultantCancelDeclarationforOperationGroup?.operations;
      if (cancelledOperations) {
        this.dialogRef.close(cancelledOperations);
      }
    }
  }
}
