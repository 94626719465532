import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Investisseur, InvestisseurType } from '@lib/models/generated/graphql';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiButtonToggleOption } from 'omnium-ui/button-toggle';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { Subscription } from 'rxjs';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { CreationPersonneMoraleConsultantDialogComponent } from './creation-personne-morale-consultant-dialog/creation-personne-morale-consultant-dialog.component';
import { InvestisseurDuplicatesDialogComponent } from './investisseur-duplicates-dialog/investisseur-duplicates-dialog.component';
const DUPLICAT_INV = gql`
  query InvestisseurAndDuplicatesById($investisseurId: Long!) {
    InvestisseurAndDuplicatesById(investisseurId: $investisseurId) {
      id
      code
      creationDate
      statutCode
      statutCodeNavigation {
        libelle
        actif
      }
      investisseurEntite {
        displayName
        personnePhysique {
          dateNaissance
          prenom
          nom
          genre
        }
      }
    }
  }
`;
@Component({
  selector: 'app-investisseur-step-declaration',
  templateUrl: './investisseur-step-declaration.component.html',
  styleUrls: ['./investisseur-step-declaration.component.scss'],
})
export class InvestisseurStepDeclarationComponent {
  typeInv = InvestisseurType;

  investisseurTypeTogglecontrol = new FormControl<number>(1);
  investisseurTypeOptions: OuiButtonToggleOption[] = [
    { value: 1, label: 'Personne physique' },
    { value: 2, label: 'Personne morale' },
  ];
  toogleInvestisseurSubscription: Subscription;

  searchInvestisseurLabel: string = 'Investisseur';

  bannerAction: OuiBannerAction = {
    label: "Consulter l'article",
    action: () => {
      const url =
        'https://prodemial.zendesk.com/hc/fr/articles/15581559789852-Souscriptions-en-d%C3%A9membrement-Viager-ou-temporaire-avec-contrepartie-connue';
      window.open(url, '_blank');
    },
  };

  constructor(
    protected draftOperationsService: DraftOperationsService,
    private dialogService: OuiDialogService,
    private snackbarService: OuiSnackbarService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    if (this.toogleInvestisseurSubscription) {
      this.toogleInvestisseurSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.investisseurTypeTogglecontrol.setValue(
      this.draftOperationsService.investisseur?.investisseurEntite?.type === InvestisseurType.PersonneMorale ? 2 : 1
    );

    this.toogleInvestisseurSubscription = this.investisseurTypeTogglecontrol.valueChanges.subscribe(value => {
      switch (value) {
        case 1: {
          //personne physique
          this.draftOperationsService.investisseurType = InvestisseurType.PersonnePhysique;
          break;
        }
        case 2: {
          //personne morale
          this.draftOperationsService.withCoInvestisseur = false;
          this.draftOperationsService.coInvestisseur = undefined;
          this.draftOperationsService.investisseurType = InvestisseurType.PersonneMorale;
          break;
        }
      }
    });

    if (this.draftOperationsService.getMaxOperationsStateId() >= 5) {
      this.investisseurTypeOptions.forEach(opt => {
        opt.disabled = true;
      });
    }
  }

  async onInvestisseurSelected(investisseur: Investisseur | null | undefined) {
    if (!investisseur?.id) {
      this.draftOperationsService.investisseur = undefined;
      return;
    }

    if (this.draftOperationsService.investisseurType === InvestisseurType.PersonneMorale) {
      this.draftOperationsService.investisseur = investisseur;
      this.draftOperationsService.fetchAndUpdateInvestisseurData(investisseur.id);
      return;
    }
    const potentialDuplicates = deepCopy(await this.getInvestisseurAndDuplicatesById(investisseur.id));

    if (potentialDuplicates.length === 1) {
      //no duplicates
      this.draftOperationsService.investisseur = investisseur;
      this.draftOperationsService.fetchAndUpdateInvestisseurData(investisseur.id);
    }
    if (potentialDuplicates.length > 1) {
      // potential duplicates
      const modalRef = this.dialogService.openDialog(
        InvestisseurDuplicatesDialogComponent,
        {
          duplicates: potentialDuplicates,
          preSelectedId: investisseur.id,
        },
        'auto',
        '430px'
      );

      modalRef.afterClosed().subscribe(result => {
        if (result?.id) {
          this.draftOperationsService.investisseur = result;
          this.draftOperationsService.fetchAndUpdateInvestisseurData(result.id);
        } else {
          this.draftOperationsService.investisseur = investisseur;
          this.draftOperationsService.fetchAndUpdateInvestisseurData(investisseur.id);
        }
      });
    }
  }

  async onCoInvestisseurSelected(coInvestisseur: Investisseur | null | undefined) {
    if (!coInvestisseur?.id) {
      this.draftOperationsService.coInvestisseur = undefined;
      return;
    }
    const potentialDuplicates = deepCopy(await this.getInvestisseurAndDuplicatesById(coInvestisseur.id));
    if (potentialDuplicates.length === 1) {
      //no duplicates
      this.draftOperationsService.coInvestisseur = coInvestisseur;
      this.draftOperationsService.fetchAndUpdateInvestisseurData(coInvestisseur.id, true);
    }
    if (potentialDuplicates.length > 1) {
      // potential duplicates
      const modalRef = this.dialogService.openDialog(
        InvestisseurDuplicatesDialogComponent,
        {
          duplicates: potentialDuplicates,
          preSelectedId: coInvestisseur.id,
        },
        'auto',
        '430px'
      );

      modalRef.afterClosed().subscribe(result => {
        if (result?.id) {
          this.draftOperationsService.coInvestisseur = result;
          this.draftOperationsService.fetchAndUpdateInvestisseurData(result.id, true);
        } else {
          this.draftOperationsService.coInvestisseur = coInvestisseur;
          this.draftOperationsService.fetchAndUpdateInvestisseurData(coInvestisseur.id, true);
        }
      });
    }
  }

  getInvestisseurAndDuplicatesById(investisseurId: number) {
    return this.draftOperationsService.getInvestisseurAndDuplicatesById(investisseurId);
  }

  onCoInvestisseurToggled() {
    this.draftOperationsService.withCoInvestisseur = !this.draftOperationsService.withCoInvestisseur;
    if (!this.draftOperationsService.withCoInvestisseur) {
      this.draftOperationsService.coInvestisseur = undefined;
    }
  }

  onDemandeCreationClick() {
    const modalRef = this.dialogService.openDialog(
      CreationPersonneMoraleConsultantDialogComponent,
      {},
      'auto',
      '560px'
    );

    modalRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.snackbarService.open('Demande de création envoyée', 'success', 7000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
        this.router.navigate(['operations']);
      }
    });
  }
}
