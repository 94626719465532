import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CreateCreationPersonneMoraleInput, CreationPersonneMorale, FormeJuridique, InvestisseurType } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { firstValueFrom } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const FORMESJURIDIQUES = gql`
  query formeJuridiques {
    formeJuridiques(where: { type: { eq: 1 } }) {
      #1: Investisseurs
      code
      libelle
    }
  }
`;

const CREATION = gql`
  mutation createCreationPersonneMorales($input: CreateCreationPersonneMoraleInput!) {
    createCreationPersonneMorales(input: $input) {
      id
    }
  }
`;

@Component({
  selector: 'app-creation-personne-morale-consultant-dialog',
  templateUrl: './creation-personne-morale-consultant-dialog.component.html',
  styleUrls: ['./creation-personne-morale-consultant-dialog.component.scss'],
})
export class CreationPersonneMoraleConsultantDialogComponent {
  investisseurType = InvestisseurType;
  raisonSocialeControl = new FormControl<string>('', [Validators.required]);

  physiqueInvestisseurId: number | null | undefined;

  formeJuridiqueControl = new FormControl<number | null>(null, [Validators.required]);
  formeJuridiqueOptions: OuiSelectOption[] = [];

  constructor(private queryManager: QueryManagerService, private dialog: MatDialogRef<CreationPersonneMoraleConsultantDialogComponent>) {}



  ngOnInit(): void {
    this.iniFormesJuridiques();
  }

  async iniFormesJuridiques() {
    const result = await firstValueFrom(
      this.queryManager.query<{ formeJuridiques: FormeJuridique[] }>({
        query: FORMESJURIDIQUES,
      })
    );
    const formes = result.data?.formeJuridiques;
    if (formes) {
      this.formeJuridiqueOptions = formes
        .map((f: FormeJuridique) => ({ label: f.libelle, value: f.code }))
        .sort((a, b) => {
          if (a.label === 'Autres') return 1;
          if (b.label === 'Autres') return -1;
          return a.label.localeCompare(b.label);
        });
    }
  }

  onInvestisseurIdSelected(investisseurId: number | null | undefined) {
    this.physiqueInvestisseurId = investisseurId;
  }

  onSend() {
    if(this.physiqueInvestisseurId&&this.raisonSocialeControl.value&&this.formeJuridiqueControl.value){
      const input: CreateCreationPersonneMoraleInput = {
        investisseurPhysiqueId: this.physiqueInvestisseurId,
        raisonSociale: this.raisonSocialeControl.value,
        formeSocialeId: this.formeJuridiqueControl.value
      }
      this.queryManager
        .mutate<{ createCreationPersonneMorales: CreationPersonneMorale }>({
          mutation: CREATION,
          variables:  {input} ,
        })
        .subscribe((result) => {
          if(result.data?.createCreationPersonneMorales){
            this.dialog.close(result.data?.createCreationPersonneMorales);
          }
        });
      
    }

  }

}
