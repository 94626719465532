import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BoxDocumentViewerService } from '@lib/components/document-viewer/document-viewer-panel/document-viewer-panel.service';
import {
  AddArianeDocumentViewerComponent,
  AddArianeResult,
} from '@lib/components/pdf-viewer/add-ariane/add-ariane-document-viewer.component';
import {
  AddUploadDocumentViewerComponent,
  AddUploadResult,
} from '@lib/components/pdf-viewer/add-upload/add-upload-document-viewer.component';
import { FichierOperation, Investisseur, NatureDocumentRequi, Operation } from '@lib/models/generated/graphql';
import { LocalFileInfo } from '@lib/models/LocalFileInfo';
import { MissingFichierOperation, isMissingFichierOperation } from '@lib/models/types';
import { DocumentsService } from '@lib/services/documents.service';
import { notEmpty } from '@lib/utils/codeutils';
import { fileLabel } from '@lib/utils/fichierOperationLabel';
import { openFilePicker } from '@lib/utils/filesUtils';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';

export interface FileInfo {
  fichierOperation?: FichierOperation | MissingFichierOperation;
  comment?: string;
  denomination?: string;
  fileName: string;
  label: string;
  isMissing: boolean;
  isUploading?: boolean;
  menu?: any;
}

export interface SelectedFileConfig {
  fileId: string;
  natureDocumentRequisId?: number;
  comment?: string;
  denomination?: string;
  isOutOfConfig: boolean;
  investisseurId: number;
  forceInAllOperations: boolean;
}

export interface UploadingFile {
  isUploading: boolean;
  fileName: string;
  typeLibelle?: string;
  comment?: string;
  denomination?: string;
}

export const DEFAULT_ID_FOR_MISSING_DOC = -1;

@Component({
  selector: 'app-souscription-file-items',
  templateUrl: './souscription-file-items.component.html',
  styleUrls: ['./souscription-file-items.component.scss'],
})
export class SouscriptionFileItemsComponent {
  @Input()
  itemsCategory: 'operation' | 'investisseur' | 'co-investisseur' | 'conseil' = 'operation';

  @Input()
  operation: Operation;

  @Input()
  operationIndex: number;

  @Input()
  set fichierOperations(value: (FichierOperation | MissingFichierOperation)[]) {
    this.updateFileInformationFromFichierOperation(value ?? []);
  }

  @Input()
  investisseur?: Investisseur;

  @Input()
  withGlobalAddFilesButton: boolean = false;

  @Input()
  showBannerConnaissanceClient: boolean = false;

  @Input()
  showBannerSouscription: boolean = false;

  uploadingFiles: UploadingFile[] = [];
  defaultMenu = this.createAddFileMenuItems();

  uploadProgress = 0;

  @Output()
  onAddedFile: EventEmitter<SelectedFileConfig[]> = new EventEmitter<SelectedFileConfig[]>();
  @Output()
  onRemoveFile: EventEmitter<FichierOperation> = new EventEmitter<FichierOperation>();

  fileInfos: FileInfo[] = [];

  uploadInProgress = false;

  uploadIndex: number | undefined;
  isGlobalUpload: boolean = false;
  isGlobalUploadInProgress: boolean = false;
  categoryNatureDocumentRequiId: number;
  investisseurs: Investisseur[] = [];

  constructor(
    private dialog: MatDialog,
    private documentService: DocumentsService,
    protected draftOperationService: DraftOperationsService,
    private snackbarService: OuiSnackbarService,
    private documentViewerService: BoxDocumentViewerService
  ) {}

  ngOnInit() {
    this.buildInvestisseurs();
  }
  buildInvestisseurs() {
    this.investisseurs = [];
    if (this.draftOperationService.investisseur) {
      this.investisseurs.push(this.draftOperationService.investisseur);
    }
    if (this.draftOperationService.coInvestisseur) {
      this.investisseurs.push(this.draftOperationService.coInvestisseur);
    }
  }
  createFileInfo(fileOperation: FichierOperation | MissingFichierOperation): FileInfo {
    if (isMissingFichierOperation(fileOperation)) {
      return {
        fichierOperation: fileOperation,
        menu: this.createAddFileMenuItems(fileOperation.natureDocumentRequis),
        fileName: '',
        label: fileOperation.natureDocumentRequis?.natureDocument?.nom ?? 'Autre Document',
        isMissing: true,
        isUploading: false,
      };
    } else {
      return {
        fichierOperation: fileOperation,
        menu: null,
        fileName: fileOperation?.metadata?.fileName ?? '',
        label: fileLabel(fileOperation),
        isMissing: false,
        isUploading: false,
      };
    }
  }

  updateFileInformationFromFichierOperation(fichierOperation: (FichierOperation | MissingFichierOperation)[]) {
    //remove file info from previous fichier operation
    this.isGlobalUploadInProgress = false;
    this.fileInfos = this.fileInfos.filter(fi => fi.fichierOperation === undefined);

    //add new files Info
    this.fileInfos.push(...fichierOperation.map(fo => this.createFileInfo(fo)));
  }

  createAddFileMenuItems(natureDocumentRequis?: NatureDocumentRequi) {
    return [
      {
        name: 'Depuis mon ordinateur',
        icon: 'upload_file',
        action: () => {
          openFilePicker(
            'target_div',
            (files: File[]) => {
              if (!files.every(file => this.documentService.isAuthorizedConsultantFile(file))) {
                this.snackbarService.open('Format ou poids de fichier non autorisé.', 'error', 5000, {
                  horizontal: 'left',
                  vertical: 'bottom',
                });
                return;
              }
              const dialogRef = this.dialog.open(AddUploadDocumentViewerComponent, {
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%',
                panelClass: 'full-screen-modal',
                autoFocus: false,
                data: {
                  files,
                  natureDocumentRequi: natureDocumentRequis,
                  typeSelectable: true,
                  typeLabel: natureDocumentRequis?.natureDocument?.nom,
                  investisseurs: this.investisseurs,
                  showAllOperationsOption:
                    this.draftOperationService.operationGroupData.operations.length > 1 &&
                    this.itemsCategory === 'operation',
                },
              });

              dialogRef.afterClosed().subscribe(async (result: AddUploadResult) => {
                if (Array.isArray(result?.filesInfo) && result?.filesInfo?.length > 0) {
                  // we have some file to upload to server in a first step and then we return fileId to create fichier Operation.
                  const localFileInfos: LocalFileInfo[] = result.filesInfo;
                  const fileIdToFileInfo = new Map<string, LocalFileInfo>();

                  const investisseurId =
                    localFileInfos[0].investisseurId ??
                    this.investisseur?.id ??
                    this.draftOperationService.investisseur?.id;

                  // set spinner if we are uploading
                  this.displaySpinner();
                  const fileIds = await Promise.all(
                    localFileInfos.map(async lfi => {
                      if (investisseurId) {
                        var fileId = await this.documentService.uploadFile(
                          lfi.file,
                          investisseurId,
                          lfi.boxDocumentType ?? natureDocumentRequis?.natureDocument?.boxDocumentType,
                          lfi.denomination
                        );
                        if (fileId) {
                          fileIdToFileInfo.set(fileId, lfi);
                        }
                        return fileId;
                      }
                      return;
                    })
                  );

                  //refresh investisseur files after upload
                  if (investisseurId && fileIds?.length > 0) {
                    await this.draftOperationService.refreshInvestisseurFilesData(investisseurId);
                    this.buildInvestisseurs();
                  }
                  const ndrForFile = natureDocumentRequis?.id ?? this.categoryNatureDocumentRequiId;
                  //TODO : gérer les cas d'erreur lors de l'upload
                  const outputData: SelectedFileConfig[] = fileIds.filter(notEmpty).map(fileId => ({
                    fileId,
                    natureDocumentRequisId: natureDocumentRequis?.id ?? this.categoryNatureDocumentRequiId,
                    denomination:
                      fileIdToFileInfo.get(fileId)?.boxDocumentType?.key ??
                      natureDocumentRequis?.natureDocument?.boxDocumentType?.key,
                    comment: fileIdToFileInfo.get(fileId)?.comment,
                    isOutOfConfig: ndrForFile === this.categoryNatureDocumentRequiId,
                    investisseurId: this.investisseurIdAccordingToCategory() ?? investisseurId,
                    forceInAllOperations: !!result.forAllOperations,
                  }));
                  this.resetAddFilesClicked();
                  this.onAddedFile.emit(outputData);
                }
              });
            },
            !natureDocumentRequis,
            true
          );
        },
      },
      {
        name: 'Depuis mes documents Ariane',
        icon: 'search',
        action: () => {
          this.buildInvestisseurs();
          const dialogRef = this.dialog.open(AddArianeDocumentViewerComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-modal',
            data: {
              investisseurs: this.investisseurs,
              natureDocumentRequis,
              multiple: this.isGlobalUpload,
              showAllOperationsOption:
                this.draftOperationService.operationGroupData.operations.length > 1 &&
                this.itemsCategory === 'operation',
            },
          });
          dialogRef.afterClosed().subscribe((resultData: AddArianeResult) => {
            //if a file has been selected, add create a fichier Operation

            const ndrForFile = natureDocumentRequis?.id ?? this.categoryNatureDocumentRequiId;
            const fileInfoList = resultData?.filesInfo;
            if (fileInfoList && Array.isArray(fileInfoList) && fileInfoList.length > 0) {
              this.displaySpinner();
              const outputData: SelectedFileConfig[] = fileInfoList.map(fi => ({
                fileId: fi.metadata.fileId!,
                natureDocumentRequisId: ndrForFile,
                investisseurId: this.investisseurIdAccordingToCategory() ?? fi.metadata.investisseurId,
                comment: fi.comment,
                isOutOfConfig: ndrForFile === this.categoryNatureDocumentRequiId,
                forceInAllOperations: !!resultData.forAllOperations,
              }));
              this.resetAddFilesClicked();
              this.onAddedFile.emit(outputData);
            }
          });
        },
      },
    ];
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
  }

  displayFile(file: FileInfo) {
    if (file.fichierOperation && !isMissingFichierOperation(file.fichierOperation)) {
      this.documentViewerService.openAppendedDocumentViewer(
        file.fichierOperation,
        result => {
          if (result?.fichierOperation?.id && typeof result.comment === 'string') {
            this.draftOperationService.updateFichierOperationCommentaire(
              result.fichierOperation?.id,
              result.comment ?? ''
            );
          }
        },
        async removeEvent => {
          if (removeEvent.fichierOperationToRemove?.id) {
            await this.draftOperationService.removeFichierOperationToCurrentGroup(removeEvent.fichierOperationToRemove);
            this.onRemoveFile.emit();
          }
        }
      );
    }
  }

  // set the index of the uploading file
  onRequisAddFiles(indx: number) {
    this.uploadIndex = indx;
    this.isGlobalUpload = false;
  }

  onGlobalAddFiles() {
    this.uploadIndex = undefined;
    this.isGlobalUpload = true;
  }

  // display the spinner in the corresponding uploading file or on the global upload
  displaySpinner() {
    if (this.uploadIndex && this.fileInfos[this.uploadIndex]) {
      this.fileInfos[this.uploadIndex].isUploading = true;
    }
    if (this.isGlobalUpload) {
      this.isGlobalUploadInProgress = true;
    }
  }
  resetAddFilesClicked() {
    this.uploadIndex = undefined;
    this.isGlobalUpload = false;
  }

  investisseurIdAccordingToCategory(): number | undefined {
    switch (this.itemsCategory) {
      case 'investisseur':
        return this.draftOperationService.investisseur?.id;
      case 'co-investisseur':
        return this.draftOperationService.coInvestisseur?.id;
      case 'conseil':
        return this.draftOperationService.investisseur?.id;
      default:
        // for operation we check that we clicked on a required file
        if (this.uploadIndex !== undefined && this.uploadIndex < this.fileInfos.length) {
          return this.fileInfos[this.uploadIndex].fichierOperation?.estCoInvestisseur
            ? this.draftOperationService.coInvestisseur?.id
            : this.draftOperationService.investisseur?.id;
        }
        return undefined;
    }
  }
}
