<div oui-dialog-title>
  <ng-container *ngIf="!isLoading">Avez-vous bien ajouté tous les documents ?</ng-container>
  <ng-container *ngIf="isLoading">Déclaration en cours</ng-container>
  <oui-button type="icon" *ngIf="!isLoading" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <ng-container *ngIf="isLoading">
    <oui-loading class="loader" size="small"></oui-loading>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <div [innerHTML]="message"></div>

    <oui-banner
      title="Vous n'avez pas besoin d'envoyer un email à Stellium Placement après votre déclaration"
      [showCloseButton]="false"></oui-banner>
  </ng-container>
</oui-dialog-content>

<oui-dialog-actions *ngIf="!isLoading">
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="declareOperation()">Déclarer</oui-button>
</oui-dialog-actions>
