import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { QueryManagerService } from './queryManagerService';
import { lastValueFrom } from 'rxjs';
import {
  BackOfficeService,
  EnveloppeProduit,
  EnveloppeProduitFilterInput,
  Produit,
  ProduitFilterInput,
} from '../models/generated/graphql';

const allEnveloppesQuery = gql`
  query allEnveloppeProduits($where: EnveloppeProduitFilterInput) {
    allEnveloppeProduits(where: $where) {
      code
      libelle
      backOfficeService
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EnveloppeProduitService {
  private _enveloppeProduitSVPs: number[];

  constructor(private queryManager: QueryManagerService) {}

  async getEnveloppes(where?: EnveloppeProduitFilterInput): Promise<EnveloppeProduit[]> {
    const result = await lastValueFrom(
      this.queryManager.query<{ allEnveloppeProduits: EnveloppeProduit[] }>({
        query: allEnveloppesQuery,
        variables: {
          where: where,
        },
      })
    );

    return result.data.allEnveloppeProduits;
  }

  async getEnveloppeSVPs(): Promise<number[]> {
    if (!this._enveloppeProduitSVPs || this._enveloppeProduitSVPs.length === 0) {
      const envResult = await lastValueFrom(
        this.queryManager.query<{ allEnveloppeProduits: EnveloppeProduit[] }>({
          query: allEnveloppesQuery,
        })
      );
      if (envResult.data?.allEnveloppeProduits) {
        this._enveloppeProduitSVPs = envResult.data.allEnveloppeProduits
          .filter(env => env.backOfficeService === BackOfficeService.Svp)
          .map(e => e.code);
      } else {
        this._enveloppeProduitSVPs = [];
      }
    }
    return this._enveloppeProduitSVPs;
  }
}
