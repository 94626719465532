<oui-layout [logoUrl]="logoUrl" [menuPosition]="'inner'">
  <oui-header *ngIf="displayHeader" [userMenu]="userMenu" [userName]="userName" logoLink="">
    <oui-header-actions>
      <oui-nav-button iconPrefix="help_outline" (click)="onHelpClick()">Aide & Contact</oui-nav-button>
    </oui-header-actions>
  </oui-header>

  <oui-content-layout>
    <router-outlet> </router-outlet>

    <ng-template #documentViewerPortalContent>
      <lib-document-viewer-panel></lib-document-viewer-panel>
    </ng-template>
  </oui-content-layout>
</oui-layout>
