<div class="flex-vertical gap-32">
  <app-montant-operation
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"
    [isRequired]="true"></app-montant-operation>
  <app-type-signature-partenaire
    [operation]="operation"
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-type-signature-partenaire>
  <app-type-taux-frais (onSpecificDataChanged)="onDataChange($event)" [initialData]="initialData"></app-type-taux-frais>

  <app-specific-comment
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-specific-comment>
</div>
