import { Component, Input } from '@angular/core';
import { FondsEvenementielsService } from '@lib/services/fonds-evenementiels.service';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { AbstractSpecificFieldComponent } from '../abstract-specific-field.component';

const FONDS_IDS = 'fondEvenementielIds';

@Component({
  selector: 'app-fonds-evenementiels',
  templateUrl: './fonds-evenementiels.component.html',
  styleUrls: ['./fonds-evenementiels.component.scss'],
})
export class FondsEvenementielsComponent extends AbstractSpecificFieldComponent<string> {
  // required input for this component
  @Input({ required: true }) override operationIndex: number;

  options: OuiSelectOption<number>[] = [];
  productId: number;

  override getDataName(): string {
    return FONDS_IDS;
  }
  override wrapData(value: string): string {
    return value;
  }
  constructor(
    private draftOperationsService: DraftOperationsService,
    private fondsEvenementielsService: FondsEvenementielsService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.operationIndex !== undefined) {
      this.productId =
        this.draftOperationsService.operationGroupData.operations[this.operationIndex]?.produitId ??
        this.draftOperationsService.operationGroupData.operations[this.operationIndex!].produit?.id ??
        0;
    }
    this.getfondEvenementielsByProductId();
  }

  override isFormValid(): boolean {
    if (this.control.disabled) {
      return true;
    }
    return this.control.valid;
  }

  async getfondEvenementielsByProductId() {
    const fondsEvenementiels = await this.fondsEvenementielsService.getfondEvenementielsByProductId(this.productId);

    this.options = fondsEvenementiels.map(fondEvenementiel => ({
      label: fondEvenementiel.libelle,
      value: fondEvenementiel.id,
    }));
    if (this.options.length < 1) {
      this.control.disable();
    }
  }
}
