<div class="flex-vertical width-100pc gap-16">
  <oui-select
    label="Comment ont été signés les documents du partenaire ? (hors documents Stellium)"
    [control]="signatureControl"
    [options]="signatureOptions"
    class="width-100pc"></oui-select>

  <oui-select
    *ngIf="showTransmissionField"
    label="Transmission des originaux"
    [control]="transmissionControl"
    [options]="transmissionListOptions"
    class="width-100pc"></oui-select>

  <oui-banner
    *ngIf="transmissionControl.value === 'envoiPostal'"
    title="Votre opération sera traitée dès la réception des documents originaux que vous devez également déposer en version numérique à l'étape suivante."
    declination="alert"
    [showCloseButton]="false"></oui-banner>

  <oui-banner
    *ngIf="transmissionControl.value === 'pasEnvoiPostal'"
    title="Vous devez déposer tous les documents en version numérique à l'étape suivante."
    declination="alert"
    [showCloseButton]="false"></oui-banner>
</div>
