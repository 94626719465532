import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiTextarea } from 'omnium-ui/form-field';
import { ConsultantReponseService } from 'src/service/consultant-reponse.service';

@Component({
  selector: 'app-consultant-reponse-commentaire',
  standalone: true,
  imports: [CommonModule, OuiTextarea, OuiButtonModule],
  templateUrl: './consultant-reponse-commentaire.component.html',
  styleUrls: ['./consultant-reponse-commentaire.component.scss'],
})
export class ConsultantReponseCommentaireComponent {
  @Output() onCancelClicked = new EventEmitter<any>();
  @Output() onValidClicked = new EventEmitter<any>();

  // commentaire form
  commentaireControl: FormControl;
  commentaireFormLabel: string;

  constructor(protected reponseService: ConsultantReponseService) {
    this.commentaireControl = this.reponseService.commentaireNCControl;
    this.commentaireFormLabel = this.reponseService.commentaireNCFormLabel;
  }

  // Commentaire optionel = ajout d'un fichier
  get buttonText(): string {
    return this.commentaireControl.hasValidator(Validators.required) ? 'Répondre' : 'Valider';
  }

  onCancelComment() {
    this.onCancelClicked.emit();
  }

  onValidComment() {
    this.reponseService.commentaireNCReponse = this.commentaireControl.value;
    this.onValidClicked.emit();
  }
}
