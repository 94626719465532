<div oui-dialog-title>
  Doublon sélectionné ?
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content class="flex-vertical gap-16">
  <div class="body-1">
    Attention, vous avez sélectionné un investisseur qui semble être en doublon dans votre portefeuille. Merci de
    confirmer votre sélection.
  </div>
  <oui-radio-button-group [control]="duplicateControl" direction="vertical">
    <oui-radio-button *ngFor="let option of duplicateOptions" [value]="option.value"
      >{{ option.label }}
    </oui-radio-button>
  </oui-radio-button-group>
  <div class="body-1">S’il y a bien un doublon, nous vous recommandons de le signaler au service support.</div>
  <a (click)="openNewTab(link)">Signaler le doublon</a>
</oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close> Annuler </oui-button>
  <oui-button type="contained" (click)="onValidate()"> Valider </oui-button>
</oui-dialog-actions>
