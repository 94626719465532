<div class="flex-horizontal width-100pc flex-center gap-8">
  <oui-select
    label="Objet de la modification"
    [control]="control"
    [options]="options"
    class="width-100pc"
    [multiple]="true"></oui-select>
  <oui-infos-bulle bulleTitle="Objet de la modification">
    Si baisse de frais, merci de préciser en commentaire si la baisse impacte votre lignée, et le cas échéant fournir le
    document "Accord lignée" à l'étape suivante.
  </oui-infos-bulle>
</div>
