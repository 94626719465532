<oui-autocomplete
  *ngIf="draftOperationsService.operationGroupData.opertionType === operationTypeEnum.Souscription"
  label="Enveloppe"
  [control]="enveloppeControl"
  [options]="enveloppeAutocompleteOptions"
  class="width-100pc"></oui-autocomplete>

<app-transaction-personnelle
  [habilitationIds]="selectableProduitHabiliationIds"
  [initValue]="draftOperationsService.operationGroupData.transactionPersonnelleStatut"
  (onTransactionPersonnelleChange)="updateTransactionPersonelleStatut($event)"></app-transaction-personnelle>

<ng-container
  *ngIf="
    draftOperationsService.operationGroupData.opertionType === operationTypeEnum.ActeDeGestion || selectedEnveloppeId
  ">
  <app-operation-information-form
    *ngFor="let operation of draftOperationsService.operationGroupData.operations; let index = index"
    [operation]="operation"
    [index]="index"
    [expanded]="index === draftOperationsService.operationGroupData.operations.length - 1"
    [deleteDisabled]="draftOperationsService.operationGroupData.operations.length <= 1"
    [selectableProduits]="selectableProduits"
    (onSpecificDataChanged)="onSpecificDataChange($event, index)"
    (onOperationConfigChanged)="onOperationConfigChanged($event, index)"
    (onProductChanged)="onProductChanged($event, index)"
    (onDeleteClicked)="deleteOperation($event)">
  </app-operation-information-form>
  <div class="flex-none">
    <oui-button
      type="outlined"
      (click)="addNewOperationInGroup()"
      *ngIf="draftOperationsService.operationGroupData.operations[0]?.operationConfig?.allowMultiple">
      {{ addButtonLabel }}
    </oui-button>
  </div>
</ng-container>
