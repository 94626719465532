import { gql } from 'apollo-angular';

export const fetchInvestisseurFileDataQuery = gql`
  query investisseurById($id: Long!) {
    investisseurById(id: $id) {
      id
      code
      filesMetadata {
        actif
        extension
        fileId
        denomination
        typeDocumentCode
        etatSignature
        dateDeDerniereModification
        fileName
        typeLibelle
        extension
        investisseurId
      }
    }
  }
`;
