import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Omnium UI
import { OuiAccordionModule } from 'omnium-ui/accordion';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiButtonToggle } from 'omnium-ui/button-toggle';
import { OuiAutocomplete, OuiCheckbox, OuiSelect, OuiTextarea, OuiTextfield } from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiInfosBulle } from 'omnium-ui/infos-bulle';
import { OuiLayoutModule, OuiNavButtonModule, OuiNavigationPanelModule } from 'omnium-ui/layout';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiPaginatorModule } from 'omnium-ui/paginator';
import { OuiSelectFilter } from 'omnium-ui/select-filter';
import { OuiSlideToggle } from 'omnium-ui/slide-toggle';
import { OuiStepper } from 'omnium-ui/stepper';
import { OuiTableModule } from 'omnium-ui/table';
import { OuiTabsModule } from 'omnium-ui/tabs';
import { OuiTagModule } from 'omnium-ui/tag';

import { SpecificDataConf1Component } from './ope-config/specific-data-conf1/specific-data-conf1.component';
import { MontantOperationComponent } from './shared/montant-operation/montant-operation.component';

import { OuiBanner } from 'omnium-ui/banner';
import { SpecificDataConf10Component } from './ope-config/specific-data-conf10/specific-data-conf10.component';
import { SpecificDataConf11Component } from './ope-config/specific-data-conf11/specific-data-conf11.component';
import { SpecificDataConf12Component } from './ope-config/specific-data-conf12/specific-data-conf12.component';
import { SpecificDataConf13Component } from './ope-config/specific-data-conf13/specific-data-conf13.component';
import { SpecificDataConf14Component } from './ope-config/specific-data-conf14/specific-data-conf14.component';
import { SpecificDataConf15Component } from './ope-config/specific-data-conf15/specific-data-conf15.component';
import { SpecificDataConf16Component } from './ope-config/specific-data-conf16/specific-data-conf16.component';
import { SpecificDataConf17Component } from './ope-config/specific-data-conf17/specific-data-conf17.component';
import { SpecificDataConf18Component } from './ope-config/specific-data-conf18/specific-data-conf18.component';
import { SpecificDataConf19Component } from './ope-config/specific-data-conf19/specific-data-conf19.component';
import { SpecificDataConf2Component } from './ope-config/specific-data-conf2/specific-data-conf2.component';
import { SpecificDataConf20Component } from './ope-config/specific-data-conf20/specific-data-conf20.component';
import { SpecificDataConf21Component } from './ope-config/specific-data-conf21/specific-data-conf21.component';
import { SpecificDataConf22Component } from './ope-config/specific-data-conf22/specific-data-conf22.component';
import { SpecificDataConf23Component } from './ope-config/specific-data-conf23/specific-data-conf23.component';
import { SpecificDataConf24Component } from './ope-config/specific-data-conf24/specific-data-conf24.component';
import { SpecificDataConf25Component } from './ope-config/specific-data-conf25/specific-data-conf25.component';
import { SpecificDataConf26Component } from './ope-config/specific-data-conf26/specific-data-conf26.component';
import { SpecificDataConf3Component } from './ope-config/specific-data-conf3/specific-data-conf3.component';
import { SpecificDataConf4Component } from './ope-config/specific-data-conf4/specific-data-conf4.component';
import { SpecificDataConf5Component } from './ope-config/specific-data-conf5/specific-data-conf5.component';
import { SpecificDataConf6Component } from './ope-config/specific-data-conf6/specific-data-conf6.component';
import { SpecificDataConf7Component } from './ope-config/specific-data-conf7/specific-data-conf7.component';
import { SpecificDataConf8Component } from './ope-config/specific-data-conf8/specific-data-conf8.component';
import { SpecificDataConf9Component } from './ope-config/specific-data-conf9/specific-data-conf9.component';
import { DemembrementComponent } from './shared/demembrement/demembrement.component';
import { FondsEvenementielsComponent } from './shared/fonds-evenementiels/fonds-evenementiels.component';
import { ModePaiementComponent } from './shared/mode-paiement/mode-paiement.component';
import { ObjetModificationComponent } from './shared/objet-modification/objet-modification.component';
import { SpecificCommentComponent } from './shared/specific-comment/specific-comment.component';
import { TypeSignaturePartenaireComponent } from './shared/type-signature-partenaire/type-signature-partenaire.component';
import { TypeTauxFraisComponent } from './shared/type-taux-frais/type-taux-frais.component';
import { VersementsProgrammesComponent } from './shared/versements-programmes/versements-programmes.component';
import { SubscribeSpecificComponent } from './subscribe-specific.component';

@NgModule({
  declarations: [
    SubscribeSpecificComponent,
    MontantOperationComponent,
    SpecificDataConf1Component,
    TypeTauxFraisComponent,
    SpecificDataConf2Component,
    SpecificDataConf3Component,
    SpecificDataConf4Component,
    SpecificDataConf5Component,
    SpecificDataConf6Component,
    ModePaiementComponent,
    TypeSignaturePartenaireComponent,
    DemembrementComponent,
    SpecificCommentComponent,
    FondsEvenementielsComponent,
    SpecificDataConf7Component,
    SpecificDataConf8Component,
    SpecificDataConf9Component,
    SpecificDataConf10Component,
    SpecificDataConf11Component,
    SpecificDataConf12Component,
    SpecificDataConf13Component,
    SpecificDataConf14Component,
    SpecificDataConf15Component,
    SpecificDataConf16Component,
    SpecificDataConf17Component,
    SpecificDataConf18Component,
    SpecificDataConf19Component,
    ObjetModificationComponent,
    SpecificDataConf20Component,
    SpecificDataConf21Component,
    SpecificDataConf22Component,
    SpecificDataConf23Component,
    SpecificDataConf24Component,
    VersementsProgrammesComponent,
    SpecificDataConf25Component,
    SpecificDataConf26Component,
  ],
  exports: [SubscribeSpecificComponent],
  imports: [
    CommonModule,
    OuiLayoutModule,
    OuiIconModule,
    OuiButtonModule,
    OuiTabsModule,
    OuiTagModule,
    OuiTableModule,
    OuiPaginatorModule,
    OuiNavButtonModule,
    OuiNavigationPanelModule,
    OuiStepper,
    OuiSlideToggle,
    OuiSelect,
    OuiBanner,
    OuiLoadingModule,
    OuiSelectFilter,
    OuiAccordionModule,
    OuiTextfield,
    OuiTextarea,
    OuiCheckbox,
    OuiAutocomplete,
    OuiButtonToggle,
    OuiInfosBulle,
  ],
})
export class OperationSpecificDataModule {}
