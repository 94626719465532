import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationSimpleComponent } from '@lib/components/confirmation-simple/confirmation-simple.component';
import { DocumentViewerHeaderComponent } from '@lib/components/document-viewer/_common/document-viewer-header/document-viewer-header.component';
import { NonConformiteAccordionComponent } from '@lib/components/non-conformite-accordion/non-conformite-accordion.component';
import {
  AddArianeDocumentViewerComponent,
  AddArianeResult,
} from '@lib/components/pdf-viewer/add-ariane/add-ariane-document-viewer.component';
import { OuiDocumentViewer } from 'omnium-ui/document-viewer';

import { FileConformStatusStampComponent } from '@lib/components/file-conform-status-stamp/file-conform-status-stamp.component';
import { FileStatutTagComponent } from '@lib/components/file-statut-tag/file-statut-tag.component';
import { FichierOperationStatutEnum } from '@lib/models/FichierOperationStatutEnum';
import { LocalFileInfo } from '@lib/models/LocalFileInfo';
import {
  FichierOperation,
  FichierOperationHistoryRecordTypes,
  NonConformiteFichier,
  Operation,
  OperationsPaginatedCollectionSegment,
} from '@lib/models/generated/graphql';
import { nonConformiteInstanceFragment } from '@lib/models/graphqlFragments';
import { DocumentsService } from '@lib/services/documents.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { openFilePicker } from '@lib/utils/filesUtils';
import { Apollo, gql } from 'apollo-angular';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiEtatVide } from 'omnium-ui/etat-vide';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiMenuModule } from 'omnium-ui/menu';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { firstValueFrom } from 'rxjs';
import { ConsultantReponseService } from 'src/service/consultant-reponse.service';
import { ConsultantReponseCommentaireComponent } from './consultant-reponse-commentaire/consultant-reponse-commentaire.component';

const UpdateFichierOperationFragment = gql`
  fragment updateFichierOperation on FichierOperation {
    id
    natureDocumentRequisId
    operationId
    fileId
    reponseConsultant
    natureDocumentRequis {
      id
      natureDocument {
        id
        nom
        categorieId
        boxDocumentType {
          isParentGedTypeHaveMultipleBoxDocumentTypes
          key
          gedParentTypeCode
        }
        categorie {
          libelle
        }
      }
    }
    commentaire
    estHorsConfiguration
    estCoInvestisseur
    fichierOperationStatut {
      id
      statut
    }
    nonConformiteFichiers {
      ...nonConformiteInstance
    }
    metadata {
      fileId
      fileName
      denomination
      typeLibelle
      typeDocumentCode
      creePar
      dateDeCreation
    }
    operation {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
    }
  }
  ${nonConformiteInstanceFragment}
`;

const UPDATEFILEREPONSE = gql`
  mutation updateFichierOperationReponse($fichierOperationId: ID!, $reponse: String, $fileId: String) {
    updateFichierOperationReponse(fichierOperationId: $fichierOperationId, reponse: $reponse, fileId: $fileId) {
      ...updateFichierOperation
    }
  }
  ${UpdateFichierOperationFragment}
`;

const FETCH_HISTORY = gql`
  query updateFichierOperationReponse($operationId: Int) {
    allOperationsPaginated(skip: 0, take: 1, where: { id: { eq: $operationId } }, order: [{}]) {
      items {
        fichierOperations {
          id
          historyEntry {
            type
            fileId
            timestamp
          }
        }
      }
    }
  }
`;

const CANCELRESPONSE = gql`
  mutation cancelConsulantNCResponse($fichierOperationId: ID!) {
    cancelConsulantNCResponse(fichierOperationId: $fichierOperationId) {
      ...updateFichierOperation
    }
  }
  ${UpdateFichierOperationFragment}
`;

@Component({
  selector: 'app-response-nc-document-viewer',
  standalone: true,
  imports: [
    CommonModule,
    OuiDocumentViewer,
    OuiMenuModule,
    OuiButtonModule,
    OuiIconModule,
    OuiEtatVide,
    OuiLoadingModule,
    DocumentViewerHeaderComponent,
    NonConformiteAccordionComponent,
    ConsultantReponseCommentaireComponent,
    FileStatutTagComponent,
    FileConformStatusStampComponent,
  ],
  templateUrl: './response-nc-document-viewer.component.html',
  styleUrls: ['./response-nc-document-viewer.component.scss'],
})
export class ResponseNcDocumentViewerComponent {
  nonConformiteList: NonConformiteFichier[];
  instancePartenaireList: NonConformiteFichier[];

  updatedFileId: string | undefined;

  documentUrl?: string;
  fileName?: string;

  isMissingFile: boolean;
  isConformFile: boolean;

  fileIdDuringNC?: string;

  localFilesInfo: LocalFileInfo[] = [];

  updated = FichierOperationStatutEnum.updatedByConsulant;

  protected docUpdated: boolean = false;

  private _selectedFile: FichierOperation;
  public get selectedFile(): FichierOperation {
    return this._selectedFile;
  }
  @Input({ required: true })
  public set selectedFile(value: FichierOperation) {
    this._selectedFile = value;
    this.initDocument();
  }

  @Input({ required: true })
  operation: Operation;

  @Output()
  close = new EventEmitter<boolean | undefined>();

  constructor(
    private dialog: MatDialog,
    private documentService: DocumentsService,
    private appolo: Apollo,
    private queryManager: QueryManagerService,
    protected reponseService: ConsultantReponseService,
    private snackbarService: OuiSnackbarService,
    private dialogService: OuiDialogService
  ) {}

  menu = [
    {
      name: 'Depuis mon ordinateur',
      icon: 'upload_file',
      action: () => this.onUpdateOnComputer(),
    },
    {
      name: 'Depuis mes documents Ariane',
      icon: 'search',
      action: () => this.onUpdateByAriane(),
    },
  ];

  async getFileIdDuringNC() {
    let result = await firstValueFrom(
      this.queryManager.query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
        query: FETCH_HISTORY,
        variables: {
          operationId: this.operation.id,
        },
      })
    );

    let fichierOpeHistory = result?.data?.allOperationsPaginated?.items?.length
      ? result?.data?.allOperationsPaginated?.items[0]?.fichierOperations.find(f => f.id === this.selectedFile.id)
          ?.historyEntry
      : [];
    let fichierOpeHistoryFiltered = fichierOpeHistory
      ?.filter(
        entry =>
          entry?.type === FichierOperationHistoryRecordTypes.FichierOperationRaiseInstance ||
          entry?.type === FichierOperationHistoryRecordTypes.FichierOperationRaiseNc
      )
      ?.sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      });
    this.fileIdDuringNC = fichierOpeHistoryFiltered?.length
      ? fichierOpeHistoryFiltered[0]?.fileId ?? undefined
      : undefined;
  }

  isResponseByFileChanged() {
    return this.fileIdDuringNC !== this.selectedFile.fileId;
  }

  async initDocument() {
    this.isConformFile = this.selectedFile.fichierOperationStatut?.statut === FichierOperationStatutEnum.compliant;

    if (this.selectedFile.metadata) {
      this.isMissingFile = false;
      this.fileName = this.selectedFile.metadata.fileName ?? undefined;

      let fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(this.selectedFile.metadata);

      if (fileCacheUrl) {
        this.documentUrl = fileCacheUrl;
        this.isMissingFile = false;
      } else {
        this.documentUrl = undefined;
        this.isMissingFile = true;
      }
    } else {
      this.documentUrl = undefined;
      this.fileName = undefined;
      this.isMissingFile = true;
    }

    this.reponseService.commentaireNCReponse = this.selectedFile.reponseConsultant;
    this.nonConformiteList = this.selectedFile.nonConformiteFichiers.filter(nc => !nc.isInstancePartenaire);
    this.instancePartenaireList = this.selectedFile.nonConformiteFichiers.filter(nc => nc.isInstancePartenaire);
    this.getFileIdDuringNC();
  }

  displayCommentMode(isRequired = true) {
    if (isRequired) {
      this.reponseService.setCommentAsRequired();
    } else {
      this.reponseService.setCommentAsOptional();
    }
  }
  closeCommentMode() {
    this.reponseService.closeCommentMode();
  }
  onRespondByComment() {
    this.displayCommentMode(true);
  }

  async onCancelComment() {
    this.updatedFileId = undefined; // reset the updatedFileId
    this.localFilesInfo = []; // reset localFile
    this.reponseService.closeCommentMode();
    await this.initDocument();
  }

  async onValidComment() {
    // case when we have to upload file before sending the response
    if (this.localFilesInfo[0]) {
      await this.uploadFile();
      if (this.updatedFileId) {
        this.sendReponse();
      }
      return;
    }
    this.sendReponse();
  }

  onModifyConsultantResponse() {
    this.displayCommentMode();
  }

  async uploadFile() {
    if (this.localFilesInfo[0] && this.operation.investisseur?.id) {
      this.updatedFileId = await this.documentService.uploadFile(
        this.localFilesInfo[0].file,
        this.operation.investisseur.id,
        this.selectedFile.natureDocumentRequis?.natureDocument?.boxDocumentType,
        this.selectedFile.natureDocumentRequis?.natureDocument?.boxDocumentType?.key
      );
    }
  }

  sendReponse() {
    this.appolo
      .mutate<{ updateFichierOperationReponse: FichierOperation }>({
        mutation: UPDATEFILEREPONSE,
        variables: {
          fichierOperationId: this.selectedFile.id,
          reponse: this.reponseService.commentaireNCReponse,
          fileId: this.updatedFileId,
        },
      })
      .subscribe(async result => {
        if (result.data?.updateFichierOperationReponse) {
          this.selectedFile = result.data?.updateFichierOperationReponse;
          this.closeCommentMode();
          this.docUpdated = true;
          this.close.emit(this.docUpdated);
        }
      });
  }
  onUpdateOnComputer() {
    openFilePicker(
      'target_div',
      async (files: File[]) => {
        if (!files.every(file => this.documentService.isAuthorizedConsultantFile(file))) {
          this.snackbarService.open('Format ou poids de fichier non autorisé.', 'error', 5000, {
            horizontal: 'left',
            vertical: 'bottom',
          });
          return;
        }
        this.localFilesInfo = files.map(file => ({
          url: this.documentService.cacheLocalFile(file),
          file,
          investisseurId: this.selectedFile.estCoInvestisseur
            ? this.operation.coInvestisseur?.id
            : this.operation.investisseur?.id,
        }));
        this.documentUrl = this.localFilesInfo[0].url;
        this.fileName = this.localFilesInfo[0].file.name;
        this.displayCommentMode(false);
        this.isMissingFile = false;
      },
      false,
      true
    );
  }

  onUpdateByAriane() {
    const dialogRef = this.dialog.open(AddArianeDocumentViewerComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: '90%',
      width: '90%',
      panelClass: 'full-screen-modal',
      data: {
        investisseurs: this.operation.investisseur ? [this.operation.investisseur] : [],
        natureDocumentRequis: this.selectedFile.natureDocumentRequis,
        hideComment: false,
      },
    });
    dialogRef.afterClosed().subscribe(async (resultData: AddArianeResult) => {
      const fileInfoList = resultData?.filesInfo;
      //if a file has been selected, add create a fichier Operation
      if (fileInfoList && Array.isArray(fileInfoList) && fileInfoList.length > 0) {
        const updatedFile = fileInfoList[0];
        this.updatedFileId = updatedFile.metadata.fileId ?? undefined;
        this.displayCommentMode(false);
        this.reponseService.commentaireNCControl.setValue(resultData.filesInfo[0].comment);
        let fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(fileInfoList[0].metadata);
        if (fileCacheUrl) {
          this.documentUrl = fileCacheUrl;
          this.fileName = updatedFile.metadata.fileName ?? undefined;
          this.isMissingFile = false;
        } else {
          this.documentUrl = undefined;
          this.isMissingFile = true;
        }
      }
    });
  }

  onCancelConsultantResponse() {
    const modalRef = this.dialogService.openDialog(
      ConfirmationSimpleComponent,
      {
        title: 'Annuler la réponse?',
        message: 'Êtes-vous sûr de vouloir annuler votre réponse ?\n Le commentaire saisi sera perdu.',
        validateButtonLabel: 'Oui, annuler',
        cancelButtonLabel: 'Non',
      },
      'auto',
      '400px'
    );
    modalRef.afterClosed().subscribe(async (isValidated: boolean) => {
      if (isValidated) {
        const result = await firstValueFrom(
          this.queryManager.mutate<{ cancelConsulantNCResponse: FichierOperation }>({
            mutation: CANCELRESPONSE,
            variables: {
              fichierOperationId: this.selectedFile.id,
            },
          })
        );
        if (result.data?.cancelConsulantNCResponse) {
          this.selectedFile = result.data?.cancelConsulantNCResponse;
          this.docUpdated = true;
        }
      }
    });
  }
}
