import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Operation, OperationType } from '@lib/models/generated/graphql';

@Component({
  selector: 'app-operation-accordion-title',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './operation-accordion-title.component.html',
})
export class OperationAccordionTitleComponent {
  protected typeOperationEnum = OperationType;

  @Input({ required: true })
  operation: Operation;

  @Input({ required: true })
  index: number;

  @Input({ required: true })
  showDetails: boolean;
}
