import { Component } from '@angular/core';
import { AbstractSpecificFieldComponent } from '../abstract-specific-field.component';

@Component({
  selector: 'app-montant-operation',
  templateUrl: './montant-operation.component.html',
  styleUrls: ['./montant-operation.component.scss'],
})
export class MontantOperationComponent extends AbstractSpecificFieldComponent<number> {
  override getDataName(): string {
    return 'montant';
  }

  override wrapData(value: string): number {
    return Number(value);
  }
}
