<div class="flex-vertical gap-32">
  <app-montant-operation
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"
    [isRequired]="true"></app-montant-operation>
  <app-versements-programmes
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"
    [operationIndex]="operationIndex"></app-versements-programmes>
  <app-mode-paiement
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"
    [operationIndex]="operationIndex"
    [isRequired]="true"></app-mode-paiement>
  <app-type-signature-partenaire
    [operation]="operation"
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-type-signature-partenaire>
  <app-demembrement (onSpecificDataChanged)="onDataChange($event)" [initialData]="initialData"></app-demembrement>
  <app-specific-comment
    (onSpecificDataChanged)="onDataChange($event)"
    [initialData]="initialData"></app-specific-comment>
</div>
