<div class="flex-vertical gap-8 flex-center width-100pc">
  <div class="width-100pc">
    <oui-textarea
      class="width-100pc"
      [label]="commentaireFormLabel"
      [control]="commentaireControl"
      placeholder="Commentaire">
    </oui-textarea>
  </div>
  <div class="flex-horizontal width-100pc">
    <oui-button type="text" class="flex-1" (click)="onCancelComment()">Annuler</oui-button>
    <oui-button type="contained" class="flex-1" (click)="onValidComment()" [disabled]="!commentaireControl.valid">{{
      buttonText
    }}</oui-button>
  </div>
</div>
