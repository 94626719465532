import { createSouscriptionFragment } from '@lib/models/graphqlFragments';
import { gql } from 'apollo-angular';

export const createSouscriptionMutation = gql`
  mutation createSouscription(
    $investisseurId: Long!
    $operationGroupId: Int
    $coInvestisseurId: Long
  ) {
    createSouscription(
      investisseurId: $investisseurId
      operationGroupId: $operationGroupId
      coInvestisseurId: $coInvestisseurId
    ) {
      ...createSouscriptionDetails
    }
  }
  ${createSouscriptionFragment}
`;
