import { createFichierOperationFragment } from '@lib/models/graphqlFragments';
import { gql } from 'apollo-angular';

export const createBatchFichierOperationAllOperations = gql`
  mutation createBatchFichierOperation($batchCreateFichierOperationInput: CreateBatchFichierOperationInput!) {
    createBatchFichierOperation(input: $batchCreateFichierOperationInput) {
      id
      operation {
        id
        fichierOperations {
          ...createFichierOperation
        }
      }
    }
  }
  ${createFichierOperationFragment}
`;
