import { Component } from '@angular/core';
import { AbstractSpecificDataComponent } from '../../abstract-specific-data.component';

@Component({
  selector: 'app-specific-data-conf18',
  templateUrl: './specific-data-conf18.component.html',
  styleUrls: ['../specific-data-conf.common.scss'],
})
export class SpecificDataConf18Component extends AbstractSpecificDataComponent {
  override getDataName(): string {
    return 'specific-data-conf18';
  }
}
