import { Component, Inject } from '@angular/core';
import { Investisseur } from '@lib/models/generated/graphql';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

import { formatDate } from '@angular/common';
import { PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE } from '@lib/models/graphqlData';

@Component({
  selector: 'app-investisseur-duplicates-dialog',
  templateUrl: './investisseur-duplicates-dialog.component.html',
  styleUrls: ['./investisseur-duplicates-dialog.component.scss'],
})
export class InvestisseurDuplicatesDialogComponent {
  readonly link = 'https://digitaladmin.typeform.com/to/VOU8MxNl';
  duplicateControl: FormControl<Investisseur>;
  duplicateOptions: { label: string; value: Investisseur }[] = [];
  duplicates: Investisseur[] = [];
  constructor(
    private dialog: MatDialogRef<InvestisseurDuplicatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      duplicates: Investisseur[];
      preSelectedId: number;
    }
  ) {}

  ngOnInit(): void {
    this.duplicates = this.data.duplicates;
    const preSelected = this.duplicates.find(i => i.id === this.data.preSelectedId);
    this.duplicateOptions = this.duplicates.map(investisseur => {
      return {
        label: this.formatLabel(investisseur),
        value: investisseur,
      };
    });

    this.duplicateControl = new FormControl<Investisseur>(preSelected??this.duplicates[0], {nonNullable: true});
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }



  onValidate() {
    this.dialog.close(this.duplicateControl.value);
  }
  formatLabel(investisseur: Investisseur): string {
    let label = '';
    label += investisseur?.investisseurEntite?.displayName ?? '';
    if (investisseur?.investisseurEntite?.personnePhysique?.dateNaissance) {
      if (investisseur?.investisseurEntite?.personnePhysique?.genre == PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE) {
        label += ' - née le ';
      } else {
        label += ' - né le ';
      }
      label +=
        formatDate(investisseur.investisseurEntite?.personnePhysique?.dateNaissance, 'dd/MM/yyyy', 'fr_FR');
    }
    if (investisseur.creationDate) {
      label += ` - créé le ${formatDate(investisseur.creationDate, 'dd/MM/yyyy', 'fr_FR')}`;
    }
    if (investisseur.statutCodeNavigation?.actif) {
      label += ' - ' + investisseur.statutCodeNavigation?.libelle.toLocaleUpperCase() ?? '-';
    }
    return label;
  }
}
