<div class="denomination-container">
  <div class="denomination body-2">
    {{ fileInfo.label }}
    <span *ngIf="isCoInvestisseur"> - co-investisseur</span>
  </div>
</div>
<div class="file-name-container">
  <ng-container *ngIf="!isFileLoading">
    <div *ngIf="!fileInfo.isMissing" class="file-name caption">
      {{ fileInfo.fileName }}
    </div>
    <oui-menu
      *ngIf="fileInfo.isMissing"
      [menuItems]="fileInfo.menu"
      horizontalPosition="left"
      verticalPosition="bottom">
      <oui-button type="text" (click)="addFileClick.emit()">Ajouter le document</oui-button>
    </oui-menu>
  </ng-container>
  <ng-container *ngIf="isFileLoading">
    <oui-loading size="small"></oui-loading>
  </ng-container>
</div>
<div class="status-container">
  <ng-container *ngIf="!fileInfo.isUploading">
    <oui-tag *ngIf="!fileInfo.isMissing" type="status" status="completed" size="big">Ajouté</oui-tag>
    <oui-tag *ngIf="fileInfo.isMissing" type="status" status="to-correct" size="big">Manquant</oui-tag>
  </ng-container>
  <ng-container *ngIf="fileInfo.isUploading">
    <oui-loading size="small"></oui-loading>
  </ng-container>
</div>
