import { Component } from '@angular/core';
import { OptionsService } from '@lib/services/options.service';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { AbstractSpecificFieldComponent } from '../abstract-specific-field.component';

@Component({
  selector: 'app-mode-paiement',
  templateUrl: './mode-paiement.component.html',
  styleUrls: ['./mode-paiement.component.scss'],
})
export class ModePaiementComponent extends AbstractSpecificFieldComponent<string> {
  override getDataName(): string {
    return 'modePaiement';
  }
  options: OuiSelectOption<string>[];

  constructor(private optionsService: OptionsService) {
    super();
    this.options = this.optionsService.getModePaiementOptions();
  }

  override wrapData(value: string): string {
    return value;
  }
}
