import { subscribeOperationDetailsFragment } from '@lib/models/graphqlFragments';
import { gql } from 'apollo-angular';

export const operationGroupDetailsQuery = gql`
  query operationGroupDetails($id: Int) {
    allOperationsPaginated(skip: 0, take: 1, where: { id: { eq: $id } }) {
      items {
        operationGroup {
          operations {
            ...subscribeOperationDetails
          }
        }
      }
    }
  }
  ${subscribeOperationDetailsFragment}
`;
