import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@lib/services/auth-service.service';
import { BoxLayoutService } from '@lib/services/layout.service';
import { MenuNode } from 'omnium-ui/shared';
import { delay } from 'rxjs/internal/operators/delay';
import { TemplatePortal } from '@angular/cdk/portal';
import { BoxDocumentViewerService } from '@lib/components/document-viewer/document-viewer-panel/document-viewer-panel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  logoUrl: string = 'assets/Logo_Box-Placement.svg';

  displayHeader: boolean = true;

  userName: string = '';
  userMenu: MenuNode[] = [
    {
      name: 'Notification e-mails',
      icon: 'settings',
      action: () => {
        this.router.navigate(['parameters']);
      },
    },
    {
      name: 'Se déconnecter',
      icon: 'power_settings_new',
      action: () => {
        this.authService.logout();
      },
    },
  ];

  @ViewChild('documentViewerPortalContent')
  documentViewerPortalContent: TemplateRef<unknown>;

  constructor(
    protected authService: AuthService,
    private layoutService: BoxLayoutService,
    private documentViewerPanelService: BoxDocumentViewerService,
    private _viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    // .pipe( delay(0)) to avoid NG0100: ExpressionChangedAfterItHasBeenCheckedError (see https://blog.angular-university.io/angular-debugging/)
    this.layoutService.displayHeader$.pipe(delay(0)).subscribe(value => (this.displayHeader = value));
  }

  ngOnInit() {
    this.authService.login().then(() => {
      const userInfo = this.authService.getUserInfo();
      this.userName = userInfo.fullName;
    });
  }

  ngAfterViewInit(): void {
    const documentViewerPortal = new TemplatePortal(this.documentViewerPortalContent, this._viewContainerRef);
    this.documentViewerPanelService.setDocumentViewerPortal(documentViewerPortal);
  }

  onHelpClick() {
    window.open('https://prodemial.zendesk.com/hc/fr/sections/13079990298908', '_blank');
  }
}
